import clsx from "clsx";
import React, {useState} from "react";
import navigationSlice, {navigationActions} from "../ReduxStore/navigationSlice";

export default function HamburgerButton({
  className = 'block cursor-pointer leading-none w-10 text-center pt-2',
  iconClasses = "fas fa-bars text-lg",
  captionClasses = "text-tiny m-0 pb-1 p-0",
  caption = 'MENU',
  hamburgerState = {},
  dispatch,
  showCloseButton = false,
  openClasses = '!text-brand-primary bg-anti-primary',
  closedClasses = '!text-anti-primary bg-brand-primary',
  closeIconClasses = "fas fa-times text-2xl",
  textContent=null, // render text INSTEAD of the button
  ...props
}) {
  const {open} = hamburgerState;

  const [touched, setTouched] = useState(false);

  const handleClick = (e) =>  {
    const opening = !open;

    setTouched(t => !t);

    dispatch(navigationActions.updateNavigation({
      hamburgerState: Object.assign({}, hamburgerState, {open: opening}),
      // close the dropdown menu...
      dropdownState: { open: false },
    }))
  }

  // invert colours when open
  className = clsx(className, open ? openClasses : closedClasses)

  // we only do this if 'touched' is present. If open and untouched, it
  // was *another* hamburger button that invoked the menu, so we should
  // just ignore it
  if (showCloseButton && open && touched) {
    return <a className={className} onClick={handleClick}>
      {
        textContent ? textContent : <div><i className={clsx(closeIconClasses)}/></div>
      }
      <div className={clsx(captionClasses)}>&nbsp;</div>
    </a>
  }

  // menu closed - render hamburger button and "MENU" caption
  return (
    <a className={className} onClick={handleClick}>
      {
        textContent ? textContent : <div><i className={clsx(iconClasses)}/></div>
      }
      {caption && <div className={clsx(captionClasses)}>{caption}</div>}
    </a>
  );

}
