import React, {useEffect, useMemo} from 'react';
import {isEmpty, keyBy, size} from 'lodash';

import ItemClusterMain from "./ItemClusterMain";
import AppliedItemCache from "../../../models/AppliedItemCache";
import ItemClusterImages from "../image_display/ItemClusterImages";
import ClusterAddToCart from "../cart_display/ClusterAddToCart";
import CatchErrors from "../../../../Utility/CatchErrors";
import {createClusterState, groupItemsSlice} from "../../../../ReduxStore/ProductGroupPage/groupItemsSlice";
import {getDispatch} from "../../../../Utility/ReduxHelper";
import ItemClusterUi from "./ItemClusterUi";
import {getSiteComponent} from "../../../../Utility/componentRegistry";

/**
 * 2025-03 converted from class to function, split off ItemClusterUI
 *
 * ItemCluster - a group of selectors for choosing among
 * a group of items, by their attributes (which may include fitment).
 *
 * PROPS: ["items","dispatch","cluster","clusterState", "multi_add"]
 */
export default function ItemCluster({
  cluster, allClusterStates, products, itemApplications, shoppingCart, dispatch,
  sectionConfig={},
  // pagetype
  ...props
}) {
  const clusterId = cluster?.id;

  useEffect(() => {
    let clusterState = createClusterState(cluster)

    dispatch ||= getDispatch();
    dispatch(groupItemsSlice.actions.updateClusterState({
      clusterId,
      clusterState
    }));
  }, [clusterId]);

  const itemCache = useMemo(() => {
    return new AppliedItemCache({
      apps: itemApplications, products: products
    });
  }, [products])

  const clusterState = allClusterStates[clusterId];
  if (isEmpty(clusterState))
    return null;

  const cartItem = getSelectedCartItem({clusterState, shoppingCart});

  // ItemClusterUi, ToolItemClusterUi
  const MyUI = getSiteComponent('ItemClusterUi', ItemClusterUi);

  return <MyUI
    cluster={cluster}
    clusterState={clusterState}
    products={products}
    itemCache={itemCache}
    cartItem={cartItem}
    sectionConfig={sectionConfig}
  />

}

function getSelectedCartItem({clusterState, shoppingCart}) {
  if (isEmpty(clusterState) || isEmpty(shoppingCart))
    return null;

  const cartIndex =  keyBy(shoppingCart.cartItems, 'productId');

  const {selectedItem } = clusterState;
  if (selectedItem) {
    const productId = selectedItem.product?.id;
    return productId ? cartIndex[productId] : null;
  }
  return null;
}
