import {getClassList} from "../../../Utility/siteConfig";

export default function CheckToCompare({id, selected, handleSelect}) {
  // HIDDEN on mobile.


  let wrapper = getClassList({
    key: 'CompareItems',
    fallback: "mt-2 relative",
    base: "hidden lg:block"
  })

  let className = getClassList({
    key: 'CheckToCompare',
    base: 'cursor-pointer inline-block',
    fallback: ''
  })

  let inputClasses = getClassList({
    key: 'CompareItems.checkbox',
    fallback: 'align-middle mx-2'
  })

  return (
    <div className={wrapper}>
      <label className={className}>
        <input id={id} type="checkbox" value="1"
          className={inputClasses}
          onChange={handleSelect} checked={selected}/>
        <span>Check to Compare</span>
      </label>
    </div>
  );
}
