import React, {useCallback, useContext, useMemo} from 'react';
import ReturnFromSubcat from "../sidebar/ReturnFromSubcat";
import FilterWidget from "./FilterWidget";
import {useDispatch} from "react-redux";
import {FilterStateContext} from "../../../ReduxStore/GroupGrid/gridFilterSlice";

/*
 *
 * @param filterMap - a list of strings - filter names.
 *  -- topcat page: categories, brands
 *  -- subcat page: sub_category_nav, brands
 *  -- vehicle landing page: categories, brands
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

/**
 *
 * @param dispatch
 * @param gridState
 * @param filterMap
 * @param allFilterOptions
 * @param allFilterSelections
 * @param filtersTouched
 * @param thisPage -- NOT USED????????????
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function FilterWidgetList({
  gridId,
  filterState,
  thisPage,   // only valid on subcat page
  ...props
}) {
  const {
    filtersTouched,
    filterMap,
    allFilterSelections = {},
    disabledFilterOptions = {},
    allFilterOptions = {},
    allFilterBuckets = {},
  } = filterState;

  const filtersAsList = useMemo(
    () => { return _(filterMap).values().sortBy(f => +f.sortOrder).value()},
    [filterMap]);

  if (_.isEmpty(filtersAsList))
    return null;

  // On the subcat page, the first filterName is "sub_category_nav",
  // which is not a filter widget at all - it's a link back to the
  // parent page!
  const showReturnToParent = !_.isEmpty(thisPage)

  return (
    <div className="pb-8">
      {showReturnToParent && <ReturnFromSubcat thisPage={thisPage}/>}

      {filtersAsList.map(f => {
        const filterName = f.name;

        return <FilterWidget
          gridId={gridId}
          key={filterName}
          filter={f}
          filterMap={filterMap}
          options={allFilterOptions[filterName] || {}}
          disabledOptions={disabledFilterOptions[filterName] || {}}
          selections={allFilterSelections[filterName] || {}}
          filtersTouched={filtersTouched}
          buckets={allFilterBuckets[filterName]}
        />
      })}
    </div>
  );
}
