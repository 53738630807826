import LocalUrlMaker from "../../lib/LocalUrlMaker";
import PropTypes from "prop-types";

export default function CartButtons({cartCode, handleClose}) {
  let cartUrl = LocalUrlMaker.prefixedPath(`/cart/${cartCode}`);

  const handleViewCart = () => {
    handleClose({label: 'view_cart_button'});
    window.location = cartUrl;
  }

  return <div className="clear my-4 text-center ">
    <a className="btn-primary mx-2"
      onClick={handleViewCart}
       href={cartUrl}>View Cart</a>

    <a className="btn-secondary mx-2"
      onClick={handleClose}>Keep Shopping</a>

  </div>
}

CartButtons.propTypes = {
  handleClose: PropTypes.func.isRequired
}
