import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import GridItemTitle from "./GridItemTitle";
import RatingWidget from "./RatingWidget";
import GridItemDesc from "./GridItemDesc";
import GridItemCompare from "./GridItemCompare";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";

export default function GridItemInfoBlock({
  item, handleClick, gridState, gridId, path,
  ...props}) {

  path ||= LocalUrlMaker.prefixedPath(item.path);

  return <div className="p-2">
    <GridItemTitle item={item} path={path}
      handleClick={handleClick}/>
    <RatingWidget item={item} caption={"Rating:"} />
    <GridItemDesc item={item} path={path}
      handleClick={handleClick}/>

    {gridState.showCompare && <GridItemCompare
      gridId={gridId}
      item={item}
      gridState={gridState}
    />}
  </div>
}
