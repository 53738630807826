import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ItemCluster from "./ItemCluster";
import {useDispatch} from "react-redux";

/*
 * ClusteredItemList - container for all clustered item selectors
 * on the page.
 *
 * 2024 props are:
 *groupId pageType productGroup groupProperties shoppingCart dynamicCart customerVehicle vehicleContext
 * clusterIndex itemApplications allApps products productDetail dispatch
 *
 *
 */
export default function ClusteredItemList({
  clusterIndex, itemApplications, products, pageType, shoppingCart,
  allClusterStates, pageConfig
}) {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    // MAGIC: for some reason this fails on first mount,
    // so do this to provoke an update.
    if (!mounted)
      setMounted(true);
  }, []);

  const clusterList = getClusterList(clusterIndex) || [];

  // props ["groupId","pageType","groupProperties",
  // "customerVehicle","clusterIndex","itemApplications",
  // "products","productDetail","dispatch"]

  return <div id="item-cluster-list">
    {clusterList.map(cluster =>
      <ItemCluster key={cluster.id}
        dispatch={dispatch}
        cluster={cluster}
        clusterCount={clusterList.length}
        itemApplications={itemApplications}
        products={products}
        pageType={pageType}
        shoppingCart={shoppingCart}
        allClusterStates={allClusterStates}
        sectionConfig={pageConfig?.productSection || {}}
      />
    )}
  </div>
}

function getClusterList(clusterIndex) {
  let {sortOrder, clusters} = clusterIndex;

  if (!clusters) return [];

  if (!sortOrder)
    sortOrder = _.keys(clusters);

  return sortOrder
    .map((k) => clusters[k]).filter((c) => !_.isEmpty(c));
}

ClusteredItemList.propTypes = {
  groupId: PropTypes.number.isRequired,
  groupProperties: PropTypes.object.isRequired,

  customerVehicle: PropTypes.object,

  clusterIndex: PropTypes.object.isRequired,
  itemApplications: PropTypes.array.isRequired,
  products: PropTypes.object.isRequired
}
