import React from 'react';
import {getClassList} from "../../../../Utility/siteConfig";
import CatchErrors from "../../../../Utility/CatchErrors";
import ItemClusterImages from "../image_display/ItemClusterImages";
import ItemClusterMain from "./ItemClusterMain";
import ClusterAddToCart from "../cart_display/ClusterAddToCart";

export default function ItemClusterUi(props) {

  const {cluster, clusterState, products, itemCache, cartItem, sectionConfig} = props;

  const className = getClassList({
    key: 'ItemCluster',
    base: [
      'item-cluster lg:grid lg:grid-cols-12 py-8',
      clusterState.highlighted ? 'item-cluster-highlighted' : null,
    ],
    fallback: ""
  }); // item-cluster item-cluster-highlighted

  // cluster.id like front_row_neosupreme_seat_covers
  return <div className={className} id={"item-cluster-" + cluster.id}>
    <div className="lg:col-span-4 min-h-4 ">
      <CatchErrors><ItemClusterImages {...props}/></CatchErrors>
    </div>
    <div className="lg:col-span-5 min-h-4 lg:px-4">
      <CatchErrors><ItemClusterMain {...props} /></CatchErrors>
    </div>
    <div className=" lg:col-span-3 min-h-4">
      <CatchErrors><ClusterAddToCart {...props}/></CatchErrors>
    </div>
  </div>

}



