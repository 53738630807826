import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from "clsx";
import LocalUrlMaker from "../../lib/LocalUrlMaker";
import Modal from "../../Modals/Modal";
import CatchErrors from "../../Utility/CatchErrors";

export default function WarrantyBadge({
  displayText, warrantyId, className, ...props
}) {

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null)

  function handleClose() {
    setModalOpen(false);
  }

  function handleClick(evt) {
    if (!warrantyId)
      return false;

    evt?.preventDefault()
    evt?.stopPropagation();

    let url = LocalUrlMaker.prefixedPath(`/tube_sections/${warrantyId}`);

    fetch(url)
      .then(r => r.text())
      .then(text => {
        setModalContent(text);
        setModalOpen(true);
      });
  }

  if (!displayText) {
    if (!warrantyId) return null;
    displayText = "learn more..."
  }

  className ||= clsx(
    "inline-block text-brand-light bg-brand-med-grey rounded cursor-pointer",
    className);

  return <div>
    <CatchErrors>
      {modalOpen && (!!modalContent) && <WarrantyModal
        textContent={modalContent} handleClose={handleClose}/>}
    </CatchErrors>

    <CatchErrors>
      <a className={clsx(className)}
        href="/warranty"
        onClick={handleClick}>
        <div className={"grid grid-cols-2"}
          style={{gridTemplateColumns: '32px auto'}}>
          <div className="text-center px-1 pt-2">
            <span className="fas fa-shield-check text-2xl text-brand-primary"/>
          </div>
          <div className="text-center p-1">
            <div className="font-bold text-sm">WARRANTY</div>
            <div className="font-normal text-xs">{displayText}</div>
          </div>
        </div>
      </a>
    </CatchErrors>
  </div>
}

function WarrantyModal({textContent, handleClose}) {
  const ref = useRef(null);

  useEffect(() => {
    const top = ref?.current;

    if (top && textContent) {
      top.innerHTML = textContent;
      let sections = top.getElementsByTagName('section');
      [...sections].map(
        e => { if (e.classList) { e.classList.remove('container') } });
    }
  }, [ref, textContent]);

  return <Modal handleClose={handleClose} className="p-0">
    <div className="text-pen bg-paper grid grid-cols-3 border-t border-brand-light">
      <div className="px-2 py-4 lg:px-12">
        <div>
          <img src="/img/logo/wordmark/green-200.png"
          alt="Carlyle" className="w-full inline-block mx-auto" />
        </div>
      </div>
      <div className="col-span-2 p-2 pt-4">
        <h2 className="text-white pt-1 lg:pt-0 text-sm lg:text-xl text-center">WARRANTY</h2>
      </div>
    </div>
    <div className="text-gray-800 bg-white p-2" ref={ref}></div>
  </Modal>
}
