import {requireConfig} from "../Utility/siteConfig";

export function preflight(top) {
  if (!top)
    top = globalThis;

  let results = {};

  results['react'] = checkReact(top);

  for (let k of _.keys(results)) {
    if (results[k]) {
      console.warn(`${k}: ${results[k]}`)
    }
  }

  // assert that theme exists
  const siteName = requireConfig('siteName');
  if (!siteName) {
    console.error("'siteName' is not defined");
  }
  // console.log("siteConfig: theme name is " + theme);

  return results;
}

function checkReact(top) {
  let React = top.React;

  if (!React) {
    return "top level React not found"
  }

  console.log(`React version is ${React.version}`)

  return `react version is ${React.version}`;
}
