import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LocalUrlMaker from "../lib/LocalUrlMaker";
import clsx from "clsx";
import {useLongPress} from "use-long-press";
import ModalCartPopup from "./ModalCartPopup";

/**
 * NEW CartIcon for NOL 2024!
 *
 * see napaonline/header.html.haml for good class names.
 *
 * @param cartCode
 * @param cartUrl
 * @param itemCount
 * @param className
 * @param linkClasses
 * @param iconClasses
 * @param countClasses
 * @returns {Element}
 * @constructor
 */
export default function TailwindCartIcon({
  cartCode, cartUrl, itemCount,
  className = "text-xl",
  linkClasses = "inline-block relative",
  iconClasses = "far fa-shopping-cart",
  countClasses = "absolute text-sm text-white rounded-full -top-2 -right-2"
}) {

  cartUrl ||= LocalUrlMaker.prefixedPath(`/cart/${cartCode}`)

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(x => !x);
  };

  const bindLongPress = useLongPress(toggleModal);

  const realCount = (+itemCount);

  const title = (realCount > 0) ?
    `You have ${realCount} ${realCount > 1 ? "items": "item"} in your shopping cart.` :
    "You have not yet added any items to your cart.";

  return (
    <div className={clsx(className)}>
      <a className={clsx(linkClasses)}
        href={cartUrl} {...bindLongPress()}
        rel="nofollow" title={title}>
        <i className={clsx(iconClasses)}/>
        {(realCount > 0) && <div className={clsx(countClasses)}>{itemCount}</div>}
      </a>
      {modalOpen && <ModalCartPopup
        cartCode={cartCode} cartUrl={cartUrl} itemCount={itemCount}
        handleClose={toggleModal}
      />}
    </div>
  );
}
