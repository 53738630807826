import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ValidationMessage from '../form/ValidationMessage';
import CheckoutFormLabel from '../form/CheckoutFormLabel';
import FieldHelper from "../form/FieldHelper";
import clsx from "clsx";

/**
 * Base class for AddressTextWidget etc.
 */
export default class GenericAddressWidget extends Component {

  // Read our initial "value" from the props and init state.
  state = {value: valueFromProps(this.props)};

  componentDidUpdate(prevProps, prevState) {

    // On receiving new props, see if they're making changes to the field's
    // value (props.shipping_address.city or whatever), and if so update state.value to match.
    const oldValue = valueFromProps(prevProps);
    const newValue = valueFromProps(this.props);

    if (oldValue != newValue) {
      if (this.state && (this.state.value != newValue)) {
        //console.log("ComponentDidUpdate change: " + oldValue + " to " + newValue);
        this.setState({value: newValue})
      }
    }
  }

  /**
   * Get a FieldHelper, which does things like compose rails-style
   * field names from a prefix.
   *
   * @returns {FieldHelper}
   */
  getHelper() {
    let args = _.pick(this.props,
      'name', 'label', 'optional', 'activeField',
      'autoComplete', 'currentAgent', 'addressType', 'placeholder');

    return new FieldHelper({
      object: this.props.address,
      objectName: `${this.props.addressType}_address`,  // shipping_address etc.
      value: this.state.value,
      ...args
    });
  }

  // onChange - called every time a character is entered.
  // This updates our local 'value' state but does not update
  // the parent (an AddressFormSection)
  onChange = (evt) => {
    let {value} = evt.target;

    // console.log(this.props.name + ": on Change, value becomes " + value + ", active=" + this.props.activeField);

    this.setState({value: value}, this.afterChange);
    return true;
  }

  // 2022-09 React 17 no longer sends us Blur events on Autofill
  // Communicate the value to redux if the focus is elsewhere
  afterChange() {
    let {activeField, name} = this.props;

    // are we in an autofill event?
    if (name != activeField) {
      // console.log("-- active field is not this field: " + name + " != " + activeField);
      this.sendUpdate();
    }
  }

  // onFocusChange = (status) => {
  //   console.log(this.props.name + " on focus change = " + status);
  // }

  // On focus, update the parent, which will put this field name
  // into our (and other children's) activeField property.
  onFocus = (evt) => {
    let {name, callbacks} = this.props;
    let {focusChanged = () => false} = callbacks;

    // console.log(this.props.name + " on Focus, value is " + this.state.value, evt);

    focusChanged(name);
  }

  // send update to Redux. This can also update fields other than this one,
  // as done by smarty-enabled autocomplete
  sendUpdateToField = (name, value) => {
    let {callbacks} = this.props;

    // console.log(`send update: ${name} = ${value}`);

    callbacks.updateAddress(name, value);
  }

  // When the user leaves the field, send an update to
  // the parent with the new value of the field. Do not change the
  // value of activeField, as the next field to receive focus will
  // do this.
  //
  // ** Note that the SMARTY widgets override this **
  //
  onBlur = (evt) => {
    //console.log(this.props.name + " on Blur, value is " +this.state.value + ' -------------------------', evt);

    // 2022-10 better to not do this
    //let {callbacks} = this.props;
    // callbacks.focusChanged(null);

    // and send the change to Redux
    this.sendUpdate();
  }

  // Called by onBlur, this sends the update to the parent (which sends it to Redux).
  sendUpdate = () => {
    let {name, callbacks} = this.props;
    let {value} = this.state;

    console.log(this.props.name + " send update to redux: " + this.state.value);

    return this.sendUpdateToField(name, value);
  }

  // ["addressType","address","customer","callbacks","cartCode","currentAgent",
  // ,"stateList","checkout","status","error","step","object","objectName","name","value","autoComplete","label"]

  renderInput(helper) {
    const {type = "text", tabIndex, readOnly = false} = this.props;
    const {value} = this.state;

    if (!helper)
      helper = this.getHelper();

    const focused = helper.isFocused();
    let autoComplete = helper.getAutoComplete();

    // KLUGE: marking a defocused field as readonly will prevent
    // Chrome (spit) from trying to use autocomplete against orders.
    // https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
    //
    // When autocomplete is off use something meaningless instead of "off"

    // let moreAttrs = {};

    // readOnly={!focused}
    //autoCorrect="off"

    return <input
      className="w-full border border-gray-400"
      id={helper.getId()}
      name={helper.getName()}
      type={type}
      value={value}
      placeholder={helper.getPlaceholder()}
      autoComplete={autoComplete || "never"}
      readOnly={readOnly || false}
      onFocus={this.onFocus}
      onBlur={this.onBlur}
      // onInput={this.onChange}
      onChange={this.onChange}
      tabIndex={tabIndex}
    />
  }

  getInputElement() {
    let id = this.getHelper().getId();
    return document.getElementById(id);
  }

  focusInput() {
    let elem = this.getInputElement();
    if (elem) {
      elem.focus();
    }
  }

  render() {
    const {name, optional, focused} = this.props;
    let { className } = this.props;

    const helper = this.getHelper();
    const error = helper.getError();

    // console.log(helper.getName() + ": render with value: " + this.props.value + " and error is " + error);

    return (<div className={clsx(helper.getClassString(), className)}>
      <CheckoutFormLabel label={helper.getLabel()}
        optional={optional} target={helper.getId()}/>

      {this.renderInput(helper)}

      {error && !focused && <ValidationMessage error={error}/>}
    </div>);
  }
}


GenericAddressWidget.propTypes = {
  name: PropTypes.string.isRequired,  // field name like first_name
  address: PropTypes.object.isRequired, // { first_name: XX, street: YY...}
  addressType: PropTypes.string.isRequired, // "shipping" or "billing"
  activeField: PropTypes.string,  // name of field with focus.
  tabIndex: PropTypes.number,     // this does what you think it does
  readOnly: PropTypes.bool,

  callbacks: PropTypes.shape({
    updateAddress: PropTypes.func.isRequired,
    focusChanged: PropTypes.func.isRequired
  }).isRequired,

  // Simple HTML attributes - will be used as-is.
  type: PropTypes.string,     // HTML field type
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string // like "given-name"
}

GenericAddressWidget.defaultProps = {
  type: "text",
  addressType: 'shipping',
  tabIndex: 0,
  readOnly: false
}


function valueFromProps({name, address = {}}) {
  const value = address[name];

  // fucking javascript
  if (_.isNull(value) || _.isUndefined(value)) {
    return "";
  }

  return value;
}
