import React from 'react';
import _ from 'lodash';
import {getAuthToken} from "../../../ReduxStore/scrapyardSlice";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import SacrificialGoat from "../form/SacrificialGoat";
import DismissibleError from "./DismissibleError";
import AddressPanel from "../address/AddressPanel";
import BraintreeClientProvider from "./BraintreeClientProvider";
import PaymentPanel from "../payment/PaymentPanel";
import CatchErrors from "../../../Utility/CatchErrors";
import ReviewPanel from "../review/ReviewPanel";

export default function CheckoutForm({
  ...props
}) {
  const {
    cartCode,
    callbacks, currentAgent, error, step = 2
  } = props;

  // Disable autocomplete in dev or for agent users.
  const nixAutoComplete = !!(currentAgent);

  const childProps = _.omit(props,
    'step', 'bt',
    'stateList', 'cartCode');

  const authToken = getAuthToken();
  const action = LocalUrlMaker.prefixedPath(`/new_checkout/${cartCode}`);

  function handleSubmit() {
    console.error("submit suppressed");
    return false;
  }

  return (
    <form className="checkout-form" method="post"
      action={action}
      autoComplete={nixAutoComplete ? 'off' : 'on'}
      noValidate={true}
      onSubmit={handleSubmit}>
      {nixAutoComplete && <SacrificialGoat/>}

      <input type="hidden" name="form_authenticity_token"
        value={authToken}/>

      {error && <DismissibleError error={error} dismissError={callbacks.clearError}/>}

      {(step === 2) && <AddressPanel {...childProps}
        stateList={props.stateList}/>}

      <BraintreeClientProvider
        braintreeData={props.braintreeData}
        render={(btProps) => {
          return (step === 3) &&
            <PaymentPanel {...childProps} {...btProps} />
        }}
      />

      {(step === 4) && <CatchErrors>
        <ReviewPanel {...childProps} />
      </CatchErrors>}
    </form>)

}
