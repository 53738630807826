import React from 'react';
import {camelCase, every, startCase} from 'lodash';
import ClusterLevels from "../../../logic/ClusterLevels";
import SelectionHandler from "../../../logic/SelectonHandler";
import {getDispatch} from "../../../../Utility/ReduxHelper";
import groupItemsSlice from "../../../../ReduxStore/ProductGroupPage/groupItemsSlice";
import clsx from "clsx";
import {isOutOfStock} from "../../../logic/products";

/**
 * ItemClusterLevel - one of the Select widgets within an
 * Item Cluster.
 *
 * props: ["name","cluster","clusterState"]
 */
export default function ToolItemClusterLevel(props) {
  let {name, value = "", enabled, cluster, clusterState, itemCache} = props;
  let {levelOptions} = (clusterState || {});

  const helper = new ClusterLevels({
    level: name, state: clusterState, cluster: cluster
  });

  const upstreamSelections = helper.upstreamSelections();
  console.log("UPSTREAM: ", upstreamSelections);

  // do not show application_id widget if there is just a single choice.
  let myOptions = levelOptions[name] || {};

  if (myOptions.hidden) {
    return null;
  }

  let choices = helper.getChoices();
  // example [ { "v": "8 in. Anvil" }, { "v": "9 in. Anvil" } ]

  const id = helper.getId();

  const handleSelect = (value) => {
    const dispatch = getDispatch();

    //console.error(`CLUSTER LEVEL ${name} onSelect() - value ${value}`);
    let handler = new SelectionHandler({
      name, cluster, clusterState, itemCache, value
    });

    let newState = handler.getUpdatedState();

    dispatch(groupItemsSlice.actions.updateClusterState({
      clusterId: cluster.id,
      clusterState: newState
    }));
  }

  // selector-level selector-enabled selector-{0,1,2..} selector-ready
  const className = helper.getClasses()

  let isSkuSelection = camelCase(name) === 'applicationId';

  let label = isSkuSelection ? "Select Part Number" : startCase(name);

  // ID is like cluster-first_row_floor_liners-colour, the actual select widgets
  // append -select to this, which is used to locate the selector & give it focus
  return <div id={id} className={className}>
    <div className="font-bold">{label}</div>
    <div>
      {choices.map((choice, i) => {
          let thisValue = choice.v;
          let tempSelect = Object.assign({}, upstreamSelections, {[name]: thisValue})
          let productIds = Object.keys(helper.matchingProductIndex(tempSelect));

          // keyed by product id
          let products = itemCache.getProductsById(productIds);
          let oos = (products.length > 0) && every(products, p => isOutOfStock(p));
          console.log(`${name}=${thisValue} / prods=${products.map(x => x.sku).join(' ')} oos=${oos}`)

          return <ChoiceButton
            key={i} choice={choice}
            cluster={cluster}
            selected={value && (value === thisValue)}
            outOfStock={oos}
            onSelect={handleSelect}
          />
        }
      )}
    </div>
  </div>
}


function ChoiceButton({choice, selected = false, onSelect, cluster, outOfStock = false}) {
  let value = choice.v;

  let className = clsx(
    "inline-block m-2 p-2 border cursor-pointer relative group text-center",
    ((outOfStock && !selected) ?
      "text-gray-500 border-gray-500 border-dashed" : "text-brand-dark border-brand-dark"),
    (selected ? 'bg-brand-dark text-white' : 'bg-white')
  );

  let label = choice.l || choice.v;

  function handleClick(evt) {
    onSelect(value);
  }

  return <div className="relative inline-block group">
    {outOfStock && <OOSTooltip />}
    <a className={className} onClick={handleClick}>
    {label}</a></div>
}

function OOSTooltip(props) {
  const className = clsx(
    "hidden group-hover:block  speech-bubble-bottom",
    "absolute -top-8 left-1/2 -translate-x-1/2",
    "bg-brand-dark text-brand-light z-200 rounded-lg",
    "px-4 py-1 whitespace-nowrap"
  );

  return <div
    style={{"--speech-bubble-colour": "#231F20"}}
    className={className}>
    out of stock
  </div>
}
// function extractSku(cluster, appId) {
//   console.log("extract for " + appId);
//
//   let products = values(cluster?.productIndex);
//   let product = find(products, p => {
//     // FIXME snake_case in 2025
//     let apps = p.applicationId || p.application_id || [];
//     let app = find(apps, a => (''+a.v) === (''+appId))

//     if (app) {
//       console.log("YES HAVE IT ", app)
//     }
//     console.log(`look for ${appId} in `, apps)
//   });
//   return product ? product.sku : appId;
// }
