import React from 'react';
import SkuDisplay from "../../../Common/components/SkuDisplay";
import ProductInstructions from "./ProductInstructions";
import NapaSkuDisplay from "./NapaSkuDisplay";
import FootnoteList from "./FootnoteList";
import {getClassList} from "../../../Utility/siteConfig";
import {isEmpty} from "lodash";
import {parseBulletText} from "../../logic/products";

export default function ItemFactBox({application = {}, product, className, ...props}) {
  // note application.footnote is a concatenated field with @@'s within

  className = getClassList({
    key: 'ItemFactBox',
    base: 'bg-green-200',
    append: className
  });

  const { name, vendorsku, bullets, instructionsPath } = product;
  const { footnote } = application;

  return <div className={className}>
    <div className="text-xl font-bold">{name}</div>

    <SkuDisplay sku={vendorsku}/>

    <NapaSkuDisplay product={product}/>

    <FootnoteList footnotes={footnote}/>

    { bullets && <ItemBullets text={bullets}/>}

    <ProductInstructions path={instructionsPath}/>
  </div>

}

function ItemBullets({text}) {
  let bullets = parseBulletText(text);
  if (isEmpty(bullets))
    return null;

  return <ul>
    {bullets.map((b,i) => <li key={i}>{b}</li>)}
  </ul>;
}
