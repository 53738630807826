import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {values,sortBy, last} from 'lodash';
import {getZIndex} from "../lib/zindex";
import clsx from "clsx";
import navigationSlice from "../ReduxStore/navigationSlice";

const THINGS_TO_HIDE = [ '#ju_bbox' ];

export default function ShadedOverlay({
  overlays ={},
  dispatch,
  ...props
}) {
  const overlay = selectActiveOverlay(overlays); // may be null

  const [clickedAt, setClickedAt] = useState(null);

  const overlayActive = overlay?.active || false;

  useEffect(() => {
    try {
      for (let sel of THINGS_TO_HIDE) {
        let list = document.querySelectorAll(sel);
        for (let e of list) {
          e.style.display = overlayActive ? 'none' : 'block';
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [overlayActive]);

  // close the overlay by double-clicking on it
  function handleClick(evt) {
    let t = new Date().getTime();

    // prior click? prior click less than a second ago?
    if (clickedAt) {
      let diff = t - clickedAt;
      if (diff < 1000) {
        // close all!
        dispatch(navigationSlice.actions.updateNavigation({overlays: {}}))
      }
    }

    setClickedAt(t);
  }

  if (!overlayActive)
    return null;

  const mode = overlay?.mode || 'content';

  let zIndex = getZIndex('shadeOverlay');
  let classes = [
    (mode==='full') ? 'fixed' : 'absolute',
    "block top-0 bottom-0 left-0 right-0 bg-black opacity-60"
  ]
  return <div className={clsx(classes)}
    onClick={handleClick}
    style={{zIndex: zIndex}}></div>
}

function selectActiveOverlay(overlays = {}) {
  let list = values(overlays).filter( foo => foo.active );
  list = sortBy(list, foo => foo.time);
  return last(list);
}
