import React, {useEffect} from 'react';
import _ from 'lodash';
import ChatInfoSaver from "./ChatInfoSaver";
import CatchErrors from "../Utility/CatchErrors";
import ErrorModal from "./ErrorModal";
import scrapyardSlice from "../ReduxStore/scrapyardSlice";
import FancyboxContainer from "./FancyboxContainer";
import KlaviyoInformant from "../Klaviyo/KlaviyoInformant";
import RemoveStashedUser from "./RemoveStashedUser";
import ActivateComponentTags from "./ActivateComponentTags";
import DemoComponent from "./DemoComponent";
import ShadedOverlay from "./ShadedOverlay";

const isDev = (process.env.NODE_ENV == 'development');

export default function EyeInTheSky({
  chatInfo, error, dispatch, currentAgent, customerVehicle, railsContext,
  controllerName, actionName,
  userStore = {},  // from redux userSlice
  shoppingCart,
  navigationSlice = {},
  ...props
}) {

  // cartOverview, cart, scrapyard, dispatch, controllerName, actionName
  const cartCode = extractCartCode(props);

  // 2024-03 set current agent into redux on startup
  useEffect(() => {
    dispatch(scrapyardSlice.actions.setItems({currentAgent}));
  }, []);

  // error = { message: "This is a fake error"}

  // Do NOT put presentation classes on the top level div.
  // do NOT put .relative on the top level div, it will break ShadedOverlay
  return (
    <div className="text-pencil bg-paper">
      <CatchErrors invisible={true}>
        <ShadedOverlay dispatch={dispatch} overlays={navigationSlice?.overlays}/>
      </CatchErrors>

      <CatchErrors invisible={true}>
        <ChatInfoSaver cartCode={cartCode} chatInfo={chatInfo} dispatch={dispatch}/>
      </CatchErrors>
      <CatchErrors invisible={!isDev}>
        <FancyboxContainer bindTargets="fancybox"/>
      </CatchErrors>

      {currentAgent && <CatchErrors invisible={!isDev}>
        <RemoveStashedUser
          currentAgent={currentAgent}
          cartCode={cartCode}
          customerVehicle={customerVehicle}
          controllerName={controllerName}
          user={userStore?.emailUser}
        />
      </CatchErrors>}

      {!currentAgent && <CatchErrors invisible={!isDev}>
        <KlaviyoInformant
          cartCode={cartCode}
          customerVehicle={customerVehicle}
          controllerName={controllerName}
          user={userStore?.emailUser}
          shoppingCart={shoppingCart}
        />
      </CatchErrors>}

      {isDev && <CatchErrors invisible={!isDev}>
        <DemoComponent {...props} />
      </CatchErrors>}

      <CatchErrors invisible={!isDev}>
        <ActivateComponentTags/>
      </CatchErrors>
      {error && error.message && <ErrorModal dispatch={dispatch} error={error}/>}
    </div>
  );
}

function extractCartCode({cartOverview = {}, cartSlice = {}}) {
  const {shoppingCart} = (cartSlice || {});

  let cc = shoppingCart && shoppingCart.cartCode;
  if (cc)
    return cc;

  return cartOverview && cartOverview.cartCode;
}
