import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import LocalUrlMaker from "../lib/LocalUrlMaker";
import {camelizeKeysDeep} from "./Helpers/transformObject";
import scrapyardSlice from "./scrapyardSlice";

const navigationSlice = createSlice({
  name: "navigationSlice",
  initialState: {
    dropdownState: {
      open: false,
      activeMenuId: null,
      mouseHolder: null,  // menuId of button or panel that presently owns the mouse
      mouseTime: null,    // timestamp of mouseHolder update
    },
    dropdownContent: {},
    hamburgerState: {
      open: false,
      path: []
    },
    hamburgerContent: {},
    overlays: {}
  },
  reducers: {
    updateNavigation: (state, action) => {
      Object.assign(state, action.payload);
    },

    // Add or remove a translucent overlay
    showOverlay: (state, action) => {
      const {payload} = action;
      const {name, active = false} = payload;

      if (active)
        state.overlays[name] = Object.assign({
          time: new Date().getTime()}, payload);
      else
        delete state.overlays[name];
    },

    // update dropdownState
    updateDropdown: (state, action) => {
      Object.assign(state.dropdownState, action.payload);
    },

    // Called after a delay, this will open the requested menu.
    showSelectedDropdown: (state, action) => {
      const {dropdownState} = state;
      const {mouseHolder} = dropdownState;

      // console.log("showSelectedDropdown", action.payload);
      // console.log("state: " + JSON.stringify(state.dropdownState));

      const isOpen = mouseHolder ? true : false;
      //const wasOpen = dropdownState.activeMenuId ? true : false;

      dropdownState.activeMenuId = mouseHolder;
      dropdownState.open = isOpen;

      if (isOpen) {
        state.hamburgerState = {open: false, path: []};
      }

    },

    // Merge in a new hamburger menu panel, after loading
    // it from the back end.
    addHamburgerContent: (state, action) => {
      const {payload = {}} = action;
      const {id, response = {}} = payload;

      if (id && response?.item) {
        state.hamburgerContent[+id] = response.item;
      } else {
        console.error("addHamburgerContent: malformed response: " + JSON.stringify(payload, null, 2))
      }
    },

    navigateToHamburgerPanel: (state, action) => {
      const {payload = {}} = action;
      const id = +(payload.id);

      let {hamburgerState = {}} = state;
      let {path = []} = hamburgerState;

      if (id > 0) {
        path.push(id);
      } else {
        path.pop();
      }
      state.hamburgerState.path = path;
    }
  }
});

export const navigationActions = navigationSlice.actions;

function getHamburgerContent(id) {
  let state = getReduxStore().getState();
  let {navigationSlice = {}} = state;
  let {hamburgerContent = {}} = navigationSlice;

  return hamburgerContent[+id];
}

export const loadHamburgerContent = createAsyncThunk(
  "navigationSlice/openHamburgerPanel",
  async (payload, thunkAPI) => {
    const {id} = payload; // 981

    if (id >= 1) {
      let content = getHamburgerContent(id);

      if (content) {
        console.log("content already exists for id " + id);
      } else {
        const url = LocalUrlMaker.prefixedPath(`/tube_content/${id}.json?depth=3`);
        console.log(`fetching ${url}`)
        fetch(url)
          .then(res => res.json())
          .then(response => {
            response = camelizeKeysDeep(response);
            thunkAPI.dispatch(
              navigationActions.addHamburgerContent({id, response}));
          })
      }
    }
  }
)

export const handleMouseMenuEvent = createAsyncThunk(
  "navigationSlice/mouseEvent",
  async (payload, thunkAPI) => {
    console.log("MOUSE EVENT: " + JSON.stringify(payload, null, 2));
    const {menuId, outbound = false} = payload;

    // IMMEDIATELY store mouseHolder. This will highlight
    // the navbar button, but not open the menu...
    let update = {
      mouseHolder: outbound ? null : menuId,
      mouseTime: new Date().getTime(),
    };

    // highlight this item in the menu
    thunkAPI.dispatch(navigationActions.updateDropdown(update));

    if (menuId)
      thunkAPI.dispatch(fetchDropdownContent({menuId: menuId}));

    // After a delay, tell the store to look at mouseHolder,
    // and show the menu (if still overed) or hide it (if not).
    window.setTimeout(() => {
      thunkAPI.dispatch(navigationActions.showSelectedDropdown({}))
    }, 200);
  },
);

const fetchDropdownContent = createAsyncThunk(
  "navigationSlice/fetchDropdownContent",
  async (payload, thunkAPI) => {
    const {menuId} = payload;

    let state = thunkAPI.getState()?.navigationSlice;
    let dropdownContent = state.dropdownContent || {};
    if (dropdownContent[menuId])
      return;

    const url = LocalUrlMaker.prefixedPath(`/tube_sections/${menuId}`);
    fetch(url)
      .then(r => {
        if (r.status >= 400) {
          console.error(`Menu ${menuId}: ${r.statusText || r.status}`)
        }
        return r.text();
      })
      .then(text => {
        dropdownContent = Object.assign({}, dropdownContent,
          {[menuId]: text})
        thunkAPI.dispatch(
          navigationActions.updateNavigation({dropdownContent}));
      }).catch(err => {
      thunkAPI.dispatch(scrapyardSlice.actions.setError({error: err}))
    });

  }
)
export default navigationSlice;
