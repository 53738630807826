import React from 'react';
import {isEmpty} from 'lodash';

import GroupProductsUI from "../components/GroupItems/GroupProductsUI";
import PropTypes from "prop-types";

const isDev = (process.env.NODE_ENV === 'development');

/**
 * 2025-02 this was massive, now it's tiny! Refactored to GroupPageContainer & GroupVehicleWatcher
 *
 * @param id
 * @param loading
 * @param props
 * @param pageConfig
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function GroupProductsContainer({
  id = 'product-section',  // important: ScrollToProductsButton uses this.
  loading = false,
  pageConfig = {productSection: {}},
  ...props
}) {
  let hasNothing = isEmpty(props.products);

  // deprecated: <EmptyProductsSection id={id} loading={loading} {...props}/>
  return <section id={id} className="clearfix">
    {(loading || hasNothing) ?
      (isDev ? "(products not loaded)" : null) :
      <GroupProductsUI pageConfig={pageConfig} {...props} />
    }
  </section>
}

GroupProductsContainer.propTypes = {
  // static - from the Rails template, via GroupProductsApp
  id: PropTypes.string,
  groupId: PropTypes.number.isRequired, // deprecated
  pageType: PropTypes.string.isRequired, //deprecated
  groupProperties: PropTypes.object.isRequired,
  productGroup: PropTypes.object.isRequired,

  customerVehicle: PropTypes.object,
}

