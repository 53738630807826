import React from 'react';

import CartItemQty from './CartItemQty';
import CartItemShipping from './CartItemShipping';
import ItemDetailsColumn from './ItemDetailsColumn';
import CartItemPrices from './CartItemPrices';
import CartItemWarnings from './CartItemWarnings';
import CartItemAgentItemBottom from '../agents/CartItemAgentItemBottom';
import CartItemAgentItemToolbar from '../agents/CartItemAgentItemToolbar';
import CartItemErrorDisplay from "./CartItemErrorDisplay";
import clsx from "clsx";

/**
 * 2023-04 React modernized, item is camelcase here and below.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CartItemRow(props) {
  let {item, shoppingCart, currentAgent, forecasts={}} = props;

  let forecast = forecasts?.[item.productId];

  const className = getItemClassList(item);

  return <div className={className} id={"item-" + item.id}>
    <ItemDetailsColumn {...props}  />
    <CartItemQty {...props} />
    <CartItemShipping {...props} forecast={forecast}/>
    <CartItemPrices {...props} />

    <div className="clear-me"/>
    <CartItemWarnings {...props} />
    <CartItemErrorDisplay {...props} />

    {currentAgent && <CartItemAgentItemBottom {...props} />}
    {currentAgent && <CartItemAgentItemToolbar {...props} forecast={forecast}/> }
  </div>

}

function getItemClassList(item) {
  let classes = ['cart-item'];
  if (item.extendedSavings)
    classes.push('with-savings');
  if (item.agentExtendedSavings > 0)
    classes.push('agent-adjusted');

  return clsx(classes);
}
