/*
** DO NOT EDIT THIS FILE! All changes will be overwritten.
** edit the master in app/javascript/themes/carlyle/_carlyle_theme.js,
** then run script/activate-theme carlyle
*/

//
//
//  ####     ##    #####   #        #   #  #       ######
// #    #   #  #   #    #  #         # #   #       #
// #       #    #  #    #  #          #    #       #####
// #       ######  #####   #          #    #       #
// #    #  #    #  #   #   #          #    #       #
//  ####   #    #  #    #  ######     #    ######  ######
//

// This file is installed into config/javascript/canyoneroTheme.js
// run script/activate-theme after making any changes

export const SITE_CONFIG = {
  siteName: 'carlyle',
  theme: {
    siteName: 'carlyle',
    displayName: "Carlyle Tools",
    saveCartLogo: "/img/logo/sabertooth/green-200.png",
    bgColour: "bg-brand-dark",
    textColour: "text-brand-light",
    pathPrefix: "", // for LocalUrlMaker
    mailingListName: "Carlyle Tools Newsletter",
    outOfStockUrl: "https://www.napaonline.com/brand/carlyle-tools"
  },
  components: {
    UniversalItemWidget: 'AddToolProduct',
    ItemClusterUi: 'ToolItemClusterUi',
    ItemClusterLevel: 'ToolItemClusterLevel',
    GridItem: 'ToolGridItem'
  },
  classLists: {
    // GLOBAL
    HamburgerMenu: "-md:left-0 lg:right-0 -top-0 lg:top-0",
    HamburgerMenuPanel: 'dark bg-brand-dark text-brand-light', //yes dark!
    HamburgerSearch: "text-brand-dark bg-brand-light text-xl h-full pt-4",
    HamburgerMenuLink: "text-brand-light hover:text-venom active:text-venom",

    // CATEGORY PAGE - Filter Sidebar
    GroupGridSidebar: "bg-brand-dark text-brand-light",
    FilterItem: "block font-normal relative py-1 border-b border-gray-200",
    "FilterItem.text": "text-white uppercase hover:text-brand-primary mr-8",
    "FilterItem.checkbox": "absolute top-2 right-1 text-brand-light checkbox-simple",
    FilterWidget: "mb-8 pr-2",
    FilterWidgetHeadline: "border-b border-white pb-2 mb-2",
    "FilterWidgetHeadline.text": "font-barlow text-white text-sm uppercase",

    // PRODUCT GRID
    GridItem: "bg-white p-2 notched-bl-sm",
    "GridItem.inner": "text-black",
    GridItemTitle: "uppercase font-bold text-black group-hover:underline",
    // Group Products: UniversalItemDisplay
    'CompareItems': "mt-2 relative text-right",
    'CompareItems.checkbox': "absolute top-2 right-2 text-2xl",
    'CompareNow': "uppercase font-bold text-right pr-6",
    'CompareNow.inner': "",
    'CheckToCompare': "uppercase pr-6",
    'GridPaginatorLink': "m-1 px-2 py-1 text-center rounded-md text-white cursor-pointer font-bold",
    'GridPaginatorLink.active': "border-white border-2",
    'GridPaginatorLink.inactive': "border-2 border-brand-dark",
    GridPaginatorCaption: 'hidden',
    // GROUP PAGE
    AddToCartButton: "px-16 py-2 bg-venom text-black font-bold uppercase leading-tight",
    "AddToCartButton.disabled": "px-16 py-2 bg-gray-200 text-gray-400 font-bold uppercase leading-tight",

    GoToCartButton: "px-16 py-2 bg-black text-white font-bold uppercase leading-tight",

    'DynamicCartPopup.headline': 'text-brand-dark text-sm lg:text-xl',
    'DynamicCartPopup.NewItemDisplay': 'py-4',
    // clustered product selector
    ItemClusterSelectWidget: "bg-brand-light text-black h-10 rounded-r-sm ",

    // Cart page
    AutomaticSaveCartModal: "rounded-lg bg-brand-dark text-brand-light",
    SaveCartModal: "rounded-lg bg-brand-light text-brand-dark",
  }
}
// relative mr-0 -ml-2

/*
** config/javascript/canyoneroTheme.js
** installed by ./script/activate-theme carlyle
** at Mon Mar 31 10:54:26 PM UTC 2025 on carl01
*/
