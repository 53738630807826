export const AFFIRM_MINIMUM = 25000;

export function affirmAvailableForTotal(totalCents, checkoutConfig = {}) {
  if (!affirmLoaded) return false;
  if (!totalCents) return false;

  let min = checkoutConfig?.affirmMinimum || checkoutConfig.affirm_minimum || AFFIRM_MINIMUM;

  if (+totalCents < min) {
    console.log(`affirm: ${totalCents} < ${min}, not enabled`)

    return false;
  }
  // console.log(`affirm: ${totalCents} >= ${min}, make it so`)

  return true;
}

export function affirmAvailableForCart(cart, checkoutConfig = {}) {
  if (!cart) return false;

  const {effectiveTotal} = cart;
  return affirmAvailableForTotal(effectiveTotal, checkoutConfig);
}

export function affirmLoaded() {
  const {affirm} = globalThis;

  return !!(affirm && affirm.ui)
}

export function safeRefreshAffirm() {
  const {affirm} = globalThis;

  if (!affirm) return false;

  if (affirm.ui && affirm.ui.refresh) {
    affirm.ui.refresh()
  } else {
    console.error("affirm: no refresh function")
  }

  return true;
}
