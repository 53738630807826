import {getAuthToken} from "../../ReduxStore/scrapyardSlice";
import {addRailsPrefix, snakeifyKeys} from "../../ReduxStore/Helpers/transformObject";
import PostWithParams from "../../ReduxStore/Helpers/PostWithParams";
import LocalUrlMaker from "../../lib/LocalUrlMaker";
import {SAVE_CART_CONFIG} from "./SaveCartConfig";
import {camelCase} from "lodash";

export function getSaveCartConfig(flavour) {
  flavour = camelCase(flavour);

  let config = flavour ? SAVE_CART_CONFIG[flavour] : null;
  if (!config) {
    console.warn(`no save cart config for flavour '${flavour}'`);
  }

  // merge it over fallback
  return Object.assign({flavour}, SAVE_CART_CONFIG.fallback, config)
}

export function getSaveCartFlavour(shoppingCart) {

  if (_.isEmpty(shoppingCart)) {
    console.error("SaveCartInvoker: shopping cart param is blank, can only use fallback")
    return "fallback";
  }

  // 2022 test: https://marge.aagarage.net/accessories/mgp-brake-caliper-covers
  // coupon: user already put in coupon, and it actually works.
  if (shoppingCart.specialOffer) { // object: {id, name, ...}
    if (shoppingCart.subtotalSavings > 0)  // and has actual savings...
      return 'coupon';
  }

  let {maxPublicSavings = 0, maxPersonalSavings = 0} = shoppingCart;

  // keep the flavour names as snake_case because the CSS expects that.
  if (maxPublicSavings > 0)
    return 'public_savings';

  if (maxPersonalSavings > 0)
    return 'personal_savings';

  // I give up. Whatever.
  return 'fallback';
}

export function buildSaveCartParams({formData, cartCode, authToken}) {
  authToken ||= getAuthToken();

  let params = addRailsPrefix(
    snakeifyKeys(formData),     // fooBar => foo_bar
    "saved_cart");        // foo_bar => saved_cart[foo_bar]

  return {
    cart_code: cartCode,
    authenticity_token: authToken,
    ...params
  }
}

export function buildSaveCartPost({formData, cartCode, authToken, storeState}) {
  const params = buildSaveCartParams({
    formData,
    cartCode,
    authToken: authToken || getAuthToken(storeState)
  })

  const post = new PostWithParams({
    params,
    url: LocalUrlMaker.jsonPath("/saved_carts.json")
  });

  return post;
}
