import React, {useEffect, useState} from 'react';
import CartDisplayTotal from "./CartDisplayTotal";
import CartDisplayShippingTotal from "./CartDisplayShippingTotal";
import CartSummaryBox from "./CartSummaryBox";
import {safeRefreshAffirm} from "../../../lib/affirmHelpers";
import AffirmEstimate from "./AffirmEstimate";
import {camelizeKeys} from "../../../ReduxStore/Helpers/transformObject";

// 2022-06 converted to redux and camelcase
export default function ShoppingCartTotals({
  shoppingCart, enableAffirm = false, checkoutConfig}) {

  if (!shoppingCart)
    return null;

  checkoutConfig ||= camelizeKeys(shoppingCart?.checkoutConfig)

  return <div id="cart-totals">
    <CartSummaryBox shoppingCart={shoppingCart}/>

    {enableAffirm && <AffirmEstimate checkoutConfig={checkoutConfig} amount={shoppingCart.effectiveTotal}/>}
  </div>
}





