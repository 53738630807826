import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default class ExitIntentWatcher extends Component {

  state = { subscribed: false }

  // PageLeaveWatcher's subscriber queue will invoke this.onLeave()
  onLeave = (evt) => {
    this.props.onExitPage(evt);
  }

  getService() {
    let pageEvents = globalThis.pageEventService || globalThis.pageEvents;
    if (!pageEvents) {
      console.error("cannot find page event service")
      return;
    }

    let watcher = pageEvents.page_leave_watcher;
    if (!watcher) {
      console.error("cannot find page leave subscribers");
      return;
    }
    return watcher;
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log("DID UPDATE...");
  // }

  componentDidMount() {
    this.trySubscribe();
  }

  trySubscribe() {
    if (this.state && this.state.subscribed)
      return false;

    let watcher = this.getService();
    if (!watcher || !watcher.subscribe) {
      // console.error("ExitIntentWatcher - cannot subscribe to page leave events");
      if (globalThis.setTimeout)
        globalThis.setTimeout( () => this.trySubscribe(), 100);
      return;
    }

    if (watcher && watcher.subscribe) {
      watcher.subscribe(this);
      this.setState({subscribed: true});
      console.warn("ExitIntentWatcher - SUBSCRIBED");
    }
  }

  componentWillUnmount() {
    let watcher = this.getService();

    if (watcher && watcher.subscribers) {
      let { unsubscribe } = watcher.subscribers;
      if (unsubscribe)
        unsubscribe(this);
    }
  }

  render() {
    //return <div className="debug">exit intent watcher</div>
    return null;
  }
}

ExitIntentWatcher.propTypes = {
  onExitPage: PropTypes.func.isRequired
};
