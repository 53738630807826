import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import HiddenContentWrapper from "../../../Common/components/HiddenContentWrapper";
import GenericAddressWidget from "./GenericAddressWidget";

export default function HiddenCompanyField({
  defaultVisible = false,
  wrapperClassName,
  ...props
}) {

  let caption = <p><span className="fa fa-plus"/>
    {' '}
    <span className="underline">Add line for Company Name</span>
    {' '}
    (optional)
  </p>

  return (
    <HiddenContentWrapper
      className={wrapperClassName}
      defaultVisible={!!defaultVisible}
      caption={caption}>
      <GenericAddressWidget
        {...props}
        name="company"
        autoComplete="company"
        label="Company"
        optional={true}/>
    </HiddenContentWrapper>)
}
