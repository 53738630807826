import _ from 'lodash';
import React from 'react';

import GridItemRank from "./GridItemRank";
import GridItemPicture from "./GridItemPicture";
import PromoBadge from "./PromoBadge";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";

import {reportGridItemClick} from "../../../Analytics/googleGridAdapter";
import {getClassList} from "../../../Utility/siteConfig";
import ToolGridItemInfoBlock from "./ToolGridItemInfoBlock";
import VendorskuBlock from "./VendorskuBlock";

const isDev = (process.env.NODE_ENV == 'development');

// 2023-11 modernised
export default function ToolGridItem({
  gridId,
  item,
  showRanks = false,
  flavour,
  index = -1, // printed onscreen if showRanks is true; also used for reporting
  gridState = {},
  className,  // LinearProductGrid uses this
  ...props
}) {

  if (!item)
    return null;

  // used by GridItemPicture, GridItemInfo
  const handleClick = (e) => {
    reportGridItemClick({
      e, item,
      flavour: flavour,
      index: index
    });

    // IMPORTANT: do default action for a click (follow the url)
    return true;
  }

  const path = LocalUrlMaker.prefixedPath(item.path);

  const {promos = [], vendorsku} = item;

  // group is present for hover state on links
  className = [
    "grid-item group h-full w-full relative hover:shadow",
    _.includes(item.tags, "suggest") ? 'tag-suggest' : '',
    className
  ];

  className = getClassList({
    key: 'GridItem',
    fallback: 'bg-white',
    base: className
  });

  const innerClasses = getClassList({
    key: 'GridItem.inner',
    fallback: 'border-b-2 border-gray-100 text-gray-500',
    base: "h-full relative"
  });

  return (
    <div className={className} id={`group-${item.productId || item.id}`}>
      <div className={innerClasses}>
        {vendorsku && <VendorskuBlock item={item} />}
        {promos.map((promo) =>
          <PromoBadge key={promo.type} promo={promo} item={item}/>)}

        {showRanks && <GridItemRank index={index}/>}

        <GridItemPicture item={item} path={path} handleClick={handleClick}/>

        <ToolGridItemInfoBlock item={item}
          path={path}
          handleClick={handleClick}
          gridState={gridState}
          gridId={gridId}
        />

        {isDev && <div className="debug"
          style={{position: 'absolute', right: 0, bottom: 0, fontSize: '0.7rem'}}>{item.id} {item.pt} {item.rel}</div>}
      </div>
    </div>
  );
}

