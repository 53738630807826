import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from "clsx";
import {focusOnInput} from "../Checkout/services/uiHelpers";

/**
 * Used only on the Carlyle site. This will scroll the target upwards by an amount,
 * which will reveal the bottom half of the content, a search bar (usually concealed
 * because of overflow-hidden)
 *
 * @param className
 * @param openClassName
 * @param containerId
 * @param scrollHeight
 * @param props
 * @returns {Element}
 * @constructor
 */
export default function RevealSearchFormApp({
  className = "fa fa-search",
  openClassName = "fa fa-times",
  containerId = 'top-search-wrapper',
  scrollHeight = '3rem',
  focusOn,
  ...props
}) {

  const [displayed, setDisplayed] = useState(false);

  function scrollContent(top) {
    let target = document.getElementById(containerId);

    if (target?.style)
      target.style.top = top;
    else
      console.error(`cannot find target ${containerId}`)
  }

  function handleClick(e) {
    e?.preventDefault();
    if (displayed)
      scrollContent(0);
    else {
      scrollContent(`-${scrollHeight}`)
      if (focusOn) {
        // things get weird unless you do this after scrolling is done
        window.setTimeout(() => focusOnInput(focusOn), 400);
      }
    }
    setDisplayed(x => !x);
  }

  return (
    <button onClick={handleClick}><span className={clsx(displayed ? openClassName : className)}/></button>
  );
}
