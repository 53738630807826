import React, {useState, useEffect} from 'react';

import {useSkeletonLoading} from "../hooks/useSkeletonLoading";
import {getZIndex} from "../lib/zindex";
import {describeVehicle} from "../VehicleChooser/logic/vehicle";
import NoVehicleDisplayNOL from "./NoVehicleDisplayNOL";

export default function ActiveVehicleDisplayNOL({
  dispatch, customerVehicle, showPanel, panelOpen,
  handleDelete, ...props
}) {
  const ref = useSkeletonLoading();

  const zIndex = getZIndex('showCustomerVehicle');

  const caption = describeVehicle(customerVehicle);

  const styles = {backgroundColor: '#192b94', zIndex};
  const iconClasses = panelOpen ? "fa fa-times !text-white" : "fa fa-chevron-right";

  return <div ref={ref} className="relative" style={styles}>
    <a className="!text-brand-orange font-bold p-2 block"
      onClick={() => showPanel(!panelOpen)}>
      <div className="float-right"><i className={iconClasses}/></div>
      <span className="fas fa-car-side"/>
      {' '}
      {caption}
    </a>
    {panelOpen && <div className="absolute bg-white w-full text-center text-brand-blue py-8 ">
      <a className="!text-brand-blue font-bold" onClick={handleDelete}>
        Shop Without Vehicle
      </a>
    </div>}
  </div>

}
