/**
 * Log to the console, big and bold and colourful.
 *
 * @param text
 * @param args
 */

const isDev = (process.env.NODE_ENV == 'development');

export function boldlyLog(text, ...args) {
  const styles = "font-size: 125%; color: teal; font-weight: bold;";

  return console.log("%c%s", styles, text, ...args)
}

export function boldlyLogDev(text, ...args) {
  if (isDev) {
    return boldlyLog(text, ...args);
  }
  return false;
}

export function quietlyWarn(text, ...args) {
  if (isDev) {
    console.warn(text, ...args);
  }
}
