// This file lives in config/javascript (outside of "app"!)
// and is built by script/activate-theme.
import {SITE_CONFIG} from "canyoneroTheme";
import clsx from "clsx";
import {useMemo} from "react";

const isDev = (process.env.NODE_ENV == 'development');

export const siteConfig = function (key, defaultValue = null, throwError = false) {
  if (SITE_CONFIG.hasOwnProperty(key))
    return SITE_CONFIG[key];

  let complaint = `siteConfig is missing key ${key}`;
  if (throwError) {
    console.error(complaint);
    throw new Error(complaint);
  } else {
    console.warn(complaint);
  }

  return defaultValue;
}

export const requireConfig = function (key, defaultValue = null) {
  return siteConfig(key, defaultValue, isDev);
}

const {classLists} = SITE_CONFIG;

/**
 * A hook to provide a list of classes from the theme.
 *
 * @param key name of a class list in the classLists hash from canyoneroTheme
 * @param base will be prepended to result in all cases
 * @param fallback defaults to use if not found.
 *
 * @returns {string}
 */
export const useClassList = function ({key = null, base = null, fallback = null} = {}) {
  return useMemo((() => getClassList({key, base, fallback})),
    [key, base, fallback]);
}

export const getClassList = function ({key = null, base = null, fallback = null, append=null} = {}) {
  let classes = classLists?.hasOwnProperty(key) ? classLists[key] : (
    (typeof fallback === "function") ? fallback(key) : fallback
  )
  return clsx(base, classes, append);
}

const {theme, components} = SITE_CONFIG;

export const useThemeVar = function (key, fallback = null) {
  return theme.hasOwnProperty(key) ? theme[key] : (
    (typeof fallback === "function") ? fallback(key) : fallback
  );
}

