import SkuDisplay from "../../../Common/components/SkuDisplay";
import NapaSkuDisplay from "../../../ProductGroupPage/components/StandardItems/NapaSkuDisplay";
import _ from "lodash";
import React from "react";
import CartItemVehicle from "./CartItemVehicle";
import CartItemTitle from "./CartItemTitle";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";

export default function CartItemDetail({item, url}) {
  url ||= LocalUrlMaker.prefixedPath(item.groupUrl);

  return <div className="product-details pl-2">
    <CartItemTitle item={item} url={url}/>

    <div className="item-name">{item.name}</div>

    <div className="float-left">
      <SkuDisplay sku={item.vendorsku} tagName="div"/>

      <NapaSkuDisplay sku={item.fullNapaSku} tagName="div"/>

      <CartItemVehicle item={item}/>

      <div className="options">
        {(item.cartItemOptions || [])
          .map((opt) => <CartItemOptionDisplay key={opt.name} {...opt} />)}
      </div>

      {(_.values(item.qualities))
        .map((qual, i) => <CartItemQuality key={i} {...qual} />)}
    </div>

  </div>
}

// Qualities, aka attributes; "Color: Black", that sort of thing.
function CartItemQuality({label, values}) {
  return <div className="item-quality">
    <strong>{label}: </strong>
    <strong>{values}</strong>
  </div>
}

function CartItemOptionDisplay({displayName, displayValue, regularUnitPrice}) {
  let dollars = regularUnitPrice ? new Money(regularUnitPrice).toString() : null;

  return <div className="item-option">
    <span>{displayName}: </span>
    <span>{displayValue} </span>
    {dollars && <span> {dollars.toString()} </span>}
  </div>
}
