import React from 'react';
import {size, isEmpty, sortBy, values, startCase, flatten} from 'lodash';
import {camelizeKeysDeep} from "../../../ReduxStore/Helpers/transformObject";
import AddToCartWithQuantity from "./AddToCartWithQuantity";
import clsx from "clsx";
import {parseBulletText} from "../../logic/products";

export default function AddToolProduct({
  product, products = {},
  className, ...props
}) {

  // product may be null, in that case show a greyed-out button
  product = product ? camelizeKeysDeep(product) : null;

  const multiProd = size(products) > 1;

  className = clsx(className, "my-4",
    multiProd ? "border-b border-gray-200 pb-4 " : "");
  return (
    <div className={className}>
      <ProductInfo product={product}/>
      <AddToCartWithQuantity product={product} {...props} />
    </div>
  );
}

function ProductInfo({product}) {
  if (isEmpty(product)) return null;

  const {regularPriceCents} = product;

  let price = regularPriceCents ?
    new Money(product.regularPriceCents) : null;


  return <div className="py-2 grid grid-cols-4">
    <div className="col-span-3">
      <div className="font-bold">{product.name}</div>
      <div className="">{product.vendorsku}</div>
      <ItemAttrs product={product} />
      <ItemBullets product={product}/>
    </div>
    {price && <div className="text-right text-2xl">
      {price.toString()}
    </div>}
  </div>
}

function ItemBullets({product}) {
  const bullets = parseBulletText(product?.bullets);
  if (isEmpty(bullets)) return null;

  return <ul>
    {bullets.map((b, i) => <li className="text-gray-600" key={i}>{b}</li>)}
  </ul>
}

function ItemAttrs({product}) {
  const { qualities } = product;
  if (!qualities) return null;

  const list = sortBy(flatten(values(qualities)), x => x.name);

  return <ul>
    {list.map( (attr, i) => <li key={attr.name}>
      <strong>{startCase(attr.name)}: </strong>
      {' ' + attr.value}
    </li>)}
  </ul>

}
