export const SAVE_CART_CONFIG = {
  coupon: {
    // A coupon code has been entered and results in savings.
    // 2025 example: MGP Caliper Covers
    headline: "Lock in savings of #SAVINGS",
    prompt: "Enter your email below and we'll send you a link to your cart.",
    buttonLabel: "Send my Offer",
    closeLabel: "Close"
  },
  publicSavings: {
    // There would be savings if a coupon code is entered, but that hasn't happened yet.
    // 2025 example: MGP Caliper Covers
    headline: "Save #SAVINGS on the items in your cart.",
    prompt: "Activate discount by entering your email below.",
    buttonLabel: "Activate My Discount",
    closeLabel: "No thanks, I'd rather pay full price."
  },
  personalSavings: {
    // Public savings are not possible, but we can tweak prices manually
    // 2025 example: BAK
    headline: "Your shopping cart qualifies for a discount we can’t advertise online.",
    prompt: "Enter your email below and we’ll send you a private offer!",
    buttonLabel: "Activate My Discount",
    closeLabel: "No thanks, I'd rather pay full price."
  },
  fallback: {
    // No savings possible.
    // 2025 example: Weathertech
    // Also supplies missing values for all other flavours
    headline: "Still thinking? Save your cart now and access it from any device later.",
    prompt: "Enter your email below and we'll send you a link to your cart.",
    buttonLabel: "Save Cart",
    closeLabel: "Close",
    firstNameError: "Please enter your name.",
    emailError: "We'll need your email address to save your cart and activate any savings.",
  },
  requested: {
    headline: "Save Cart"
    // all others same as fallback
    // FIXME:  Sign up for AAG Insiders Mailing List? (optional)
  }
}
