import React from 'react';
import CatchErrors from "../../../Utility/CatchErrors";
import GenericAddressWidget from "./GenericAddressWidget";

/**
 * 2025-03 - wrap each Smarty component, and fall back
 * on AddressTextWidget if smarty is disabled
 *
 * @param component
 * @param alternative
 * @param props
 * @returns {Element}
 * @constructor
 */
export default function SmartyLimiter({
  component,
  alternative=GenericAddressWidget,
  ...props}) {

  const checkoutConfig = props.checkoutConfig || props.checkout?.checkoutConfig;

  const useSmarty = !(checkoutConfig?.disableSmarty);

  const COMPONENT = useSmarty ? component : alternative;

  return <CatchErrors>
    <COMPONENT {...props}  />
  </CatchErrors>
}
