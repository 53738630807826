import React from 'react';

export default function GridItemPicture({item, path, handleClick}) {

  const imagePath = item.img;

  return (
    <div className="p-4">
      <a href={path || item.path} onClick={handleClick || (e => true) }>
        {imagePath ?
          <img className="w-full m-2" width="240" height="165"
            src={imagePath} alt={item.name}/> :
          <div style={{aspectRatio: "3/2"}}
            className="text-center text-3xl bg-gray-100 text-gray-300 pt-6 m-2 w-full">
          image not available
        </div>}
      </a>
    </div>
  );
}
