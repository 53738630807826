import React from 'react';
import PropTypes from 'prop-types';
import ItemClusterLevel from "./ItemClusterLevel";
import SelectedItemDetail from "./SelectedItemDetail";
import {camelizeKeysDeep} from "../../../../ReduxStore/Helpers/transformObject";
import {levelIsEnabled} from "../../../logic/ClusterLevels";
import ItemClusterSelectorsList from "../../StandardItems/ItemClusterSelectorsList";

/*
 * ItemClusterMain - the "widgets" component of a cluster, with
 * the selectors; the central of the three columns.
 *
 */
export default function ItemClusterMain({
  cluster, clusterState, itemCache
}) {

  const selectedItem = camelizeKeysDeep(clusterState.selectedItem);

  return <div className="cluster-main min-h-1">
    <h3 className="text-brand-primary text-xl">{cluster.headline}</h3>

    <ItemClusterSelectorsList
      itemCache={itemCache}
      cluster={cluster}
      clusterState={clusterState} />

    {selectedItem && <SelectedItemDetail item={selectedItem}/>}
  </div>
}

ItemClusterMain.propTypes = {
  products: PropTypes.object.isRequired,
  clusterState: PropTypes.object
}
