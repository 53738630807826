// Modernized 2023-05 - JS class syntax and universal-cookie

// TODO this should have been a function
// TODO maybe get rid of universal-cookie? it's a wrapper for js lib 'cookie'
import Cookies from 'universal-cookie';

export default class AgentDetector {
  // returns Object like {name: 'MattH', cart_code: 'RR-ZPLRP'}, or null
  getAgent() {
    this.agent ||= this.readAgent();
    return this.agent;
  }

  readAgent() {
    const cookies = new Cookies();
    let cookie = cookies.get('cart_agent');
    if (!cookie)
      return null;

    const words = ("" + cookie).split(',');
    if (words[0]) {
      return {
        name: words[0],
        cart_code: words[1]
      }
    }
    return null;
  }
}


