import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import BraintreeHostedFieldBuilder from "../../braintree/BraintreeHostedFieldBuilder";
import BraintreeWidget from "./BraintreeWidget";
import BraintreeEventHandler from "../../braintree/BraintreeEventHandler";
import EditObject from "../../logic/EditObject";
import ContinueButton from "../form/ContinueButton";
import SubmitBraintreeCheckout from "../../logic/SubmitBraintreeCheckout";
import DismissibleError from "../general/DismissibleError";
import ReviewAddress from "./ReviewAddress";
import ShippingAddressAnalyser from "../../logic/ShippingAddressAnalyser";
import {sendCartEvent} from "../../../Analytics/googleShoppingCart";

export default class BraintreeFormSection extends Component {

  state = {
    // create an object to hold the credit card validation
    // errors - though the card data itself is never known,
    // this lets us have standard errors/touched/changes logic.
    creditCard: EditObject.initObject()
  };

  // set state.error from payment failure message
  static getDerivedStateFromProps(props, _state) {
    const {checkout} = props;

    if (checkout) {
      const {payment} = checkout;
      if (payment && !payment.success) {
        console.log("returning state.error = " + payment.message)
        return {error: payment.message}
      }
    }

    return {}
  }

  // Need an extra componentDidUpdate() call when switching back to this tab
  // from another payment method's tab
  componentDidMount() {
    this.componentDidUpdate({});
  }

  componentDidUpdate(prevProps = {}) {
    // Watch for prop braintreeClient to arrive.
    // When it does, complete initialization.
    if (!this.state.haveClient) {
      const {braintreeClient} = this.props;

      if (braintreeClient) {
        this.setState({haveClient: true}, () => {
          this.buildFields();
        });
      }
    }
  }

  // // Save the braintree client in local state, then use it to build hosted fields.
  // receiveBraintreeClient = (client) => {
  //   this.setState({client: client}, () => this.buildFields(client));
  // }

  // Once we have the client, invoke Braintree HostedFields to build
  // the actual form widgets.
  buildFields() {
    const {braintreeClient} = this.props;

    if (braintreeClient && braintreeClient.getVersion) {
      console.log(`* Invoking Braintree hosted fields builder, client=${braintreeClient} ...`);
    } else {
      console.warn(`* Braintree Client missing or incorrect, client=${braintreeClient} ...`, braintreeClient);
    }

    const {checkout = {}} = this.props;
    const {shippingAddress, billingAddress} = checkout;

    const builder = new BraintreeHostedFieldBuilder({
      client: braintreeClient, shippingAddress, billingAddress
    });

    // may return error:
    // BraintreeError: Hosted Fields timed out when attempting to set up.
    builder.call()
      .then(fields => this.receiveHostedFields(fields))
      .catch(err => console.error(err))
  }

  // Called when hostedFields are received after being
  // initialised by BrainTree
  receiveHostedFields(hostedFields) {
    // Attach event handlers to the hostedFields instance.
    let eventHandler = new BraintreeEventHandler({
      hostedFields,
      getCard: () => this.state.creditCard,
      updateCard: (card) => this.setState({creditCard: card})
    });

    // Register callbacks (to be handled by eventHandler) with the
    // hosted fields instance.
    eventHandler.bindEvents();

    this.setState({hostedFields: hostedFields, eventHandler}, () => {
      // After assembling hosted fields, simulate a click on the label
      // to move cursor to it.

      // credit_card-cardholder_name-label or -number-label
      let e = document.getElementById('credit_card-cardholder_name-label');
      if (e && e.click)
        e.click();
    });
  }

  // Invoked when user hits submit button; this will tell Braintree to
  // stash its copy of the payment field contents and give us a nonce
  // (along with other data) that we will send to the back-end.
  //
  // Send the checkout object to the back-end, including the
  // braintreePayment object
  //
  onSubmit = (evt) => {
    if (evt && evt.preventDefault)
      evt.preventDefault();

    window.scrollTo(0, 0);

    const {checkout, callbacks} = this.props;
    const {hostedFields} = this.state;

    // Tokenize the Braintree form. Checkout will be disabled as soon as state flips to 'pending'
    const submit = new SubmitBraintreeCheckout({
      checkout, callbacks, hostedFields,
      onFailure: this.onSubmitFailed
    });
    submit.call();

    // google analytics 4
    sendCartEvent({
      eventType: 'add_payment_info',
      shoppingCart: checkout,
      payment_type: "credit_card"
    });
  }

  onSubmitFailed = ({message} = {}) => {
    if (!message)
      message = "Sorry, an unknown error has occurred.\n\nPlease check your card information and try again.";

    this.setState({error: message});
  }

  // Callback provided to GetBraintreeNonce
  finishCheckout = (payload) => {
    const {callbacks} = this.props;
    const {editCheckout} = callbacks;

    // console.error("FINISH CHECKOUT - RECEIVE BRAINTREE NONCE", payload)

    // use an editCheckout to save into checkout redux
    // then post to back-end /new_checkout/XX/braintree_checkout
    editCheckout({braintreeCheckout: payload})
  }

  // Clear both the local error (state.error), and also discard
  // any payment object in the checkout.
  onClearPaymentError = () => {
    let {checkout, callbacks} = this.props;

    this.setState({error: null});

    if (checkout.payment) {
      checkout = _.omit(checkout, 'payment');
      callbacks.setCheckout({checkout: checkout})
    }
  }

  /**
   * Init sequence:
   *  - render once with no client
   *  - componentDidMount happens, gets the client
   *  - renders again with client - renders empty divs where fields go
   *  - componentDidMount's callback happens, invoke hosted field builder
   *  - renders again with client and hostedFields - HF will take over those divs.
   *
   * @returns {JSX.Element}
   */
  render() {
    const {creditCard, error} = this.state;
    const {checkout = {}, callbacks} = this.props;
    const {shippingAddress} = checkout;

    let impediments = new ShippingAddressAnalyser(checkout).shippingImpediments();

    // Note field names here are the snake-cased versions of
    // Braintree's field names.
    return (
      <div className="lg:pr-40">
        <ReviewAddress
          usage="shipping"
          impediments={impediments}
          address={shippingAddress}
          handleChangeAddress={callbacks.changeAddress}/>

        <div className="">
          <BraintreeWidget object={creditCard}
            name="cardholder_name" label="Name on Card"/>

          <BraintreeWidget object={creditCard}
            name="number" label="Card Number"/>

          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
            <BraintreeWidget object={creditCard}
              name="expiration_month" label="Month"/>

            <BraintreeWidget object={creditCard}
              name="expiration_year" label="Year"/>

            <BraintreeWidget object={creditCard}
              name="cvv" label="CVV"/>
          </div>
        </div>

        {error && <DismissibleError
          dismissError={this.onClearPaymentError}
          error={error}/>}

        <ContinueButton
          name="pay-with-braintree"
          disabled={this.props.status === "pending"}
          caption="Place Order"
          onSubmit={this.onSubmit}
        />
      </div>
    );

    // <BraintreeWidget object={creditCard} name="postal_code" label="Billing Zip Code"/>
  }
}

BraintreeFormSection.propTypes = {
  braintreeClient: PropTypes.object, // braintree { client, status, ...} - may be null
  checkout: PropTypes.object.isRequired,
  callbacks: PropTypes.object.isRequired
};
