import React, {useState} from 'react';
import ReviewAddress from "../payment/ReviewAddress";
import ContinueButton from "../form/ContinueButton";
import SubmitPaypalCheckout from "../../logic/SubmitPaypalCheckout";
import SubscribingWidget from "../address/SubscribingWidget";
import ShippingAddressAnalyser from "../../logic/ShippingAddressAnalyser";
import {sendCartEvent} from "../../../Analytics/googleShoppingCart";

/**
 * Step 4! After returning from Paypal, present the user with
 * the updated tax/total.
 */
export default function PaypalReviewSection(props) {
  const {checkout, callbacks, paymentType, addressType = 'shipping'} = props;
  const {shippingAddress = {}} = checkout;

  const [disabled, setDisabled] = useState(false);

  const impediments = new ShippingAddressAnalyser(checkout).shippingImpediments();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    let submit = new SubmitPaypalCheckout({checkout, callbacks});
    submit.call();

    // google analytics 4
    sendCartEvent({
      eventType: 'add_payment_info',
      shoppingCart: checkout,
      payment_type: "paypal"
    });
  }

  const childCallbacks = {
    focusChanged: () => {} // dontcare
  }

  return (
    <div className="paypal-review-section">
      <h2>Review & Complete Paypal Order</h2>

      <p className="paypal-address-warning">
        <strong>Ship-To Address Note: </strong>

        Due to our agreements with PayPal, we can only ship
        to the address on your PayPal account. This will override
        any address entered on the previous screen. To ship elsewhere,
        choose a different payment method.
      </p>

      <ReviewAddress
        usage="shipping"
        impediments={impediments}
        address={shippingAddress}
        paymentType={paymentType || 'paypal'}
      />

      <SubscribingWidget address={shippingAddress || {}} callbacks={childCallbacks}/>

      <ContinueButton disabled={disabled || !_.isEmpty(impediments)}
        id="complete-order-paypal" caption="" onSubmit={handleSubmit}>
        Complete Order
      </ContinueButton>
    </div>
  );
}

