import React from 'react';
import clsx from 'clsx';
import {times} from 'lodash';

const fullStar = 'fas fa-star';
const halfStar = 'fas fa-star-half-alt';
const emptyStar = 'far fa-star';

// 2023-11 modernised
// 2025-02 use FontAwesome stars
export default function RatingWidget({
  caption,
  className = "block text-brand-blue text-sm mb-1",
  captionClasses = "hidden md:inline-block mr-2",
  starClasses = 'whitespace-nowrap text-brand-secondary',
  item
}) {

  const rating = +item.r; // 0 to 5
  const reviewCount = +item.rc;

  // 2023-04 add no-rating
  if (isNaN(reviewCount) || isNaN(rating) || (reviewCount < 1) || (rating < 1)) {
    return <div className="no-rating">&nbsp;</div>
  }

  const stars = starsFor(rating);

  const formattedCount = (+reviewCount).toLocaleString();

  //  reviewCount > 999 ? (reviewCount/1000).toFixed(1) + "K" : reviewCount;
  return <div className={clsx(className)}>
    {caption && <span
      className={clsx(captionClasses)}> {caption} </span>}
    <span className={clsx(starClasses)}>{times(5).map(n => {
      return <i key={n} className={stars[n] || emptyStar}/>
    })}</span>
    <span className="inline-block ml-2"> {formattedCount} </span>
  </div>
}

function starsFor(rating) {
  let full = Math.floor(rating);
  let frac = rating - full;

  let out = times(full).map( n => fullStar);
  if (frac >= 0.75)
    out.push(fullStar);
  else if (frac >= 0.25)
    out.push(halfStar);

  return out;
}
