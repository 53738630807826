import React from 'react';
import PropTypes from 'prop-types';
import {size} from 'lodash';

import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import {setGridPage} from "../../../ReduxStore/GroupGrid/groupGridSlice";
import gridFilterSlice from "../../../ReduxStore/GroupGrid/gridFilterSlice";
import {useDispatch} from "react-redux";
import clsx from "clsx";
import {getClassList, useClassList} from "../../../Utility/siteConfig";

// 2024-05 gridId aware
// 2023-11 MH modernised
export default function FilterItem({
  gridId,
  filter,
  filterOption,
  disabled = false,   // NO LONGER SUPPORTED, we exclude the disabled ones
  selected = false,
  filtersTouched = [],
  buckets = {},
  ...props
}) {
  const dispatch = useDispatch();

  const className = useClassList({
    key: 'FilterItem',
    fallback: 'block text-sm font-normal'
  })

  const textClasses = useClassList({
    key: 'FilterItem.text',
    fallback: 'ml-2 text-gray-700 hover:text-brand-primary'
  })

  const checkboxClasses = useClassList({
    key: 'FilterItem.checkbox',
    fallback: "checkbox-simple"
  });

  // link to target only if filters are untouched
  const targetPath = _.isEmpty(filtersTouched) ?
    targetPathFor(filterOption) : null;

  const handleClick = e => {
    if (targetPath) {
      document.location = targetPath;
      return;
    }

    // now tell gridFilterSlice that we've changed a filter selection
    const payload = {
      gridId,
      filterName: filter.name,
      id: filterOption.id,
      selected: !selected
    };

    dispatch(gridFilterSlice.actions.selectFilterItem(payload))

    // go back to page 1 of product grid, as this change might reduce the number of pages possible
    setGridPage({dispatch, gridId, page: 1});
  }

  // the selectable value like "209010"
  const filterValue = filterOption.id;
  const matchCount = size(buckets[filterValue] || buckets["" + filterValue]);

  return (<label className={className}>
    <input type="checkbox"
      className={checkboxClasses}
      name={`${gridId}-${filterValue}`}
      value={filterValue}
      disabled={disabled}
      onChange={disabled ? null : handleClick} checked={selected}/>

    {targetPath ?
      <a className={textClasses} href={targetPath}>{filterOption.name}
      </a> :
      <span className={textClasses}>
        {filterOption.name}
        {' '}

        {matchCount && <span className="text-gray-400 text-xs">({matchCount})</span>}

      </span>
    }
  </label>);
}

function targetPathFor(filterOption) {
  if (filterOption.path)
    return LocalUrlMaker.prefixedPath(filterOption.path);
  return null;
}

FilterItem.propTypes = {
  gridId: PropTypes.string.isRequired,
  flavour: PropTypes.string,
  filterName: PropTypes.string,   // one of: categories, brands
  selected: PropTypes.bool,
  filterOption: PropTypes.shape({
    id: PropTypes.string,     // 133020
    name: PropTypes.string,  // Neoprene Seat Covers
    sortOrder: PropTypes.number,  // 0
    count: PropTypes.number, // 5
    path: PropTypes.string  // /Seat-Covers/Neoprene
  }).isRequired
}
