import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import {useClassList} from "../../../Utility/siteConfig";

export default function FilterWidgetHeadline({
  gridId,
  headline,
  handleToggleOpen = (e => null),
  isOpen=false,
  ...props}) {

  const className=useClassList({
    key: 'FilterWidgetHeadline',
    fallback: 'border-gray-300 border-b pb-2',
    base: 'relative'
  });

  const textClasses=useClassList({
    key: 'FilterWidgetHeadline.text',
    fallback: 'text-md font-normal uppercase text-gray-600 font-sans',
  });

  const iconClass = clsx("font-normal absolute right-2 top-1",
    isOpen ? "fa fa-angle-down" : "fa fa-angle-up");

  return <h4 className={className} >
    <a className={textClasses}
      onClick={handleToggleOpen}>
      {headline}
      <span className={iconClass}></span>
    </a>
  </h4>
}
