import React from 'react';
import ContinueButton from "../form/ContinueButton";
import ReviewAddress from "./ReviewAddress";

export default function AgentNoPaymentFormSection(props) {
  const {checkout = {}, callbacks} = props;
  const {shippingAddress = {}, cartCode} = checkout;

  if (!cartCode) return null;

  let url = `/cart/${cartCode}/paypal_checkout/new`

  const handleSubmit = (evt) => {
    evt.stopPropagation();

    const {checkout, callbacks} = props;

    //callbacks.setCheckout({status: 'pending' })

    callbacks.saveCheckout({
      checkout: checkout,
      route: 'agent_payment'
    });
  }

  return (
    <div className="agent-none-form-section">

      <ReviewAddress impediments={[]}
        usage="shipping"
        address={shippingAddress}
        handleChangeAddress={callbacks.changeAddress}/>

      <h2 className="agent-bg" style={{color: 'white', padding: "1rem", borderRadius: "1rem"}}>
        <span className="fa fa-user-secret"/>
        {' '} Create Order Without Payment</h2>

      <p className="agent-fg">
        <strong>Agents Only:</strong>
        {' '}
        Use this to create an order without requiring payment
        or when payment is to be settled later.
      </p>

      <ContinueButton id="pay-with-agent" caption="" onSubmit={handleSubmit}>
        <span className="fa fa-user-secret"/>
        {' '}
        Create Order
      </ContinueButton>
    </div>
  );
}

