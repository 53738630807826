import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from "clsx";
import HamburgerMenuInner from "./HamburgerMenuInner";
import {getZIndex} from "../lib/zindex";
import {navigationActions} from "../ReduxStore/navigationSlice";
import {getClassList} from "../Utility/siteConfig";

export default function HamburgerMenu({
  hamburgerState = {open: false},
  dispatch,
  className,
  config={},

  //rootItems = [],
  ...props
}) {
  const {open} = hamburgerState;

  const ref = useRef(null);
  const [styles, setStyles] = useState({height: 0, width: 0});
  const [menuWidth, setMenuWidth] = useState(0);

  const zIndex = getZIndex('hamburgerMenu');

  // make a function to call from inside useEffect
  const showOverlay = useCallback((active) => {
    dispatch(navigationActions.showOverlay({
      // show/hide overlay
      name: 'hamburger',
      active: active, // true/false
      mode: 'content'
    }));
  }, [dispatch]);

  // Size the menu appropriately for the browser.
  useEffect(() => {
    let height = 0;
    let width = 0;

    if (ref?.current && open) {
      let elem = ref.current;
      let rect = elem.getBoundingClientRect(); // top, bottom, ...
      height = window.innerHeight - rect.top;
      width = window.innerWidth > 600 ? 360 : (0.98 * window.innerWidth);
    }

    setStyles({height: height, width: width, zIndex: zIndex});
    setMenuWidth(width);
  }, [ref?.current, open])

  useEffect(() => {
    showOverlay(open);

    // cleanup - nix overlay on close
    return () => showOverlay(false);
  }, [open]);

  className = getClassList({
    key: 'HamburgerMenu',
    base: [
      "absolute transition-width duration-1000", // overflow-y-scroll
    ],
    // to make it open from right side, override this in XX_theme.js and put in right-0
    fallback: "left-0 -top-24 lg:top-0",  // bg-white
    append: className
  });

  //
  // return <div className="bg-red-400 container mx-auto relative"> HI BOB
  // </div>

  // The container is ALWAYS present, but has height/width zero when menu is not open.
  // return <div className="bg-red-400 container mx-auto relative">

  //return //<div className="w-auto bg-white overflow-hidden"> id="hamburger-menu"
  return <div ref={ref}
    className={className}
    style={styles}>
    {open && <HamburgerMenuInner
      config={config}
      menuWidth={menuWidth}
      hamburgerState={hamburgerState}
      {...props}/>}
  </div>
  //</div>
}
