import React, {useState, useEffect, useDebugValue} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import GroupPageType from "../models/GroupPageType";

import {loadGroupItems} from "../../ReduxStore/ProductGroupPage/groupItemsSlice";
import {getDispatch} from "../../Utility/ReduxHelper";

//  ["groupId","pageType","productGroup","groupProperties","shoppingCart","dynamicCart","customerVehicle","vehicleContext","dispatch","id"]
/**
 * 2025-02 refactored from GroupProductsContainer.
 *
 * @param pageType {String}
 * @param groupId {Number}
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function GroupProductsLoader({
  productGroup={}, ...props}) {

  useEffect(() => {
    const dispatch = getDispatch();
    const { id, universal } = productGroup;

    if (id && universal) {
      // underscore case as these are URL params
      dispatch(loadGroupItems({params: {group_id: id}}));
    }
  }, [productGroup?.id]);

  return null;
}
