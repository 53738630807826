import React, {useState, useEffect} from 'react';

import createLightGallery from "../../Common/services/createLightGallery";
import {bindScrollButtons} from "../../Utility/scroll";
import GroupProductsLoader from "./GroupProductsLoader";
import DynamicCartApp from "../../DynamicCart/container/DynamicCartApp";
import ReportGroupView from "../components/Reporting/ReportGroupView";
import CatchErrors from "../../Utility/CatchErrors";
import GroupVehicleWatcher from "./GroupVehicleWatcher";

// cartCode groupId pageType
// productGroup {id,name,universal,path,brandName,topCategories}, groupProperties={}
// redux adds shoppingCart dynamicCart customerVehicle vehicleContext clusterIndex itemApplications
// allApps products productDetail dispatch
export default function GroupPageContainer({...props}) {
  const {cartCode, productGroup = {}, customerVehicle} = props;
  const {universal = false} = productGroup;
  // <div>{JSON.stringify(_.keys(props), null, 2)}</div>

  return <div>
    <CatchErrors><DynamicCartApp cartCode={cartCode}/></CatchErrors>
    <CatchErrors><GroupProductsLoader productGroup={productGroup}/></CatchErrors>
    <CatchErrors><ReportGroupView productGroup={productGroup}/></CatchErrors>
    <CatchErrors><LightGalleryLauncher/></CatchErrors>
    <CatchErrors><InitReviewLinks/></CatchErrors>

    {!universal && <CatchErrors>
      <GroupVehicleWatcher productGroup={productGroup} customerVehicle={customerVehicle}/>
    </CatchErrors>}
  </div>
}

function LightGalleryLauncher(_props) {
  //const [gallery, setGallery] = useState(null);

  function buildGallery() {
    let g = createLightGallery('product-group-gallery', {
      selector: '.pic',
    });
  }

  useEffect(buildGallery, []); // run once

  return null;
}

function InitReviewLinks(_props) {
  useEffect(() => {
    bindScrollButtons('.link-to-reviews', 'group-review-summary')
  }, []); // run once

  return null;
}
