import React from 'react';
import {includes} from 'lodash';
import {useDispatch} from "react-redux";

import groupGridSlice from "../../../ReduxStore/GroupGrid/groupGridSlice";
import CompareNow from "./CompareNow";
import CheckToCompare from "./CheckToCompare";

/**
 * 2024-11 reviewed, pure tailwind
 *
 * DESKTOP ONLY. This shows one of two widgets, a "Check to Compare"
 * label/checkbox, or a "Compare Now" button (once two have been selected).
 *
 * @param gridId
 * @param item
 * @param gridState
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function GridItemCompare({
  gridId, item, gridState
}) {
  if (!gridState || !item || !gridId) return null;

  const dispatch = useDispatch();
  const {compareList = []} = gridState;
  const {id} = item;
  const selected = includes(compareList, +id);

  const handleSelect = e => {
    let newList = compareList;

    if (selected) {  // remove it.
      newList = compareList.filter(n => n !== id);
    } else { // add it
      if (compareList.length >= 5) {
        alert("Maximum of 5 items can be compared at a time.")
      } else {
        newList = [...compareList, id];
      }
    }

    // noinspection JSVoidFunctionReturnValueUsed
    dispatch(groupGridSlice.actions.updateGridState({
      gridId,
      gridState: {compareList: newList}
    }));
  }

  const fieldId = `${gridId}-cmp-${item.id}`; // silence google Issues tab

  const canCompare = (selected && compareList.length > 1);
  if (canCompare) {
    return <CompareNow id={fieldId} compareList={compareList}
      handleSelect={handleSelect}/>
  } else {
    return <CheckToCompare id={fieldId} selected={selected}
      handleSelect={handleSelect}/>
  }
}


