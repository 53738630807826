import React from "react";

export default function CartRefresh({callbacks}) {

  const onClick = (evt) => {
    evt?.preventDefault && evt.preventDefault();

    window.scrollTo(0, 0);

    callbacks.loadCart();
  }

  return <div className="text-sm p-4">
    <a className="cursor-pointer !text-gray-400" onClick={onClick}>
      <span className="fa fa-sync"/>
      {' Refresh'}
    </a>
  </div>
}
