import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AddSingleItemUI from "../components/SingleItem/AddSingleItemUI";
import ScrollToProductsButton from "../../VehicleChooser/components/ScrollToProductsButton";

const isDev = (process.env.NODE_ENV == 'development');

export default class AddSingleItemContainer extends Component {


  getProductList() {
    const { products } = this.props;

    return products ? _.values(products) : [];
  }

  render() {
    let products = this.getProductList();

    if (_.isEmpty(products)) {
      if (isDev) { return <div>AddSingleItemContainer: products not loaded</div>}
      return <div/>;
    }

    if (products.length === 1) {
      return <AddSingleItemUI {...this.props} product={products[0]} />
    } else {
      // present a "Scroll to Options" button. This is wrapped in a .vehicle-selector
      // div for CSS purposes only
      return <div className="vehicle-selector"><ScrollToProductsButton /></div>;
    }
  }
}

AddSingleItemContainer.propTypes = {
  products: PropTypes.object
};
