import React, {useEffect} from 'react';
import cartSlice from "../../ReduxStore/ShoppingCart/cartSlice";

import Modal from "../../Modals/Modal";
import DynamicCartDisplay from "../components/DynamicCartDisplay";
import {camelizeKeysDeep} from "../../ReduxStore/Helpers/transformObject";
import {getDispatch} from "../../Utility/ReduxHelper";

const isDev = (process.env.NODE_ENV == 'development');

/**
 * DynamicCartPopup - ALWAYS present on a group page, this
 * receives the dynamicCart property with a boolean "open" value,
 * which controls the display of DynamicCartModal.
 *
 * 2023-04 props are cartCode, customerVehicle, dispatch,
 * dynamicCart: {open: true}, newItemIds: [id,id],
 * recommendations, shoppingCart
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function DynamicCartPopup(props) {
  const {dynamicCart = {open: false}, shoppingCart} = props;
  const {open} = dynamicCart;

  const {updateDynamicCart} = cartSlice.actions;

  const customerVehicle = camelizeKeysDeep(props.customerVehicle);

  const handleClose = ({label = 'keep_shopping'} = {}) => {
    const dispatch = getDispatch();
    dispatch(updateDynamicCart({open: false}));
  }

  if (!open) {
    // if (isDev)
    //   return <div className="debug">DynamicCartApp: {JSON.stringify(_.keys(props), null, 2)} </div>;
    return null;
  }

  // before DynamicCartApp: [ "cartCode", "shoppingCart", "dynamicCart", "customerVehicle", "dispatch" ]
  // after  DynamicCartApp: [ "cartCode", "shoppingCart", "dynamicCart", "newItemIds", "recommendations", "customerVehicle", "dispatch" ]
  return <Modal id="dynamic-cart-modal" handleClose={handleClose}>
      <div className="max-w-sm lg:max-w-4xl mx-auto w-auto">
        {(shoppingCart && shoppingCart.cartCode) ?
          <DynamicCartDisplay {...props}
            handleClose={handleClose}
            customerVehicle={customerVehicle}
          />
          : <p>Loading...</p>}
      </div>
    </Modal>
}

