import lightGallery from "lightgallery";
import lgVideo from 'lightgallery/plugins/video';
import EmbeddedConfig from "../../lib/EmbeddedConfig";
import {quietlyWarn} from "../../Utility/logging";

const GALLERY_DEFAULTS = {
  // licenseKey: no longer stored here
  closable: true,
  selector: '.pic',
  closeOnTap: true,
  plugins: [lgVideo],
  youTubePlayerParams: {
    //modestbranding : 0,
  }
};

function resolveContainer(container) {
  let element = container;

  if ((typeof element) == 'string') {
    element = document.getElementById(container);
  }

  return element;
}

export default function createLightGallery(container_or_id, options = {}) {
  const container = resolveContainer(container_or_id);
  if (!container) {
    quietlyWarn("cannot find container for light gallery", container_or_id);
    return null;
  }

  const licenseKey = EmbeddedConfig.instance().fetchKey('lg');

  options = _.extend({licenseKey}, GALLERY_DEFAULTS, options);

  return lightGallery(container, options);
}
