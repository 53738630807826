import React from 'react';
import PropTypes from 'prop-types';
import {keys, minBy, pick, values} from 'lodash';

// FIXME: using my own Money library, not good.
import Money from '../../../../lib/Money';

// FIXME: not camelized

export default function CartPriceDisplay(props) {
  let {product, matchingProducts, products} = props;

  let dollars = getPrice({product, matchingProducts, products});
  if (!dollars) return null;

  return (
    <div className="cart-price-display">
      <div className="unit-price">{dollars.toString()}</div>
      <div className="subtitle">Free Shipping</div>
    </div>
  );
}

function getPrice({product, matchingProducts, products}) {
  if (!product) {
    product = selectCheapestProduct({matchingProducts, products});
  }

  if (!product)
    return null;

  // FIXME underscore
  let {regular_price_cents} = product;

  return new Money(regular_price_cents);
}

function selectCheapestProduct({matchingProducts, products}) {
  if (!matchingProducts || !products)
    return null;

  let goodProds = values(pick(products, keys(matchingProducts)));

  // FIXME underscore
  goodProds = goodProds.filter((p) => p.regular_price_cents);
  return minBy(goodProds, p => p.regular_price_cents);
}

CartPriceDisplay.propTypes = {
  application: PropTypes.object,
  product: PropTypes.object,
  matchingProducts: PropTypes.object,
  products: PropTypes.object,
  productGroup: PropTypes.object
}
