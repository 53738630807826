import React from 'react';
import RatingWidget from "./RatingWidget";
import {getClassList} from "../../../Utility/siteConfig";

// 2023-11 modernised
// 2024-11 modernised some more
export default function GridItemTitle({item, path, handleClick = (e => true)}) {
  const className = getClassList({
    key: 'GridItemTitle',
    fallback: "text-gray-800 group-hover:text-brand-orange group-hover:underline"
  })

  let name = (''+item.name).replaceAll(
    /\/([a-zA-Z])/g,
    (m, a, b) => { return ` / ${a}`} )

  return (
    <div className="leading-tight">
      <div className="align-top text-left text-md min-h-14">
        <a className={className}
          href={path} onClick={handleClick}>{name}</a>
      </div>
    </div>
  );

}
