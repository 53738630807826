import React, {useEffect, useState} from 'react';
import VehicleChooserApp from "../VehicleChooser/container/VehicleChooserApp";
import {useSkeletonLoading} from "../hooks/useSkeletonLoading";
import PullDownSelector from "./PulldownSelector";
import {getZIndex} from "../lib/zindex";

/**
 * Shown when no vehicle is selected.
 */
export default function NoVehicleDisplayNOL({
  panelOpen = false,
  showPanel,
  disableSelector = false,
  selectorProps = {},
  caption = "Add New Vehicle"
}) {
  const ref = useSkeletonLoading();

  const handleClick = () => {
    if (!disableSelector)
      showPanel(!panelOpen);
  }

  // not brand-blue, this is a tint
  const backgroundColor = '#192b94';

  const zIndex = getZIndex('showCustomerVehicle');

  if (disableSelector) {
    caption = "";
  }
  return <div ref={ref} className="relative" style={{zIndex}}>
    <a style={{backgroundColor}}
      className="block p-2 text-white hover:text-white cursor-pointer font-bold"
      onClick={handleClick}>
      {!disableSelector && <div className="float-right"><span
        className={panelOpen ? 'fas fa-times' : 'text-sm fas fa-plus'} /></div>}

      <span className="fas fa-car-side"/>
      {' ' + caption}
    </a>
    {panelOpen &&
      <PullDownSelector selectorProps={selectorProps} handleClose={handleClick}/>}
  </div>
}

