import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function HamburgerBackLink({handleBackClick, ...props}) {

  return <div className="bg-gray-100 p-2 pt-4">
    <a className="text-gray-600 text-lg font-bold font-napa-bold"
      onClick={handleBackClick}>
      <span className="fas fa-chevron-left"/>
      {' '}
      BACK
    </a>
  </div>
}
