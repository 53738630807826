import React  from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

/**
 * App.footnote is internally delimited by @@. Split it.
 *
 * @param footnote
 * @param TagName
 * @param className
 * @returns {unknown[]|null}
 * @constructor
 */
export default function FootnoteList(
  {
    footnotes,
    className = "text-sm text-grey-400"
  }) {

  if (isEmpty(footnotes)) return null;

  footnotes = splitCombinedFootnote(footnotes);
  return footnotes.map((f, i) => <div key={i} className={clsx(className)}>{f}</div>);
}

function splitCombinedFootnote(allFootnotes) {
  if (Array.isArray(allFootnotes))
    return allFootnotes;

  return ('' + allFootnotes).split(/@@/);
}
