import React, {useEffect, useState} from 'react';
import StartAffirmButton from './StartAffirmButton';
import SaveCartButton from './SaveCartButton';
import PaypalCheckoutButton from './PaypalCheckoutButton';
import CheckoutPath from "../../services/CheckoutPath";
import {applePayAvailable} from "../../services/applePayHelpers";
import {getEnabledPaymentTypes} from "../../services/checkoutConfig";
import AlternatePayment from "./AlternatePayment";
import ApplePayButton from "../../../Checkout/components/payment/ApplePayButton";
import {reportStartCheckout} from "../utility/startCheckout";
import {getClassList} from "../../../Utility/siteConfig";
import CheckoutButton from "./CheckoutButton";

export default function CartSidebarButtonBox(props) {
  let {cartCode, shoppingCart = {}, currentAgent,
    checkoutPath, enableAffirm = false, dispatch, checkoutConfig} = props;

  checkoutPath || (checkoutPath = getCheckoutPath(cartCode, shoppingCart));

  const [applePayExists, setApplePayExists] = useState(false);

  useEffect(() => {setApplePayExists(applePayAvailable())}, []);

  let ptList = getEnabledPaymentTypes({
    shoppingCart, currentAgent, checkoutConfig});

  const paymentTypes = _.keyBy(ptList, x => x);

  return (<div id="cart-sidebar-button-box">
    <SaveCartButton {...props} />

    <CheckoutButton
      shoppingCart={shoppingCart}
      cartCode={cartCode}
      checkoutPath={checkoutPath} />

    <div id="other-checkouts">
      <AlternatePayment visible={paymentTypes['affirm'] && enableAffirm}>
        <StartAffirmButton
          {...props} checkoutPath={checkoutPath}/>
      </AlternatePayment>

      <AlternatePayment visible={applePayExists && paymentTypes['apple']}>
        <ApplePayButton {...props}
          checkoutPath={checkoutPath} dispatch={dispatch}/>
      </AlternatePayment>

      <AlternatePayment visible={paymentTypes['paypal']}>
        <PaypalCheckoutButton {...props}
          checkoutPath={checkoutPath}/>
      </AlternatePayment>

    </div>
  </div>);

}

function getCheckoutPath(cartCode, shoppingCart) {
  let cp = new CheckoutPath({
    cartCode, cart: shoppingCart
  })
  return cp.toString()
}
