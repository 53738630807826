import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const isDev = (process.env.NODE_ENV == 'development');

function compareVehicles(oldVehicle, newVehicle, watchedFields) {
  oldVehicle = oldVehicle || {};
  newVehicle = newVehicle || {};

  let changes = {};
  // console.error("compare:" , oldVehicle, newVehicle);

  for (let k of watchedFields) {
    let old = oldVehicle[k];
    let value = newVehicle[k];
    if (old !== value) {
      changes[k] = [old, value];
    }
  }

  return changes;
}

/**
 * VehicleChangeWatcher - this component watches its properties for
 * changes in the customerVehicle. When this happens, it loads products.
 *
 * // props - onVehicleChanged, customerVehicle, watchedFields
 */
export default class VehicleChangeWatcher extends Component {

  componentDidMount() {
    this.componentDidUpdate({}, {});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {watchedFields = []} = this.props;

    let oldVehicle = prevProps.customerVehicle || {};
    let newVehicle = this.props.customerVehicle || {};

    let changes = compareVehicles(oldVehicle, newVehicle, watchedFields);

    if (_.isEmpty(changes))
      return;

    // 2024-08 this is now a no-op, because KlaviyoInformant watches for vehicle changes.
    // 2022-05 report change in vehicle to Klaviyo
    // this.reportVehicle();

    this.vehicleDidChange({changes, oldVehicle, newVehicle});
  }

  vehicleDidChange({changes, oldVehicle, newVehicle}) {
    // on category pages this is in GridWithControls and does a reload.
    const {onVehicleChanged} = this.props;

    if (typeof onVehicleChanged == 'function') {
      return onVehicleChanged(changes);
    }

    if (typeof onVehicleChanged == 'object') {
      const {action, target} = onVehicleChanged;

      if (action === 'redirect') {
        return this.redirectOnChange({target, oldVehicle, newVehicle})
      }
    }

    console.error("unknown onVehicleChanged action: " +
      JSON.stringify(onVehicleChanged, null, 2))
  }

  redirectOnChange({target, oldVehicle, newVehicle}) {
    // 2022-08 do not redirect when setting initial vehicle
    if (_.isEmpty(newVehicle) || (newVehicle.source === 'initial')) {
      return false;
    }

    if (target) {
      // "set-vehicle=" + newVehicle.id;
      window.location = target + '?set-vehicle=1';

      return true;
    }
  }

  // compare old/new vehicles (either may be empty)

  render() {
    // if (isDev) {
    //   const {watchedFields, customerVehicle} = this.props;
    //
    //   return <div className="debug">VehicleChangeWatcher:
    //     props={JSON.stringify(_.keys(this.props), null, 2)}
    //     vehicle={JSON.stringify(customerVehicle, null, 2)}
    //     watchedFields={JSON.stringify(watchedFields, null, 2)} </div>
    // }
    return null;
  }
}

VehicleChangeWatcher.propTypes = {
  customerVehicle: PropTypes.object,
  watchedFields: PropTypes.array.isRequired,
  onVehicleChanged: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
};

VehicleChangeWatcher.defaultProps = {
  // FIXME camelCase
  watchedFields: ['vehicle_make_id', 'fitment', 'lloyd_unique']
};
