import React from 'react';
import PropTypes from 'prop-types';

import GroupPageType from "../../models/GroupPageType";
import LloydContainer from "../../lloyd/container/LloydContainer";
import UniversalItemList from "../StandardItems/UniversalItemList";
import ClusteredItemList from "../ClusteredItems/main/ClusteredItemList";
import StandardItemList from "../StandardItems/StandardItemList";

// 2024-04 props:   groupId pageType productGroup groupProperties
// shoppingCart dynamicCart customerVehicle vehicleContext clusterIndex
// itemApplications allApps products productDetail dispatch

export default function GroupProductsUi({id, loading, pageType, ...props}) {

  let pt = new GroupPageType(pageType);

  if (pt.lloyd()) {
    const {productDetail = {}} = props;
    // Expand children of productDetail
    return <LloydContainer {...props} {...productDetail} />
  } else if (pt.clustered()) {
    return <ClusteredItemList {...props} />
  } else if (pt.universal()) {
    return <UniversalItemList {...props} />
  } else {
    // STANDARD - non-clustered, non-universal.
    // https://lisa.aagarage.net/Tonneau-Covers/BakFlip-MX4-Tonneau-Cover
    return <StandardItemList {...props} />
  }
}

GroupProductsUi.propTypes = {
  // ["groupId","pageType","groupProperties",
  // "customerVehicle","itemsUrl","clusterIndex","itemApplications",
  // "products","productDetail","dispatch"]object
  groupId: PropTypes.number.isRequired,
  pageType: PropTypes.string.isRequired
}
