import React from 'react';
import {includes} from 'lodash';
import {getSaveCartConfig} from "../services/saveCartLogic";

const fallbackConfig = getSaveCartConfig('fallback');

const TOKEN = '#SAVINGS';

export default function SaveCartHeadline({
  config, saveCartStatus, shoppingCart, className, ...props
}) {

  let headline = buildHeadline({
    config, saveCartStatus, shoppingCart
  });

  // safeguard - if still has #SAVINGS, ignore it.
  if (!headline || includes(headline, TOKEN))
    headline = fallbackConfig.headline;

  return <div>
    <h2 className="mt-4 mb-8 text-2xl text-center">{headline}</h2>
  </div>
}

function buildHeadline({config, saveCartStatus, shoppingCart}) {
  if (!shoppingCart) return null;
  let flavour = saveCartStatus?.flavour;
  let headline = config.headline;

  if (!includes(headline, TOKEN))
    return headline;

  let savings = getSavings({flavour, shoppingCart});
  if (savings) {
    return headline.replace(TOKEN, savings.toString());
  }
}

function getSavings({flavour, shoppingCart}) {
  const {maxPublicSavings, maxPersonalSavings} = shoppingCart;

  if (flavour === 'personalSavings') {
    if (maxPersonalSavings && (+maxPersonalSavings > 0))
      return new Money(maxPersonalSavings);
  }

  if (maxPublicSavings && (+maxPublicSavings > 0))
    return new Money(maxPersonalSavings);

  // fail or zero
  return null;
}

/*
 PERSONAL:  submit=SEnd my offer
 let {cartTotals} = this.props;
 let {max_personal_savings} = cartTotals;
 return new Money(max_personal_savings);

 PUBLIC:
 let {shoppingCart = {}} = this.props;
 let {maxPublicSavings = 0} = shoppingCart;
 return new Money(maxPublicSavings);
 <p className="headline">
 Save
 {' '}
 <span className="money">${this.getSavings().toDollars()}</span>
 {' '}
 <span className="text-after">on the items in your cart.</span>
 </p>
 Activate discount by entering your email below.


 COUPON
 let {shoppingCart} = this.props;
 let {subtotalSavings = 0} = shoppingCart;

 return new Money(subtotalSavings);
 return (<div className="save-cart-prompt">
 <p className="headline">
 <span className="text-before"> Lock in savings of </span>
 {' '}
 <span className="money">${this.getSavings().toDollars()}</span>!
 </p>
 <p className="instructions">Enter your email below and we'll send you a link to your cart.</p>
 </div>);
 */
