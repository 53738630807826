import React from 'react';
import PropTypes from 'prop-types';

import ItemFactBox from "./ItemFactBox";
import ItemImageBox from "./ItemImageBox";
import ItemCartBox from "./ItemCartBox";
import {getClassList} from "../../../Utility/siteConfig";
import {camelizeKeysDeep} from "../../../ReduxStore/Helpers/transformObject";

export default function StandardItemDisplay({className, product, ...props}) {
  const {cartItem} = props;

  // FIXME: 2025-04 not camelized until we get here.
  product = camelizeKeysDeep(product);

  className = getClassList({
    key: 'StandardItemDisplay',
    append: className,
    fallback: "grid lg:grid-cols-3" // three equal columns
  });

  return <div className={className}>
    <ItemImageBox product={product} {...props} />
    <ItemFactBox  product={product} {...props} />
    <ItemCartBox  product={product} {...props} />
  </div>;

}

StandardItemDisplay.propTypes = {
  product: PropTypes.object.isRequired,
  application: PropTypes.object, // optional, for universal
  dispatch: PropTypes.func.isRequired,
  cartItem: PropTypes.object
}

StandardItemDisplay.defaultProps = {
  application: {}
}
