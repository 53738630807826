import React, {useState, useEffect} from 'react';
import {isEmpty} from 'lodash';
import GroupPageType from "../models/GroupPageType";
import VehicleChangeWatcher from "../components/GroupItems/VehicleChangeWatcher";
import VehicleChangeHandler from "../logic/VehicleChangeHandler";
import {getDispatch} from "../../Utility/ReduxHelper";

/**
 * 2025-02 extracted from GroupProductsContainer, a class that did far too much.
 *
 * @param productGroup
 * @param customerVehicle
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function GroupVehicleWatcher({
  productGroup = {}, customerVehicle, ...props
}) {

  const {id, pageType = ""} = productGroup;

  // what fields do we care about?  [ id, [fitment, [lloyd_unique]]]
  const [watchedFields, setWatchedFields] = useState(null);

  useEffect(() => {
    let pageType = new GroupPageType(productGroup.pageType);
    setWatchedFields(pageType.watchedFields());
  }, [productGroup]);

  const handleVehicleChange = (changes) => {
    const handler = new VehicleChangeHandler({
      changes, customerVehicle, pageType, groupId: id, location: globalThis.location
    });

    handler.call({dispatch: getDispatch()});
  }

  if (isEmpty(watchedFields)) return null;

  return <VehicleChangeWatcher
    watchedFields={watchedFields}
    customerVehicle={customerVehicle}
    onVehicleChanged={handleVehicleChange}/>

}
