import React from 'react';
import PropTypes from 'prop-types';
import CheckoutStep from "./CheckoutStep";

/**
 * 1, 2, 3 across the top.
 *
 * used by MULTIPLE APPS  - Cart and Checkout.
 */
export default function CheckoutSteps(props) {
  const {current = 0, callbacks = {}} = props;
  const {setStep} = callbacks;

  return (
    <div id="checkout-steps">
      <ul>
        <CheckoutStep setStep={setStep} step={1} current={current} caption="Cart"/>
        <CheckoutStep setStep={setStep} step={2} current={current} caption="Address"/>
        <CheckoutStep setStep={setStep} step={3} current={current} caption="Payment"/>

        {(current === 4) &&
          <CheckoutStep setStep={setStep} step={4} current={current} caption="Review & Submit"/>}
      </ul>
    </div>
  );
}

CheckoutSteps.propTypes = {
  current: PropTypes.number.isRequired,
  callbacks: PropTypes.shape({
    setStep: PropTypes.func.isRequired
  }).isRequired
};
