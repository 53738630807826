import React, {useEffect, useRef} from "react";
import clsx from "clsx";
import {getZIndex} from "../lib/zindex";
import CloseModalButton from "./CloseModalButton";
import {getClassList} from "../Utility/siteConfig";
import {isEmpty} from 'lodash';

/**
 * 2023-02 used by ErrorModal, HiddenContentContainer, DynamicCartModal...
 *
 * @param id
 * @param classNames
 * @param handleClose
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export default function Modal(
  {
    id,
    className,    // for modal-inner.
    styles = {},    // explicit styles for modal-inner
    handleClose,
    children,
    contentNode,
  }) {

  const modalRef = useRef(null);
  const contentRef = useRef(null);

  // When we have a ref, see if there is any content to inject into it.
  useEffect(() => {
    if (!modalRef?.current) return;
    if (!contentRef?.current) return;

    modalRef.current.focus();

    if (contentNode) {
      contentRef.current.append(contentNode);

      // call this function after it becomes visible.
      if (typeof (contentNode.onShowModal) == 'function') {
        contentNode.onShowModal({modalRef, contentRef})
      }
    }
  }, [modalRef?.current, contentRef?.current]);

  if (!handleClose) {
    console.error("Modal component called without handleClose");
    handleClose = (e) => {
      throw new Error("Modal component called without handleClose");
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode === 27) {
      handleClose();
    }
  }

  className ||= getClassList({
    key: 'Modal',
    // has legacy CSS in tailwind components.css
    fallback: ["top-2 p-2 bg-white text-gray-400"],
  })

  // prepend the essential classes.
  // "modal-inner", in tailwind/components, this controls the sizing only.
  className = clsx('modal-inner relative lg:max-w-auto', className);

  styles ||= {
    //maxHeight: "90%", minWidth: "20%", minHeight: "20%",
    //maxWidth: "90%"
  };

  return (
    <div ref={modalRef} id={id || ""}
      className="open-modal modal"
      style={{}}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      >
      <ModalFadeScreen handleClose={handleClose}>
        <div className={className} style={styles}
          onClick={(e) => e.stopPropagation()}>
          <CloseModalButton handleClose={handleClose}/>
          <div ref={contentRef}>{children}</div>
        </div>
      </ModalFadeScreen>
    </div>)
}

function ModalFadeScreen({children, handleClose, ...props}) {
  let zIndex = getZIndex("modalFadeScreen", 500_000);

  const className = [
    // NOTUSED: "modal-fade-screen",
    "fixed h-full w-full top-0 bottom-0 left-0 right-0 lg:pt-16",
    "bg-black bg-opacity-70 transition-opacity"
  ];

  const styles = {zIndex: zIndex};

  return <div style={styles} onClick={handleClose}
    className={clsx(className)}>{children}</div>
}
