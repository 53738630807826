import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Modal from "../Modals/Modal";
import LocalUrlMaker from "../lib/LocalUrlMaker";

export default function ModalCartPopup({
  handleClose,
  cartCode, cartUrl, itemCount,
  ...props
}) {

  cartUrl ||= LocalUrlMaker.prefixedPath("/cart");

  return <Modal handleClose={handleClose}>
    {cartCode ?
      <CartExists cartCode={cartCode} cartUrl={cartUrl} itemCount={itemCount}/>
      : <NoCart/>}
  </Modal>
}

function NoCart(props) {
  return <div className="font-body text-norm">
    <h2 className="text-xl">
      <span className="far fa-shopping-cart"/>
      {' '}
      Your Shopping Cart
    </h2>

    You have not yet added anything to your cart.
  </div>
}

function CartExists({cartCode, cartUrl, itemCount}) {
  return <div className="font-body text-norm text-center py-4">
    <h2 className="text-lg">
      <span className="far fa-shopping-cart"/>
      {' '}
      Your Shopping Cart
    </h2>

    <div className="text-norm">
      Your Connect ID is
    </div>

    <h3 className="text-xl py-4">{cartCode}</h3>

    <div>
      <a href={cartUrl}
        className="btn-primary">
        View Cart
      </a>
    </div>
  </div>
}
