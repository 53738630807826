/**
 * Given a list of items, sort them by the NumberForwardText value
 * of each item. The second parameter is for extracting the sortable text
 * from the item, a function like foo => foo.name; will be the identity function if blank.
 *
 * @param list
 * @param fn
 * @returns {*}
 */
export function sortListByNumberForwardText(list, fn) {
  fn ||= (it => it); // identity function if none provided

  // const map1 = list.map(fn);
  // console.log(`BEFORE SORT: ${list.length}: `, map1)

  let wrapped = list.map( item => {
    let key = fn(item);

    return { item, sortBy: splitNumberForwardText(key) }
  } );
  // console.dir(wrapped);

  let sortedWrappers = _.sortBy(wrapped,
    c => c.sortBy[0] || '',
    c => c.sortBy[1] || 0,
    c => c.sortBy[2] || '')

  // now extract the original object and return
  const sorted = sortedWrappers.map(x => x.item);

  // const map2 = sorted.map(fn);
  // console.log(`AFTER SORT: ${sorted.length}: `, map2)


  return sorted;
}

/**
 * Given option values like "1 3/8in Drive", split it into
 * a numeric part and a texty part, for sorting.
 *
 * @param text
 * @returns {(number|*)[]|*[]}
 */
export function splitNumberForwardText(text) {
  // ignore leading $/#, include leading -,
  // look for digits-dot-comma-slash-space, (space is for mixnums like "1 3/4")
  // then anything
  const m = text.match(/^([^0-9]*)(-?[0-9 .,\/\-]+)(.*)/);
  if (!m) {
    return [text, Infinity, ""];
  }

  const prefix = m[1]; // often empty
  const numberPart = m[2];
  const textPart = m[3];

  const value = textToNumber(numberPart);

  let out = [prefix, value, textPart];

  // console.log(`splitNumberForwardText: ${text} => ${JSON.stringify(out)}`)
  return out;
}

export function textToNumber(text) {
  text = (''+text).trim().replaceAll(',', '');

  // negative?
  if (text.charAt(0) === '-')
    return -1 * textToNumber(text.substring(1));

  // let m = text.match(/^([0-9]+)\s+([0-9]
  let words = text.split(/[\s\-]+/);
  return _.sum( words.map(word => evaluatePossibleFraction(word))) || 0;
}

export function evaluatePossibleFraction(text) {
  const words = text.split('/');

  let numerator = +words[0];
  if (isNaN(numerator))
    return 0;

  if (words.length !== 2) {
    return numerator;
  }

  let denom = +words[1];
  if (isNaN(denom) || (denom < 1))
    return 0;

  return numerator / denom;
}
