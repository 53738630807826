import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CheckoutSteps from "./CheckoutSteps";

import BraintreeTokenFetcher from "../../braintree/BraintreeTokenFetcher";
import {isEmptyCheckout} from "../../logic/checkout";
import CheckoutTotals from "../sidebar/CheckoutTotals";
import {camelizeKeysDeep} from "../../../ReduxStore/Helpers/transformObject";
import CatchErrors from "../../../Utility/CatchErrors";
import GoogleCheckoutReporter from "../../analytics/GoogleCheckoutReporter";
import {paymentTypes} from "../payment/paymentConstants";
import CheckoutForm from "./CheckoutForm";

export default function CheckoutUI(props) {
  const {
    checkout = {}, cartCode,
    callbacks, currentAgent,
    step = 2
  } = props;

  const loaded = !isEmptyCheckout(checkout);

  const cartItems = _.sortBy(checkout.cartItems || [],
      item => item.id)
    .map(item => camelizeKeysDeep(item));

  return <div>
    <div className="container mx-auto">
      <CheckoutSteps
        current={+step}
        cartCode={cartCode}
        callbacks={callbacks}/>
    </div>

    <div className="bg-white py-2 text-gray-800">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-3 lg:gap-2">
          <div className="checkout-main lg:col-span-2 mb-2">
            {loaded && <CatchErrors><CheckoutForm {...props}/></CatchErrors>}
          </div>
          <div className="checkout-sidebar py-2">
            {loaded && <CatchErrors>
              <CheckoutTotals
                enableAffirm={true}
                totals={checkout.totals}
                shippingAddress={checkout.shippingAddress}
                cartItems={cartItems} currentAgent={currentAgent}/>
            </CatchErrors>
            }
          </div>
        </div>

        <CatchErrors>
          {cartCode && <BraintreeTokenFetcher cartCode={cartCode}/>}

          {loaded && <GoogleCheckoutReporter checkout={checkout}/>}
        </CatchErrors>
      </div>
    </div>
  </div>
}

CheckoutUI.propTypes = {
  // braintree stuff { client, status, ...}
  // bt: PropTypes.object,
  cartCode: PropTypes.string.isRequired,
  //step: PropTypes.number,
  currentAgent: PropTypes.object, // or NULL
  paymentType: PropTypes.oneOf(paymentTypes),
}

