import React from 'react';
import {times} from 'lodash';
import {useDispatch} from "react-redux";
import {setGridPage} from "../../../ReduxStore/GroupGrid/groupGridSlice";
import clsx from "clsx";
import {scrollToGrid} from "../logic/gridLogic";
import {reportGridEvent} from "../../../Analytics/googleGridAdapter";
import {getClassList} from "../../../Utility/siteConfig";

/**
 * 2024-05 multi-grid aware
 * 2024-11 fully tailwind
 *
 * @param gridId
 * @param gridState
 * @param className
 * @param filteredItemCount
 * @returns {React.JSX.Element|null}
 * @constructor
 */
export default function GridPaginator({
  gridId,
  gridState = {},
  className,
  filteredItemCount
}) {

  const {pageSize} = gridState;
  const currentPage = gridState.currentPage ? +gridState.currentPage : 1;

  if (isNaN(pageSize) || (pageSize < 1)) return null;

  const pageCount = Math.ceil(filteredItemCount / pageSize);
  if (pageCount < 2)
    return null;

  // make you an array of [1,2,3..n]
  const pageList = times(pageCount, n => 1 + n)

  const captionClasses = getClassList({
    key: "GridPaginatorCaption",
    fallback: "mr-2"
  })
  return <span className={clsx(className, 'block md:inline-block text-center')}>
    <span className={captionClasses}>Page: </span>

    {pageList.map(n =>
      <PaginatorLink
        gridId={gridId}
        key={n}
        page={n}
        currentPage={currentPage}
        pageCount={pageCount}/>
    )}
  </span>
}

function PaginatorLink({gridId, page, currentPage, pageCount}) {
  const dispatch = useDispatch();
  const isCurrent = (page === currentPage);

  const handleClick = e => {
    // also handles URL update
    setGridPage({gridId, page, dispatch});

    scrollToGrid(gridId);

    reportGridEvent({
      eventType: 'product_grid_page',
      params: {page: page}
    });
  }


  let className= getClassList({
    key: "GridPaginatorLink",
    base: "inline-block",
    fallback: "m-1 px-2 py-1 text-center rounded-md border-2"
  });

  // now append more classes
  className = getClassList({
    key: isCurrent ? "GridPaginatorLink.active" : "GridPaginatorLink.inactive",
    base: className,
    fallback: isCurrent ? "" : ""
  })

  return <a className={className} onClick={handleClick}>{page}</a>

}
