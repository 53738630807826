import React from 'react';
import _ from 'lodash';

import GenericSmartyWidget from "./GenericSmartyWidget";

export default class SmartStreetWidget extends GenericSmartyWidget {

  // selected from autocomplete list.
  onSelectAddress = ({evt, addr}) => {
    // clear suggestion list, which will dismiss the popup.
    this.setState({suggestions: []});

    if (addr) {
      this.setAddressFromAutocomplete(addr);
    } else {
      console.warn("SmartStreetWidget: no address")
    }

    // entry count, used to identify need for apartment/unit designators
    // if (addr.entries && addr.entries > 0) {}
  }

  setAddressFromAutocomplete(suggestion) {
    let {address = {}} = this.props;

    if (_.isEmpty(suggestion))
      return;

    // Update address in Redux
    this.setState({value: suggestion.streetLine, suggestions: []},
      () => {
        this.sendUpdateToField('street', suggestion.streetLine);

        this.sendUpdateToField('city', suggestion.city);
        this.sendUpdateToField('state_code', suggestion.state);
        this.sendUpdateToField('zip', suggestion.zipcode);

        if (suggestion.secondary) {
          // overwrite unit number only if user hasn't already typed something larger
          let oldval = '' + address.street2;
          if (oldval.length < suggestion.secondary.length) {
            this.sendUpdateToField('street2', suggestion.secondary);
          }
        }

        if (+(suggestion.entries) > 1) {
          // Send full address as hidden field _autocomplete_street to redux.
          // SmartApartmentWidget will watch for this and do some Smarty lookups of its own
          //let composed = `${addr.streetLine} (${addr.entries})`;
          // pseudo field name
          this.sendUpdateToField('_autocomplete_addr', Object.assign({}, suggestion));
        } else {
          this.sendUpdateToField('_autocomplete_addr', null);
        }
      }
    )
  }

}
