import React, {useMemo, useState} from 'react';

import ActiveVehicleDisplayNOL from "./ActiveVehicleDisplayNOL";
import ActiveVehicleDisplay from "./ActiveVehicleDisplay";
import NoVehicleDisplayNOL from "./NoVehicleDisplayNOL";

/**
 * Displayed in the Top Nav bar.
 */
export default function ShowCustomerVehicleUi({...props}) {
  const {themeName, customerVehicle} = props;
  const nol = themeName === 'napaonline';

  if (customerVehicle?.id) {
    return nol ? <ActiveVehicleDisplayNOL {...props} /> :
      <ActiveVehicleDisplay {...props} />
  } else {
    return nol ? <NoVehicleDisplayNOL  {...props} /> :
      <NoVehicleDisplay  {...props} />
  }
}

function NoVehicleDisplay({}) {
  // LEGACY VERSION - semantic class names
  return <div className="stored-vehicle-inner"/>
}


