import React from "react";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import {affirmAvailableForCart} from "../../../lib/affirmHelpers";
import {reportStartCheckout} from "../utility/startCheckout";
import {getClassList} from "../../../Utility/siteConfig";
import {CHECKOUT_BUTTON_CLASSES} from "./CheckoutButton";

/**
 * Note we have two similarly named components in different SPA's.
 *
 * This one is on the CART page, and it simply sends the user into
 * checkout with Affirm preselected - it doesn't actually talk to Affirm.
 */
export default function StartAffirmButton(props) {
  const { shoppingCart={}, checkoutConfig = {}} = props;

  //alert(JSON.stringify(checkoutConfig, null, 2));

  // if (!affirmAvailableForCart(shoppingCart, checkoutConfig?.affirm))
  //   return null;

  let checkoutPath = LocalUrlMaker.jsonPath(
    `/new_checkout/${shoppingCart.cartCode}?pt=affirm`);

  const handleClick = (evt) => {
    reportStartCheckout({
      shoppingCart, checkoutType: "affirm"
    });
  };

  const common = getClassList({
    key: 'CheckoutButton.common',
    fallback: CHECKOUT_BUTTON_CLASSES
  })

  const className = getClassList({
    key: "CheckoutButton.main",
    base: [common]
  })

  return <a
    className={className} href={checkoutPath} onClick={handleClick}>
        <img className="block mx-auto"
          src="/img/badges/affirm_200_black.png" style={{height:'28px'}}
          alt="Checkout with Affirm"  /></a>
}
