import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LocalUrlMaker from "../../../lib/LocalUrlMaker";

export default function VendorskuBlock({
  item, path, vendorsku, ...props
}) {

  vendorsku ||= item.vendorsku;
  path ||= LocalUrlMaker.prefixedPath(item.path);

  return (
    <div className="text-center">
      <div className="text-xs">Part#</div>
      <a className="font-bold block"
        href={path}>{vendorsku}</a>
    </div>
  );
}
