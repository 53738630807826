import React from 'react';

import {compareItemsPath} from "../../../lib/urlPaths";
import {getClassList} from "../../../Utility/siteConfig";

/**
 *
 * 2024-11 modernised even more
 * 2023-11 modernised
 *
 * @param id
 * @param handleSelect
 * @param compareList
 * @returns {JSX.Element}
 * @constructor
 */
export default function CompareNow({id, handleSelect, compareList}) {
  const handleClick = e => {
    if (compareList.length < 2) {
      alert("Please select at least two items.");
      return;
    }

    window.location = compareItemsPath(compareList);
  }

  let wrapper = getClassList({
    key: 'CompareItems',
    fallback: "mt-2 relative",
    base: "hiddenlg:block"
  })

  let className = getClassList({
    key: 'CompareNow',
    base: 'cursor-pointer inline-block',
    fallback: 'rounded-xl bg-brand-primary pr-2 py-1 hover:shadow-lg'
  })

  let inputClasses = getClassList({
    key: 'CompareItems.checkbox',
    fallback: 'align-middle mx-2'
  });

  let linkClasses = getClassList({
    key: 'CompareNow.inner',
    base: 'align-middle',
    fallback: '"align-middle text-anti-primary text-sm font-normal m-1 pb-1 leading-tight'
  });

  console.log("CompareNow: " + className);

  // Do NOT use a label here, as it will want to proxy clicks to the checkbox
  // use an A, because it links to something outside this page
  return <div className={wrapper}>
    <span className={className}>
      <input
        className={inputClasses}
        id={id} type="checkbox" value="1"
        onChange={handleSelect} checked={true}
      />

      <a className={linkClasses}
        onClick={handleClick}> Compare Now</a>
    </span>
  </div>
}

