import React from 'react';
import PropTypes from 'prop-types';
import AddToCartForm from "./AddToCartForm";

/*
 * Inside the .cluster-cart column we have the
 * cluster-cart-inner, which wraps a
 * CartPriceDisplay
 * AddToCartCheckbox or AddToCartButton
 * CartNotesDisplay
 *
 * props are items=array, dispatch, cluster, clusterState
 *
 */
export default function ClusterAddToCart({cluster, clusterState = {}, products, shoppingCart, cartItem}) {
  let {productGroup} = cluster;

  let {selectedItem} = clusterState;

  let enabled = !!selectedItem;

  return <div className="cluster-cart">
    <AddToCartForm
      enabled={enabled}
      index={cluster.index || (500 + Math.round(10000 * Math.random()))}
      products={products}
      clusterState={clusterState}
      shoppingCart={shoppingCart}
      cartItem={cartItem}
      appliedItem={selectedItem}

      application={selectedItem?.application}
      product={selectedItem?.product}
      productGroup={productGroup}
      matchingProducts={clusterState.matchingProducts}
    />
  </div>
}

ClusterAddToCart.propTypes = {
  itemApplications: PropTypes.array,
  products: PropTypes.object.isRequired,
  // productGroup: PropTypes.object.isRequired,
  // dispatch,
  pageType: PropTypes.string, // "CMf"
  cluster: PropTypes.object,
  clusterState: PropTypes.object,
  itemCache: PropTypes.object, // AppliedItemCache
  cartItem: PropTypes.object
}
