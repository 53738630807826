import React from 'react';
import clsx from "clsx";

export default function ChangeAddressLink({handleChangeAddress, paymentType}) {

  const handlePaypalClick = (evt) => {
    evt.preventDefault();

    let msg = "Due to our agreements with PayPal, " +
      "we can only ship to the address on your PayPal account. " +
      "This will override any address entered on the previous screen. " +
      "To ship elsewhere, choose a different payment method.";

    alert(msg);
  }

  const className= clsx('pointer-cursor underline block lg:inline-block');

  if (paymentType === 'paypal') {
    return <a className={className}
      onClick={handlePaypalClick}>change</a>
  } else if (handleChangeAddress) {
    return <a className={className}
      onClick={handleChangeAddress}>change</a>
  }

  return null;
}
