import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _, {camelCase} from 'lodash';
import {useDispatch} from "react-redux";

import {addToCart} from "../../ReduxStore/ShoppingCart/cartSlice";
import {getClassList} from "../../Utility/siteConfig";
import {isOutOfStock} from "../logic/products";

export default function DynamicAddButton(props) {
  const {product, application, caption = "Add to Cart", quantity} = props;

  const dispatch = useDispatch();

  const outOfStock = isOutOfStock(product);

  let disabled = props.disabled || _.isEmpty(product);

  const handleClick = (evt) => {
    evt.preventDefault();

    if (outOfStock) {
      alert("Sorry, this product is out of stock.");
    } else if (disabled) {
      alert("Please select all options before adding to cart.");
    } else {
      dispatch(addToCart({product, application, quantity}));
    }
  }

  let className = getClassList({
    key: (outOfStock || disabled) ? 'AddToCartButton.disabled' : 'AddToCartButton',
    fallback: 'btn',  // FIXME legacy css btn
    append: [props.className]
  });

  return (
    <button type="button" className={className}
      onClick={handleClick}>
      {outOfStock ? "Out of Stock" : caption}
    </button>
  );
}

DynamicAddButton.propTypes = {
  product: PropTypes.object,
  application: PropTypes.object, // optional, for universal
  disabled: PropTypes.bool
};


