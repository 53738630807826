import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import clsx from "clsx";

function getErrors(props) {
  let {error} = props;

  error = error ? error.toString() : "Unknown error";
  return error.split("\n");
}

/**
 * An error display that you can close
 */
export default function DismissibleError(props) {
  const {dismissError, id, buttonText} = props;
  const errors = getErrors(props);

  const className = clsx([
    "dismissible-error",
    "bg-red-600 text-white font-bold p-4 my-4 rounded"
  ])

  return <div id={id}
    className={className}>
    <button type="button"
      className="float-right m-4 text-black bg-gray-200 py-1 px-8 hover:opacity-75"
      onClick={dismissError}>{buttonText}</button>

    <div className="error-inner">
      {errors.map((err, i) =>
        <div key={i} className="message">{err}</div>)}
    </div>
  </div>;

}

DismissibleError.defaultProps = {
  id: "dismissible-error",
  error: "Unknown error",
  buttonText: "OK"
}
