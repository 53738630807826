import React from 'react';
import clsx from "clsx";
import CatchErrors from "../../../../Utility/CatchErrors";
import ItemClusterMain from "./ItemClusterMain";
import AddToolProduct from "../../StandardItems/AddToolProduct";
import ItemClusterSelectorsList from "../../StandardItems/ItemClusterSelectorsList";

export default function ToolItemClusterUi({...props}) {
  const {cluster, clusterState, products, clusterCount=1, itemCache, cartItem, sectionConfig} = props;

  const className = clsx('my-4 border-gray-200 border-b');
  const { selectedItem } = clusterState;

  const product = selectedItem?.product;

  // cluster.id like front_row_neosupreme_seat_covers
  return <div className={className} id={"item-cluster-" + cluster.id}>
    <h3 className="font-bold text-sm py-2">{cluster.headline}</h3>
    <CatchErrors>
      <ItemClusterSelectorsList
        itemCache={itemCache}
        cluster={cluster}
        clusterState={clusterState}
      />
    </CatchErrors>
    <CatchErrors>
      <AddToolProduct product={product} cartItem={cartItem}/>
    </CatchErrors>
  </div>

}
