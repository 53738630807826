import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import CompareItemsTable from "./CompareItemsTable";
import RestoreHiddenItems from "./RestoreHiddenItems";
import {selectHiddenItems, selectValidItems} from "./logic";

export default function CompareItemsContainer(props) {
  const {allItems} = props;

  const [items, setItems] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);

  useEffect(() => {
    // select items valid on this site (having a URL)
    setItems(selectValidItems(props.allItems))
  }, [props.allItems])

  const setVisibleItems = (list) => {
    if (_.isEmpty(list))
      list = selectValidItems(allItems);

    setItems(list);
    setHiddenItems(selectHiddenItems(allItems, list))
  }

  const hideItem = (id) => {
    setVisibleItems(items.filter((item) => item.id != id));
  }

  const restoreAllItems = () => {
    setItems(selectValidItems(props.allItems));
    setHiddenItems([]);
  }

  const callbacks = {
    hideItem, restoreAllItems
  }

  let childProps = Object.assign(
    {},
    props,
    {callbacks: callbacks, items, hiddenItems},
  );

  // skip first render
  if (_.isEmpty(items))
    return null;

  return <section className="" id="compare-items-container">
    <CompareItemsTable {...childProps}  />
    <RestoreHiddenItems {...childProps} />
  </section>

}

