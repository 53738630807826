import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {partition, isEmpty} from 'lodash';
import HamburgerMenuSection from "./HamburgerMenuSection";
import {useDispatch} from "react-redux";
import navigationSlice, {loadHamburgerContent} from "../ReduxStore/navigationSlice";
import {getClassList} from "../Utility/siteConfig";
import HamburgerBackLink from "./HamburgerBackLink";
import HamburgerSearch from "./HamburgerSearch";

export default function HamburgerMenuPanel({
  items,
  offset = 0,
  panelId, // null for root
  menuWidth = 360,
  config = {},
  ...props
}) {
  let [buckets, links] = partition(items, i => i.flavour?.match('bucket'));

  if (isEmpty(buckets) && !isEmpty(links)) {
    buckets = [makeBucket(links)];
  }

  const handleBackClick = (e) => {
    e.preventDefault();
    const dispatch = getReduxStore().dispatch
    dispatch(navigationSlice.actions.navigateToHamburgerPanel({id: -1}));
  }

  offset = +offset;
  menuWidth = +menuWidth;

  const styles = {
    left: (offset > 0) ? `-${offset * menuWidth}px` : 0,
    right: (offset > 0) ? `${offset * menuWidth}px` : 0,
    //width: '360px'
    // zIndex: 999
  }

  const className = getClassList({
    key: 'HamburgerMenuPanel',
    base: "absolute top-0 transition-all duration-1000",
    fallback: 'bg-paper text-pen',
  });

  // console.log(`panel ${panelId} offset=${offset} styles=${JSON.stringify(styles, null, 2)}`)
  return (
    <div id={`hamburger-panel-${panelId ? panelId : 0}`}
      className={className}
      style={styles}>

      {(config.siteSearch) && (panelId === 0) && <HamburgerSearch config={config}/>}

      {(panelId > 0) && <HamburgerBackLink handleBackClick={handleBackClick}/>}

      {buckets.map((bucket, index) => <HamburgerMenuSection
          key={bucket.name}
          bucket={bucket}
          config={config}
        />
      )}
    </div>
  );

  //<div className="debug">panelId= {panelId} offset={offset}</div>

}

function makeBucket(links) {
  return {
    id: 99999,
    name: 'All Categories',
    flavour: 'hamburger-menu-bucket',
    link: null,
    childrenCount: links.length,
    children: links
  }
}
