import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {getClassList} from "../../../../Utility/siteConfig";

/**
 * The heart of a ClusterLevel - the <select> widget.
 *
 */
export default function ItemClusterSelectWidget({
  id, value, name, cluster, choices, enabled = true, onSelect
}) {

  // Vehicle Detail, Color, Size, etc.
  const label = (cluster.labels ? cluster.labels[name] : null) || _.capitalize(name);

  const classes = getClassList({
    key: "ItemClusterSelectWidget",
    base: "relative m-0 -ml-1"     // m-0 needed because aag/nola legacy css adds 24px margin bottom
  });

  return <select id={id}
    className={classes}
    name={name}
    value={value || ""}
    onChange={onSelect}
    disabled={!enabled}>
    <option value="">Select {label}</option>
    {choices.map(choice => <option
      key={choice.v} value={choice.v}>{choice.l || choice.v}</option>
    )}
  </select>
}

ItemClusterSelectWidget.propTypes = {
  name: PropTypes.string, // fitment, color, application_id
  cluster: PropTypes.object, value: PropTypes.string, // long string like "Regular Cab, with 1st Row Bench..."
  choices: PropTypes.array, enable: PropTypes.bool, onSelect: PropTypes.func
}
