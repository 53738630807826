import _ from "lodash";
import React from "react";
import ChangeAddressLink from "./ChangeAddressLink";

export default function ValidAddressDisplay(
  {
    address,
    handleChangeAddress,
    usage,
    impediments,
    paymentType,
    ...props
  }) {
  if (!address) return null;

  let countryCode = address.countryCode || 'US';

  const usa = (countryCode === 'US');

  return <div className="address">
    <div className="full-name">
      {address.firstName} {address.lastName}
    </div>
    {address.company &&
      <div className="company">{address.company} </div>}

    <div>
      <span className="street">{address.street} </span>

      {address.street2 && <span className="street2">{address.street2} </span>}

      <span className="city-state">
        {address.city}, {address.stateCode} {address.zip}
      </span>

      {' '}
      {!usa && <span className="country">
        {address.countryName || countryCode}</span>}
      {' '}

      <ChangeAddressLink
        paymentType={paymentType}
        handleChangeAddress={handleChangeAddress}/>
    </div>

    {(usage === "shipping") && <ImpedimentsList impediments={impediments}/>}
  </div>
}

function ImpedimentsList({impediments}) {
  if (_.isEmpty(impediments))
    return null;

  return (<div className="text-red-800">
    {impediments.map((text) => <div key={text}>
      <span className="fa fa-exclamation-triangle"/>{' ' + text + ' '}
    </div>)}
  </div>);
}
