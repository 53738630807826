import React from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
import {getClassList} from "../../../Utility/siteConfig";

/**
 * Continue button (and its div wrapper) at the bottom of each of the
 * check out panels. Any child components will be rendered inside
 * the button, so use that technique for affirm/paypal logos.
 *
 */
export default function ContinueButton({
  onSubmit, caption, disabled=false, children, id,
  tabIndex=0,
  className='btn',
  wrapperClasses
}) {

  // classes for button
  className = getClassList({
    key: "Checkout.ContinueButton",
    fallback: 'w-full lg:w-2/3 text-xl py-2 bg-brand-secondary text-anti-secondary hover:opacity-75',
    append: className
  })

  // classes for div
  wrapperClasses = clsx(
    "continue-button-wrapper my-4 text-center",
    (disabled ? 'submit-disabled' : 'submit-enabled'),
    wrapperClasses
  );


  const handleClick = (evt) => {
    if (!disabled) {
      onSubmit(evt);
    }
  }

  return (
    <div id={id || null} className={wrapperClasses}>
      <button type="button" className={className}
        tabIndex={tabIndex} onClick={handleClick}
        disabled={disabled}>
        {children}
        {caption}
      </button>
    </div>
  );

}

ContinueButton.propTypes = {
  id: PropTypes.string,
  tabIndex: PropTypes.number,
  caption: PropTypes.string,
  classes: PropTypes.array,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  htmlCaption: PropTypes.string
};

