import {getReduxStore} from "../../ReduxStore/store";
import GroupPageContainer from "./GroupPageContainer";
import {connect, Provider} from 'react-redux';

// 2025-02 moved old component by this name to GroupPageContainer
// and made this a Redux wrapper instead.
function mapStateToProps(state) {
  let {vehicleSlice = {}, groupItemsSlice = {}, cart} = state;

  return Object.assign(
    _.pick(cart, 'shoppingCart', 'dynamicCart'),
    vehicleSlice, groupItemsSlice);
}

const GroupPageWrapper = connect(mapStateToProps)(GroupPageContainer);

export default function GroupPageApp(props) {
  return <Provider store={getReduxStore()}>
    <GroupPageWrapper {...props} />
  </Provider>
}
