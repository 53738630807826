import React, {useEffect, useRef, useState} from "react";
import createLightGallery from "../../../Common/services/createLightGallery";

export default function ItemImageBox({product, ...props}) {
  const ref = useRef(null);
  const [gallery, setGallery] = useState(null);
  const [imagePath, setImagePath] = useState(null);

  function destroyGallery() {
    if (gallery?.destroy) {
      gallery.destroy();
    }
  }

  // calculate new image paths when product changes; this will also trigger the next effect.
  // (trying to do it all in one effect means the thing runs twice)
  useEffect(() => {
    if (!product || !product.images)
      return;

    const {small = [], mid = [], large = []} = (product.images || {});
    const pic = (small && small[0]) || (mid && mid[0]);
    setImagePath(pic?.path);
  }, [product?.id]);

  useEffect(() => {
    if (!ref?.current || !imagePath)
      return;

    destroyGallery();

    let g = createLightGallery(ref.current);
    setGallery(g);

    return destroyGallery();
  }, [ref?.current, imagePath])

  const bigPath = imagePath && imagePath.replace("/sm/", "/lg/").replace("/md/", "/lg/");

  return <div className="" ref={ref}>
    {imagePath ? <a className="pic" href={bigPath}><img
      alt={product.name} src={imagePath}/></a> : <div>&nbsp;</div>}
  </div>
}
