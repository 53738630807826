import _ from "lodash";
import LevelList from "../../models/LevelList";
import ProductIndexFilter from "./ProductIndexFilter";
import {sortListByNumberForwardText, splitNumberForwardText} from "../../Utility/numbers";

export default class ClusterLevels {
  constructor({level, cluster, state}) {
    this.level = level;         // a name like "color"
    this.cluster = cluster;
    this.state = state || {};

    this.levelList = new LevelList(cluster.levels);

    this.productIndexFilter = new ProductIndexFilter(cluster.productIndex);
  }

  getId() {
    return `cluster-${this.cluster.id}-${this.level}`
  }

  getClasses() {
    let classes = ['selector-level', 'selector-enabled']

    classes.push("selector-" + this.getPosition());

    if (this.getValue()) {
      classes.push("selector-complete");
    } else {
      classes.push("selector-ready");
    }
    return classes.join(' ');
  }

  getIcon() {
    if (this.getValue()) {
      return 'fa fa-check-circle';
    } else {
      return 'fa fa-arrow-right';
    }
  }

  getPosition() {
    return this.levelList.levelPosition(this.level);
  }

  getValue() {
    let {selections} = this.state;
    return selections[this.level];
  }

  upstreamSelections(includeCurrent = false) {
    let levels = this.levelList.levelsBefore(this.level);
    if (includeCurrent)
      levels.push(this.level);

    let selections = _.pickBy(this.state.selections,
      (v, k) => _.includes(levels, k));

    return selections;
  }

  // ProductIndex, filtered by upstream selections
  // These are the products available to this level,
  // before user has made selection - based solely on what
  // has been selected above
  availableProductIndex() {
    return (this.productIndexFilter
      .filterProducts(this.upstreamSelections()));
  }

  matchingProductIndex(selections) {
    selections = selections || this.upstreamSelections(true);
    // alert(JSON.stringify(selections));

    return (this.productIndexFilter
      .filterProducts(selections));
  }

  // return [Array<Object>]
  // where object is {v: foo, l: bar}
  getChoices() {
    let prods = _.values(this.availableProductIndex());

    let choices = _(prods)
      .flatMap((p) => p[this.level] || [])
      .uniqBy(c => c.v)
      .value();

    return sortListByNumberForwardText(choices, c => c.l || c.v);
  }
}



export function levelIsEnabled({levelName, levels, selections}) {
  let pos = _.indexOf(levels, levelName);

  // 0: first selector always enabled
  // undefined or -1: errors, but just return true
  // >= 1: dependent on previous ones being selected.
  if (!pos || (pos < 1))
    return true;

  // are all previous levels selected?
  for (let i = 0; i < pos; i++) {
    let v = selections[levels[i]];
    if (!v)
      return false;
  }

  // happy, all previous levels are selected.
  return true;
}
