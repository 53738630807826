import React from "react";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import DynamicAddButton from "../DynamicAddButton";
import ShoppingCart from "../../../ShoppingCart/models/ShoppingCart";
import CheckoutLink from "./CheckoutLink";


export default function ItemCartBox({
  sectionConfig={},
  application, product, cartItem, shoppingCart,
  ...props
}) {
  const { regularPriceCents } = product;

  let price = regularPriceCents && new Money(regularPriceCents);

  return <div className="cart-box text-left lg:text-right">
    {price && <div className="text-2xl text-brand-primary">{price.toString()}</div> }

    <DynamicAddButton
      className="text-2xl w-full bg-brand-primary text-anti-primary py-2 cursor-pointer hover:opacity-75"
      application={application}
      product={product}
      cartItem={cartItem}
    />

    {cartItem && <CheckoutLink shoppingCart={shoppingCart} />}
  </div>
}
