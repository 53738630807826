import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LocalUrlMaker from "../lib/LocalUrlMaker";
import ActiveMenuPanel from "./ActiveMenuPanel";
import {handleMouseMenuEvent, navigationActions} from "../ReduxStore/navigationSlice";

/*
 * The actual dropdown menus, revealed when MenuBarItem's are activated.
 */
export default function DropdownMenu({
  dropdownState = {}, dropdownContent={}, dispatch, overlay, ...props
}) {
  const {activeMenuId, open = false} = dropdownState;

  let content = activeMenuId ? dropdownContent[activeMenuId] : null;

  const showOverlay = useCallback((active) => {
    dispatch(navigationActions.showOverlay({
      // show/hide overlay
      name: 'dropdownMenu',
      active: active, // true/false
      mode: 'content'
    }));
  }, [dispatch]);

  useEffect(() => {
    showOverlay(open);

    return () => showOverlay(false);
  }, [open]);

  const handleHover = (evt) => {
    dispatch(handleMouseMenuEvent({
      menuId: activeMenuId, outbound: false
    }))
  };

  const handleBlur =(evt) => {
    dispatch(handleMouseMenuEvent({
      menuId: activeMenuId, outbound: true
    }))
  };

  if (!open) return null;

  // This wrapper serves as an anchor for the absolutely-positioned content.
  return <div
    onMouseEnter={handleHover} onMouseLeave={handleBlur}
    className="relative bg-white overflow-visible w-full">
    { content && <ActiveMenuPanel
      html={content}
      dropdownState={dropdownState}
    />}
  </div>

}
