import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import GridItemTitle from "./GridItemTitle";
import RatingWidget from "./RatingWidget";
import GridItemDesc from "./GridItemDesc";
import GridItemCompare from "./GridItemCompare";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import Money from "../../../lib/Money";

export default function ToolGridItemInfoBlock({
  item, handleClick, gridState, gridId, path,
  ...props}) {

  path ||= LocalUrlMaker.prefixedPath(item.path);

  let usd = new Money(item.price);
  let usd2 = item.priceHigh ? new Money(item.priceHigh) : usd;
  let { count } = item;

  return <div className="p-2">
    <GridItemTitle item={item} path={path}
      handleClick={handleClick}/>

    <RatingWidget item={item}/>

    {(item.price == item.priceHigh) ?
      <div className="text-lg my-2">
        <span className="font-bold">${usd.toString('')}</span>
      </div> :
      <div className="text-lg my-2">
        <span className="font-bold">${usd.toString('')}</span>
        {' '} &mdash; {' '}
        <span className="font-bold">{usd2.toString('')}</span>
      </div>}

    {gridState.showCompare && !item.vendorsku && false && <GridItemCompare
      gridId={gridId}
      item={item}
      gridState={gridState}
    />}
  </div>
}
