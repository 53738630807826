import React from 'react';
import PropTypes from 'prop-types';
import LocalUrlMaker from "../../lib/LocalUrlMaker";
import CartButtons from "./CartButtons";
import CartItemVehicle from "../../ShoppingCart/components/cart_contents/CartItemVehicle";
import {getClassList} from "../../Utility/siteConfig";

// 2023-11 added VehicleDisplay (from cart) - display a alert when vehicle missing
// 2023-04 modernized, camelized props
export default function NewItemDisplay({
  className,
  newItem, shoppingCart, handleClose, customerVehicle}) {

  if (!newItem)
    return null;

  //console.log("NEW ITEM", newItem);

  let price = new Money(newItem.effectiveUnitPrice || 0);

  let {smallImage} = newItem;

  // removed for carlyle<div className="shipping">Free shipping</div>
  className = getClassList({
    key: "DynamicCartPopup.NewItemDisplay",
    base: [className, "new-item-display flex flex-col lg:flex-row"],
    fallback: ['p-2']
  });

  return (
    <div className={className}>
      {smallImage && <div className="new-item-pic">
        <img className="inline-block my-4"
          src={smallImage} alt={newItem.name || "New Item"}/>
      </div>}

      {newItem && <div className="new-item-details">
        <div className="text-lg">
          {newItem.brandName} {newItem.name}</div>

        <div className="text-brand-dark">{price.toString()}</div>

        <CartButtons handleClose={handleClose} cartCode={shoppingCart.cartCode}/>

        <CartItemVehicle item={newItem} quiet={true}/>

        <VehicleLink vehicle={customerVehicle}/>
      </div>}

      <div className="clear-me"></div>
    </div>
  );
}

NewItemDisplay.propTypes = {
  newItem: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired
};

// Show a "Shop more Ford F150 accessories" link.
// vehicleUrl may be missing as we don't have pages for obscure vehicles
function VehicleLink({vehicle}) {
  if (!vehicle || !vehicle.vehicleUrl)
    return null;

  return <div className="vehicle-link">
    <a href={LocalUrlMaker.prefixedPath(vehicle.vehicleUrl)}>
      Shop more {' '}
      {vehicle.shortMakeName || vehicle.vehicleMakeName} {' '}
      {vehicle.shortModelName || vehicle.vehicleModelName}
      {' '}
      accessories</a>
  </div>
}
