import React from 'react';
import PropTypes from 'prop-types';

import GenericAddressWidget from "./GenericAddressWidget";

export default class SelectStateWidget extends GenericAddressWidget {
  /**
   * Filter the states given in the statesList property, showing only
   * those of the selected country. If this is a shipping address
   * filter it to only locations we ship to.
   *
   */
  selectStates() {
    const {stateList, address={}, addressType} = this.props;

    let { countryCode } = this.props;
    if (!countryCode)
      countryCode = address.country_code || 'US';

    // For shipping, do not allow overseas (Guam, PR, ...) or APO/FPO
    let list = stateList.filter(s => s.country_code === countryCode);
    if (addressType != 'billing') {
      list = list.filter((s) => (s.section != 'overseas') && (s.section != 'military'));
    }
    return list;
  }

  onChange = (evt) => {
    let { value } = evt.target;

    const { name } = this.props;
    console.log(name + " change: " + value);

    this.setState({value: value}, () => this.sendUpdate());
  }

  renderInput(helper) {
    const {label, placeholder, tabIndex} = this.props;
    const {value} = this.state;
    const states = this.selectStates();

    if (!helper)
      helper = this.getHelper();

    let autoComplete = helper.getAutoComplete();

    return <select
      className="w-full border border-gray-400 bg-white"
      value={value}
      id={helper.getId()}
      name={helper.getName()}
      tabIndex={tabIndex}
      autoComplete={autoComplete}
      onFocus={this.onFocus}
      onBlur={this.onBlur}
      onChange={this.onChange}>
      <option value="">{placeholder || label || 'select'}</option>
      {states.map((s) => <option key={s.state_code} value={s.state_code}>{s.name}</option>)}
    </select>
  }

}

SelectStateWidget.propTypes = Object.assign({}, GenericAddressWidget.propTypes, {
  stateList: PropTypes.array.isRequired,
  countryCode: PropTypes.string
});

SelectStateWidget.defaultProps = GenericAddressWidget.defaultProps || {};

