import {reportStartCheckout} from "../utility/startCheckout";
import {getClassList} from "../../../Utility/siteConfig";
import React from "react";

export const CHECKOUT_BUTTON_CLASSES = [
  'block w-full py-2 mt-4',
  'text-brand-dark bg-gray-200 uppercase text-xl',
  'border border-gray-300 cursor-pointer hover:opacity-60',
  'transition transition-opacity duration-300'
];

export default function CheckoutButton({shoppingCart, cartCode, checkoutPath}) {
  checkoutPath || (checkoutPath = getCheckoutPath(cartCode, shoppingCart));

  const handleClick = (evt) => {
    reportStartCheckout({
      shoppingCart, checkoutType: 'credit_card'
    });
  };

  const common = getClassList({
    key: 'CheckoutButton.common',
    fallback: CHECKOUT_BUTTON_CLASSES
  })

  const className = getClassList({
    key: "CheckoutButton.main",
    base: [common, 'bg-venom !border-0 font-bold']
  })

  // FIXME CARLYLE THEME
  const styles = {
    boxShadow: '0px 1px 0px darkgreen',
    borderBottom: '2px solid darkgreen'
  };

  return <a id="checkout-button"
    style={styles}
    className={className}
    href={checkoutPath} onClick={handleClick}>
    <span className="fa fa-lock mr-2"/>
    {" "}
    Secure Checkout
  </a>
}
