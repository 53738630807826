import _ from 'lodash';
import React from 'react';

import StandardItemDisplay from './StandardItemDisplay';

/**
 * A list of non-clustered, non-universal items. Each one gets an
 * add-to-cart.
 *
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function StandardItemList(props) {
    const {itemApplications = [], products={}, dispatch, shoppingCart={}} = props;

    const cartIndex = _.keyBy( (shoppingCart && shoppingCart.cartItems), 'productId');

    if (_.isEmpty(itemApplications))
      return null;


    return (
      <div className="standard-item-list">
        {itemApplications.map( (app) => {
          const productId = app.productId || app.product_id;

          return <StandardItemDisplay key={app.id}
            cartItem={cartIndex[productId] || null}
            dispatch={dispatch}
            shoppingCart={shoppingCart}
            product={products[productId]}
            application={app} />
        })}
      </div>
    );
}

