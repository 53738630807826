import React from 'react';
import ProductPicker from "./ProductPicker";
import SingleItemImageDisplay from "./SingleItemImageDisplay";

export default function ItemClusterImages(props) {
  const productPicker = new ProductPicker(props);
  const product = productPicker.getProductForImage();

  return <div className="text-center min-h-4">
    {product && <SingleItemImageDisplay product={product}/>}
  </div>
}

