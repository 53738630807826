import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import ClusterLevels from "../../../logic/ClusterLevels";
import ItemClusterSelectWidget from "./ItemClusterSelectWidget";
import SelectionHandler from "../../../logic/SelectonHandler";
import LevelList from "../../../../models/LevelList";
import {getDispatch} from "../../../../Utility/ReduxHelper";
import groupItemsSlice from "../../../../ReduxStore/ProductGroupPage/groupItemsSlice";

/**
 * ItemClusterLevel - one of the Select widgets within an
 * Item Cluster.
 *
 * props: ["name","cluster","clusterState"]
 */
export default function ItemClusterLevel(props) {
  let {name, value = "", enabled, cluster, clusterState, position = 0} = props;
  let {levelOptions} = (clusterState || {});

  // get a ref to my top level element.
  const ref = useRef();

  // For all but the first one in each set, focus on it when it first appears,
  // because this probably means the user just selected the level above.
  useEffect(() => {
    const node = ref?.current;
    if (node && (position > 0)) {
      focusOnMe(node);
    }
  }, [ref?.current]);

  const helper = new ClusterLevels({
    level: name, state: clusterState, cluster: cluster
  });

  // do not show application_id widget if there is just a single choice.
  let myOptions = levelOptions[name] || {};

  if (myOptions.hidden) {
    return null;
  }

  let choices = helper.getChoices();
  const id = helper.getId();

  const handleSelect = (evt) => {
    const {itemCache} = props;

    let {target = {}} = (evt || {});
    let value = target.value || "";

    //console.error(`CLUSTER LEVEL ${name} onSelect() - value ${value}`);
    let handler = new SelectionHandler({
      name, cluster, clusterState, itemCache, value
    });

    let newState = handler.getUpdatedState();

    const dispatch = getDispatch();
    dispatch(groupItemsSlice.actions.updateClusterState({
      clusterId: cluster.id,
      clusterState: newState
    }));
  }

  // selector-level selector-enabled selector-{0,1,2..} selector-ready
  const className = helper.getClasses()

  // ID is like cluster-first_row_floor_liners-colour, the actual select widgets
  // append -select to this, which is used to locate the selector & give it focus
  return <div ref={ref} id={id} className={className}>
      <span className="fa-wrapper">
        <span className={helper.getIcon()}/>
      </span>

    <div>
      <ItemClusterSelectWidget
        id={id + "-select"}
        name={name}
        value={value}
        choices={choices}
        onSelect={handleSelect}
        cluster={cluster}
        enabled={enabled}
      />
    </div>
  </div>
}

// Focus on the select element within our div.
function focusOnMe(topNode) {
  let selector = topNode?.querySelector('select');
  if (selector && selector.focus)
    selector.focus();
}
