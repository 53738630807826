import React from 'react';
import ContinueButton from "../form/ContinueButton";
import SpeakerToAffirm from "../../affirm/SpeakerToAffirm";
import ReviewAddress from "./ReviewAddress";
import ShippingAddressAnalyser from "../../logic/ShippingAddressAnalyser";
import {sendCartEvent} from "../../../Analytics/googleShoppingCart";

const affirmLogo = "__affirm-logo __affirm-logo-white __ligature__affirm_full_logo__";

export default function AffirmFormSection(props) {
  const {checkout = {}, callbacks, paymentType="affirm"} = props;
  const {shippingAddress = {}} = checkout;
  let impediments = new ShippingAddressAnalyser(checkout).shippingImpediments();

  const handleSubmit = () => {
    let speaker = new SpeakerToAffirm();
    speaker.startCheckout(props.checkout);

    // google analytics 4
    sendCartEvent({
      eventType: 'add_payment_info',
      shoppingCart: checkout,
      payment_type: "affirm"
    });
  }

  return (
    <div className="">

      <ReviewAddress
        usage="shipping"
        paymentType={paymentType}
        impediments={impediments}
        address={shippingAddress}
        handleChangeAddress={callbacks.changeAddress}/>

      <p className="my-4 text-xl">
        You will be redirected to Affirm to set up your payment.
      </p>

      <ContinueButton
        className="text-brand-light text-center  bg-brand-dark"
        caption=""
        onSubmit={handleSubmit}>
        <span className="fa fa-lock"/>
        {' Continue with '}
        <span className={affirmLogo}>Affirm</span>
      </ContinueButton>
    </div>
  );
}


