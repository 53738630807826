/**
 * Focus on a HTML input element; called for the first_name field
 *
 * @param inputId
 */
export function focusOnInput(inputId) {
  let input = document.getElementById(inputId);
  if (! input?.focus) {
    console.warn(`cannot find ${inputId} to focus on`);
    return;
  }

  input.focus();

  // ...and cursor move to the end.
  if (input.value)
    input.selectionStart = input.selectionEnd = input.value.length;
}
