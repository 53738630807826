import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {camelizeKeysDeep} from "../ReduxStore/Helpers/transformObject";
import clsx from "clsx";
import {useSkeletonLoading} from "../hooks/useSkeletonLoading";
import {describeVehicle} from "../VehicleChooser/logic/vehicle";

export default function ActiveVehicleDisplay({
  customerVehicle,
  classNames,
  uplink,
  icon = "", themeName,
  handleDelete,
  ...props}) {
  const ref = useSkeletonLoading();

  customerVehicle = camelizeKeysDeep(customerVehicle);

  const {vehicleUrl, source} = customerVehicle;
  const caption = describeVehicle(customerVehicle);

  // LEGACY VERSION - AAG with semantic class names
  classNames = clsx(classNames, 'stored-vehicle-inner',
    `${customerVehicle.source || 'vehicle'}-selected`);

  // 2022-03 link to vehicle page if it exists
  return (
    <div ref={ref} className={classNames}>
      {icon && <i className={icon}/>}
      <strong>
        {vehicleUrl ? <a href={vehicleUrl}>{caption}</a> : caption}
      </strong>
      {' '}
      <a className="nobr click-me" id="clear-vehicle-top"
        onClick={handleDelete}>change vehicle</a>
    </div>)
}
