import React, {useEffect} from 'react';
import _ from 'lodash';
import {safeGtag} from "../../../Analytics/googleAnalyticsFour";
import {groupToGoogleItem} from "../../../Analytics/googleAnalyticsItems";

export default function ReportGroupView({productGroup, ...props})  {

  useEffect(() => {
    if (_.isEmpty(productGroup))
      return;

    const event = [ "event", "view_item", {
      currency: 'USD',
      items: [groupToGoogleItem(productGroup)]
    } ];

    safeGtag(...event);
  }, [productGroup])

  return null;
}

