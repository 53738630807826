import React, {useState} from 'react';

import CartSummaryTable from "./CartSummaryTable";
import TinyCartContents from "./TinyCartContents";
import {camelizeKeysDeep} from "../../../ReduxStore/Helpers/transformObject";
import AffirmEstimate from "../../../ShoppingCart/components/sidebar/AffirmEstimate";

export default function CheckoutTotals(
  {
    currentAgent, enableAffirm = false, cartItems, ...props
  }) {

  // the tiny cart display - suppress it for agents
  const [showCart, setShowCart] = useState(!!props.currentAgent);

  const handleToggleCart = () => {setShowCart(!showCart);}

  if (!props.totals)
    return null;

  const totals = camelizeKeysDeep(props.totals);

  let count = cartItems.length;

  return <div id="cart-totals">
    <div id="cart-summary-box">
      <div className="headline">
        <a className="toggle-cart !text-brand-secondary" onClick={handleToggleCart}>
          <strong>
            {' ' + count + ' '}
            {(count === 1) ? "Item" : "Items"}
            {' '}
          </strong>
          <span className={showCart ? "fas fa-chevron-up" : "fas fa-chevron-down"}/>
        </a>
        <h2>Cart Summary</h2>
      </div>

      <CartSummaryTable {...props} />

      {showCart && <TinyCartContents cartItems={cartItems}/>}
    </div>

    {enableAffirm && <AffirmEstimate amount={totals.effectiveTotal}/>}

  </div>
}




