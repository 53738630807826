import React from 'react';

import CartSpecialOfferEntry from './CartSpecialOfferEntry';

export default function CartCouponTool({
  shoppingCart, callbacks, checkoutConfig, ...props
}) {
  const couponConfig = checkoutConfig?.coupons || {};
  if (couponConfig.disabled) return null;

  return <div id="coupon-area">
    {specialOffer ?
      <CartSpecialOfferDisplay callbacks={callbacks} offer={specialOffer}/> :
      <CartSpecialOfferEntry shoppingCart={shoppingCart} callbacks={callbacks} {...props} />}
  </div>
}

function CartSpecialOfferDisplay(props) {
  let {offer = {}, callbacks} = props;

  let removeCoupon = () => callbacks.updateCart({couponCode: ""});

  return <div id="coupon-display">
    <h4>A coupon has been applied to your cart</h4>
    <strong>{offer.name}</strong>
    <p>{offer.description}</p>
    <button type="button" onClick={removeCoupon}>remove coupon</button>
  </div>
}
