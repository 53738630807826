import navigationSlice, {loadHamburgerContent} from "../ReduxStore/navigationSlice";
import React from "react";
import {getClassList} from "../Utility/siteConfig";

export default function HamburgerMenuItem({item}) {
  const {id, name, childrenCount, link} = item;
  const hasChildren = (childrenCount > 0);

  const handleClick = (e) => {
    if (hasChildren) {
      e.preventDefault();
      const dispatch = getReduxStore().dispatch
      dispatch(loadHamburgerContent({id}));
      dispatch(navigationSlice.actions.navigateToHamburgerPanel({id}));
    } else {
      return true;
    }
  }

  const className = getClassList({
    key: 'HamburgerMenuLink',
    base: "block relative",
    fallback: "!text-brand-dark"
  });
  return <li className="py-1">
    <a className={className} href={link} onClick={handleClick}>
      {hasChildren && <span
        className="inline-block absolute t-0 right-2 fa fa-chevron-right"/>
      }

      {name}
    </a>
  </li>
}
