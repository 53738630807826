import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import GenericAddressWidget from "./GenericAddressWidget";
import SelectStateWidget from "./SelectStateWidget";
import EmailWidget from "./EmailWidget";
import SubscribingWidget from "./SubscribingWidget";
import SmartStreetWidget from "./SmartStreetWidget";
import SmartApartmentWidget from "./SmartApartmentWidget";
import {focusOnInput} from "../../services/uiHelpers";
import SmartyLimiter from "./SmartyLimiter";
import HiddenCompanyField from "./HiddenCompanyField";

/**
 * Holds fields for one address - shipping or billing.
 */
export default function AddressFormSection({
  address = {}, addressType, stateList = [],
  tabIndexBase = 100, callbacks,
  ...props
}) {
  // tabIndexBase addressType address callbacks stateList currentAgent readOnly

  const [activeField, setActiveField] = useState(null);

  // when initially revealed, focus on first name
  useEffect(() => {
    focusOnInput(`${addressType}_address-first_name`);
  }, []);

  // also render the email/subscribe fields? only for shipping addr
  let full = addressType !== "billing";

  // called by onFocus() of child components (with simple field name)
  // and onBlur() with null.
  function focusChanged(name) {
    setActiveField(name);
  }

  /**
   * Usually called from the onBlur handler of a child component,
   * this indicates that the user is finished with the field.
   * Send the changes to Redux.
   *
   * @param name field name like "first_name"
   * @param value value for that field, like "James Tiberius"
   */
  function updateAddress(name, value) {
    // Even if it hasn't changed, send the update, so that we
    // can validate. For example, a user tabbing through blank fields for
    // the first time.
    try {
      const {editCheckout} = callbacks;

      // build an object like { shippingAddress: { first_name: 'James' } }
      const payload = {[`${addressType}Address`]: {[name]: value}};

      console.log(`edit addr ${name} => ${value} - sending to Redux`, payload);
      editCheckout(payload);
    } catch (err) {
      alert(`error in update addr: ${err}`)
    }
  }

  const childProps = {
    address,  // volatile
    activeField, // volatile
    addressType,
    currentAgent: props.currentAgent,
    readOnly: !!(props.readOnly),
    callbacks: {
      updateAddress,
      focusChanged,
      // email widget needs saveCheckout
      saveCheckout: callbacks.saveCheckout
    }
  }

  return (
    <div className="lg:grid lg:grid-cols-2 gap-4">
      <GenericAddressWidget {...childProps}
        name="first_name"
        autoComplete="given-name"
        tabIndex={tabIndexBase + 1}
        label="First Name"
        grabFocus={true}
      />

      <GenericAddressWidget {...childProps}
        name="last_name"
        autoComplete="family-name"
        tabIndex={tabIndexBase + 2}
        label="Last Name"/>

      {full && <EmailWidget {...childProps}
        name="email"
        type="email"
        autoComplete="email"
        tabIndex={tabIndexBase + 3}
        label="Email Address"
        className="col-span-2"
      />}

      {(addressType !== "billing") && <SubscribingWidget
        {...childProps}
        className="col-span-2"
        tabIndex={tabIndexBase + 4}
        name="subscribing"/>}

      <SmartyLimiter
        {...childProps}
        component={SmartStreetWidget}
        name="street" autoComplete="address-line1"
        tabIndex={tabIndexBase + 10}
        label="Street Address"/>

      <SmartyLimiter
        {...childProps}
        component={SmartApartmentWidget}
        name="street2" autoComplete="address-line2"
        label="Apt / Suite / Unit"
        tabIndex={tabIndexBase + 30}
        optional={true}
      />

      <HiddenCompanyField
        {...childProps}
        wrapperClassName="col-span-2"
        defaultVisible={!!address.company}
        tabIndex={tabIndexBase + 70}
      />

      <GenericAddressWidget {...childProps}
        tabIndex={tabIndexBase + 71}
        name="city" autoComplete="address-level2"
      />

      <div className="grid grid-cols-2 gap-2">
        <SelectStateWidget {...childProps}
          name="state_code" autoComplete="address-level1"
          label="State"
          countryCode={address.country_code}
          tabIndex={tabIndexBase + 72}
          stateList={stateList}/>

        <GenericAddressWidget {...childProps}
          name="zip" autoComplete="postal-code"
          tabIndex={tabIndexBase + 73}
          label="Zip Code"/>

      </div>

      <GenericAddressWidget {...childProps}
        name="phone" autoComplete="tel"
        tabIndex={tabIndexBase + 74}
        type="tel"/>
    </div>

  );

}

AddressFormSection.propTypes = {
  addressType: PropTypes.string.isRequired,
  // checkout: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  callbacks: PropTypes.object.isRequired,
  tabIndexBase: PropTypes.number,
  readOnly: PropTypes.bool
};

AddressFormSection.defaultProps = {
  tabIndexBase: 100
};

