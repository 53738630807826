import React from 'react';
import clsx from "clsx";
import {getClassList} from "../../Utility/siteConfig";

export default function Headline({item}) {
  let className = getClassList({
    key: 'DynamicCartPopup.headline',
    base: [],
    fallback: 'text-2xl text-green-600'
  });

  if (item) {
    return <h2 className={className}>
      <span className="fal fa-check-circle mr-2"/>
      Added To Cart!</h2>
  } else {
    return <h2 className={className}>
      <span className="fa fa-shopping-cart mr-2"/>
      Your Shopping Cart </h2>
  }
}
