import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ItemClusterLevel from "../ClusteredItems/main/ItemClusterLevel";
import {levelIsEnabled} from "../../logic/ClusterLevels";
import {getSiteComponent} from "../../../Utility/componentRegistry";
import clsx from "clsx";

export default function ItemClusterSelectorsList({
  cluster, clusterState, itemCache, className, ...props}) {

  const {selections} = clusterState;
  // "matchingProducts", "selectedItem", "levelOptions", "highlighted"
  const {levels} = cluster;

  const enabledLevels = levels.filter(
    levelName => levelIsEnabled({levelName, levels, selections}));

  let UI = getSiteComponent('ItemClusterLevel', ItemClusterLevel);

  return <div className={clsx(className)}>
    {enabledLevels.map((levelName, position) =>
      <UI
        key={levelName} name={levelName} position={position}
        enabled={true}
        value={selections[levelName]}
        clusterState={clusterState}
        cluster={cluster}
        itemCache={itemCache}
      />
    )}
  </div>
}
