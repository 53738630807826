import React from 'react';
import PropTypes from 'prop-types';
import {camelCase} from 'lodash';

import CartPriceDisplay from "./CartPriceDisplay";
import CartItemHiddenFields from "./CartItemHiddenFields";
import DynamicAddButton from "../../DynamicAddButton";
import CheckoutLink from "../../StandardItems/CheckoutLink";
import {getClassList} from "../../../../Utility/siteConfig";
import {camelizeKeysDeep} from "../../../../ReduxStore/Helpers/transformObject";

function isOutOfStock(product) {
  if (!product) return false;

  let status = camelCase(product.buyableStatus);
  return (status === "outOfStock") || (status === 'discontinued');
}

export default function AddToCartForm(props) {
  let {selectedItem, cartItem, shoppingCart} = props;

  // 2025-02 not camelized above here.
  const product = camelizeKeysDeep(props.product || selectedItem?.product);

  let outOfStock = isOutOfStock(product);

  let enabled = props.enabled && product && !outOfStock;

  // 2022-09 this is NO LONGER A FORM due to the DynamicAddButton doing
  // all the heavy lifting.

  // method="post" action={this.getUrl()} onSubmit={this.onSubmit}

  let className = getClassList({
    key: 'AddToCartForm',
    base: ["cluster-cart-form text-lg lg:text-base lg:text-right",
      outOfStock ? 'out-of-stock' : null,
      enabled ? 'cart-enabled' : 'cart-disabled'
    ]
  })

  return <div className={className}>
    <CartPriceDisplay {...props} />

    {enabled && <CartItemHiddenFields {...props} />}

    <DynamicAddButton {...props} disabled={!enabled}/>

    {cartItem && <CheckoutLink shoppingCart={shoppingCart}/>}

    {outOfStock && <div className="stock-status out-of-stock">Out Of Stock</div>}
  </div>
}

AddToCartForm.propTypes = {
  enabled: PropTypes.bool,
  cartItem: PropTypes.object,
  appliedItem: PropTypes.object
};
