import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {camelizeKeys} from "../Helpers/transformObject";
import {buildSaveCartPost} from "../../SaveCart/services/saveCartLogic";

const initialState = {
  // window state
  saveCartStatus: {open: false, flavour: null, status: 'untouched'},
};

let simpleUpdate = (state, action) => {_.assign(state, action.payload);};

// just merge in any arbitrary new state.
export const saveCartSlice = createSlice({
  name: 'saveCart',
  initialState,
  reducers: {
    update: simpleUpdate,
    // show/hide are just aliases to match the actions defined below
    show: simpleUpdate,
    hide: simpleUpdate,
    // merge the payload into the existing saveCartStatus, preserving old contents
    mergeStatus: (state, action) => {
      _.assign(state.saveCartStatus, camelizeKeys(action.payload));
    }
  }
});

// Show the popup and create a new saveCartStatus.
// Used by SaveCartButton and by the exit-intent logic.
export const showSaveCart = createAction('saveCart/show', (payload = {}) => {
  const saveCartStatus = Object.assign({
    open: true,
    openedAt: new Date().getTime()  // impedes future auto-opens, see SaveCartInvoker
  }, payload);

  return {payload: {saveCartStatus}}
});

// Close the popup and reset saveCartStatus
export const hideSaveCart = createAction('saveCart/hide', (payload = {}) => {
  return {
    payload: {
      saveCartStatus: {
        open: false,
        status: 'closed',
        closedAt: new Date().getTime() // impedes future auto-opens, see SaveCartInvoker
      }
    }
  }
});

export const submitSaveCart = createAsyncThunk(
  "submitSavedCart",
  async (payload, thunkAPI) => {
    const {formData, cartCode} = payload;
    const {mergeStatus} = saveCartSlice.actions;
    let flavour = payload.flavour || formData.flavour;

    // update pending status, and also put the form data into saveCartStatus
    thunkAPI.dispatch(mergeStatus({
      status: 'pending',
      flavour,
      formData
    }));

    const post = buildSaveCartPost({
      formData, cartCode, storeState: thunkAPI.getState()
    })

    post.perform()
      .then(r => r.json())
      .then(data => {
        console.log("submitSaveCart returns: ", data);
        // {"status":"success"}
        thunkAPI.dispatch(mergeStatus({open: false, ...data}));
      })
  }
);

export default saveCartSlice;
