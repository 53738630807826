import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function Prop65Warning({...props}) {
  const [clicked, setClicked] = useState(false);

  const handleClick= () => setClicked(true)

  if (!clicked)
  {
    return <div className="text-center">
      <b>California Residents: </b>
      <a className="inline-block cursor-pointer underline" onClick={handleClick}>
        Prop 65 Warning
      </a>
    </div>
  }

  return (
    <a className="inline-block cursor-pointer" onClick={handleClick}>
      <div className="grid grid-cols-2 gap-2 "
        style={{gridTemplateColumns: '42px auto'}}>

          <div className="text-center text-yellow-300 text-4xl">
            <img className="inline-block mx-auto my-auto"
              src="/img/badges/prop65.png" alt="/!\"
              height="24" style={{height: '24px'}}
              />
          </div>

          <div className="align-middle text-left">
            <div>
              California Prop 65 -
              <span className="font-bold">WARNING</span>
            </div>
            {clicked ? <div>
              This product can expose you to chemicals, which are known to the
              State of California to cause Cancer, Birth Defects, or other Reproductive Harm.
              For more information go to
              {' '}
              <a className="underline"
                href="https://www.p65Warnings.ca.gov/product">www.p65Warnings.ca.gov/product</a>
              {' '}

            </div> : <a onClick={handleClick}>read more...</a>}
          </div>
      </div>
    </a>);
}

// .border.border-gray-400.py-4.group
//   .grid.grid-cols-2.gap-2{style: "grid-template-columns: 42px auto"}
// .text-center
// %img.inline-block.mx-auto{src: "/img/badges/prop65.png", alt: "Warning", height: 24, style: "height: 24px"}
// .text-left
// %span.font-bold
// California Prop 65 - WARNING
//   .hidden{class: 'group-hover:block'}
// %div : This product can expose you to chemicals, which are known to the State of California to cause Cancer, Birth
//     Defects, or other Reproductive Harm. For more information go to www.p65Warnings.ca.gov/product.
