import React from 'react';
import LocalUrlMaker from "../../../lib/LocalUrlMaker";

// Presented under the Add button when an item is already in the cart.
export default function CheckoutLink({cartCode, shoppingCart}) {
  cartCode ||= shoppingCart?.cartCode;

  const url = LocalUrlMaker.prefixedPath(`/cart/${cartCode || ''}`);

  return <div className="mt-2 text-center lg:text-right">
    <div className="text-gray-400 text-xs">Item Added to Cart</div>
    <div className="underline hover:no-underline"><a href={url}>Go to Checkout</a>
    </div>
  </div>
}
