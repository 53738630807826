import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {editCheckout, fetchCheckout, setCheckout, updateCheckout} from "../../ReduxStore/Checkout/checkoutSlice";
import CheckoutStateBuilder from "./CheckoutStateBuilder";
import LocalUrlMaker from "../../lib/LocalUrlMaker";
import {isEmptyCheckout} from "../logic/checkout";

import CheckoutUI from "../components/general/CheckoutUI";
import PulsingLinesThrobber from "../../Common/components/PulsingLinesThrobber";
import OrderComplete from "../components/order/OrderComplete";

import CatchErrors from "../../Utility/CatchErrors";
import PaypalReturnHandler from "../components/payment/PaypalReturnHandler";

/**
 * 2021 Checkout
 */
export default class CheckoutContainer extends Component {

  // I am ashamed of this
  state = new CheckoutStateBuilder(this.props, {}).initState();

  componentDidUpdate(prevProps, prevState) {
    let newState = new CheckoutStateBuilder(this.props, this.state).call();

    let merged = Object.assign({}, this.state, newState);
    if (!_.isEqual(merged, this.state)) {
      // console.log("Container: state changed, update: ", merged);
      this.setState(merged);
    }
  }

  /**
   * setStep - handles the pagination widget.
   *
   * @param step
   */
  setStep = (step) => {
    step = step ? +step : 2;  // force to integer

    this.setState({step: step}, () => {
      // step 1 is in another app, on the View Cart page.
      if (step < 2) {
        const {cartCode} = this.props;

        document.location = LocalUrlMaker.prefixedPath(`/cart/${cartCode}`);
      }

      let {window} = globalThis;

      // scroll to top of page after navigating.
      if (window && window.scrollTo) {
        window.scrollTo(0, 0);
      }
    });
  }

  setSameAddress = (sameAddr) => {
    // let { shippingAddress, billingAddress } = this.props.checkout;
    // EditCheckout.js will clone fields of ship to bill.
    // we send it both ways so the presence of "diffAddress" key triggers the cloning logic.
    this.editCheckout({sameAddress: sameAddr, diffAddress: !sameAddr})
  }

  // user clicked "Change Address" link, on the payment tab's review-address widget
  changeAddress = (evt) => {
    if (evt.preventDefault)
      evt.preventDefault();

    this.setStep(2);
  }

  // Payment Type Switcher - user clicked a payment type name like 'affirm'
  setPaymentType = (paymentType, callback) => {
    this.setState({paymentType}, callback)
  }

  // For child components, send selected props and all of state.
  getChildProps() {
    let cp = Object.assign(
      _.omit(this.props, 'startup'),
      this.state,
      {callbacks: this.callbacks});

    return cp;
  }

  // this will send to Redux using an EDIT_CHECKOUT action.
  editCheckout = (edits) => {
    const {dispatch} = this.props;
    dispatch(editCheckout(edits));
  }

  // payload should have props
  setCheckout = (payload) => {
    const {dispatch} = this.props;
    dispatch(setCheckout(payload));
  }

  clearError = () => {
    const {dispatch} = this.props;
    dispatch(setCheckout({error: null}));
  }

  /**
   *
   * @param payload {Object} with keys "checkout" and optionally "edits"
   * @returns {Promise}
   */
  saveCheckout = (payload) => {
    const {dispatch, checkout} = this.props;

    if (!payload.checkout) {  // missing? use the one in props.
      payload = Object.assign({}, payload, {checkout});
    }

    // updateCheckout - just post to back end without reloading
    // fetchCheckout - full reload with throbber etc.
    if (payload.quiet) {
      // console.log("QUIET.............................")
      return dispatch(updateCheckout(payload));
    } else {
      return dispatch(fetchCheckout(payload));
    }
  }

  callbacks = {
    // setState - expose this so children can save arbitrary objects in state.
    setState: (diff, fn) => this.setState(diff, fn),

    // select page 1,2,3, etc. of checkout.
    setStep: this.setStep,

    // set credit_card, affirm, paypal... type
    setPaymentType: this.setPaymentType,

    // update the checkout object in Redux, making edits one field at a time.
    // Does not save to back-end.
    editCheckout: this.editCheckout,

    // update the checkout object in Redux, in bulk - replace entire checkout state.
    // Does not save to back-end.
    setCheckout: this.setCheckout,

    // handle clicking the "edit" button on the address review widget
    changeAddress: this.changeAddress,

    // same/different address switch
    setSameAddress: this.setSameAddress,

    // Save the checkout to the back-end.
    saveCheckout: this.saveCheckout,

    // clear checkoutState.error
    clearError: this.clearError
  };

  componentDidMount() {
    let {checkout, dispatch, cartCode, step = 2, startup = {}} = this.props;

    // Load the checkout, unless we already have something that looks like it.
    if (_.isEmpty(checkout) || _.isEmpty(checkout.totals)) {
      // noinspection JSValidateTypes
      dispatch(fetchCheckout({cartCode}));
    }
  }

  render() {
    const {status, checkout = {}, cartCode, startup = {}, dispatch} = this.props;

    // If an order exists render the OrderComplete widget instead
    // of our usual CheckoutUI
    const {order} = checkout;

    if (order && order.id) {
      return <OrderComplete order={order} cartCode={cartCode} checkout={checkout}/>
    }

    let cp = this.getChildProps();

    // render a throbber IN ADDITION TO the usual content.
    let loading = (status === 'pending' || status === 'empty');
    console.log(`render CheckoutContainer with status=${status} loading=${loading}`)

    return <section>
      <CatchErrors>
        {!isEmptyCheckout(checkout) && <PaypalReturnHandler
          cartCode={cartCode}
          paypal={startup.paypal}
          dispatch={dispatch}
          setStep={this.setStep}
          setPaymentType={this.setPaymentType}
        />}

        {loading && <PulsingLinesThrobber/>}

      </CatchErrors>

      <CatchErrors>
        <CheckoutUI {...cp} />
      </CatchErrors>
    </section>
  }
}

CheckoutContainer.propTypes = {
  cartCode: PropTypes.string.isRequired,
  startup: PropTypes.object.isRequired,
  braintreeData: PropTypes.object // initiall null until we fetch token
  //currentAgent: PropTypes.object, // or NULL
}
