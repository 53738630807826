import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useThemeVar} from "../../../Utility/siteConfig";

export default function OutOfStockExplainer({product}) {
  const siteName = useThemeVar('siteName', null);
  if (siteName === 'carlyle') {
    return <CarlyleOutOfStockExplainer product={product}/>
  }

  return <div>
    Sorry, this item is out of stock.
  </div>
}

function CarlyleOutOfStockExplainer({product}) {
  let url =  useThemeVar('outOfStockUrl',"https://www.napaonline.com/brand/carlyle-tools");

  return <div className="border border-gray-400 text-brand-dark p-2 my-4">
    Sorry, this product is temporarily out of stock on our site.
    Please visit
    {' '}
    <a href={url} target="_blank" className="font-bold underline">NAPAOnline.com</a>
    {' '}
    to purchase online.
  </div>
}
