import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {getClassList} from "../Utility/siteConfig";
import clsx from "clsx";

export default function HamburgerSearch({config, ...props}) {
  const {siteSearch = {}} = config;
  const {url, placeholder = 'SEARCH'} = siteSearch;
  if (!url)
    return null;

  const className = getClassList({
    key: 'HamburgerSearch',
    base: "w-full p-2 z-100",
    fallback: [],
  });

  return <form action={url} method="get" role="search" className="p-2">
    <div className="relative">
      <input type="search" name="text"
        className={className}
        tabIndex="1"
        placeholder={placeholder}/>
      <button type="submit" className={clsx(
        "absolute right-2 top-2 z-200 text-center text-xl text-brand-dark"
      )}><span className="fa fa-search"></span></button>
    </div>

  </form>

}
