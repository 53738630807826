import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ItemFactBox from "./ItemFactBox";
import ItemImageBox from "./ItemImageBox";
import ItemCartBox from "./ItemCartBox";
import clsx from "clsx";
import {camelizeKeysDeep} from "../../../ReduxStore/Helpers/transformObject";

export default function UniversalItemDisplay({product, ...props}) {
  const {shoppingCart, sectionConfig={}} = props;

  product = camelizeKeysDeep(product);

  const cartIndex = _.keyBy((shoppingCart && shoppingCart.cartItems), 'productId');

  const cartItem = cartIndex[product.id];

  const { compact= false } = sectionConfig;

  const classes = [
    "item-display grid clearfix lg:pb-4 my-4",
    compact ? 'grid-cols-1' : 'lg:grid-cols-3'
  ]
  return <div className={clsx(classes)}>
    {!compact && <ItemImageBox {...props} product={product} cartItem={cartItem}/> }
    <ItemFactBox {...props} product={product}  cartItem={cartItem}/>
    <ItemCartBox {...props} product={product}  cartItem={cartItem}/>
  </div>;
}

UniversalItemDisplay.propTypes = {
  product: PropTypes.object.isRequired,
  application: PropTypes.object, // optional, for universal
  dispatch: PropTypes.func.isRequired,
  shoppingCart: PropTypes.object
}
