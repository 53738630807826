import React from 'react';
import Money from '../../../lib/Money';

// 2023-11 modernised
export default function GridItemDesc({item}) {
  let usd = new Money(item.price);

  // 2025-02 this is now dynamic, added flags to back-end data
  let freeShipping = item.fl ? item.fl.match('F') : false;

  return (
    <ul className="list-none text-sm leading-tight">
      <li className="">From {usd.toString()}</li>
      <li className="text-brand-primary">{ freeShipping ? "Free Shipping" : " " }</li>
    </ul>
  );
}
