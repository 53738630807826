import React, {useMemo} from 'react';
import SaveCartInvoker from "../services/SaveCartInvoker";
import CatchErrors from "../../Utility/CatchErrors";
import SaveCartUi from "./SaveCartUi";

const isDev = (process.env.NODE_ENV == 'development');

/**
 * SaveCartContainer is rendered by SaveCartApp which is physically just above
 * the shopping cart in the page.  It provides logic for, and holds, both the
 * SaveCartInvoker (an invisible component) and the SaveCartModal (a modal
 * that exists only when needed).
 */
export default function SaveCartContainer({
  currentAgent,
  saveCartStatus,
  shoppingCart,
  dispatch,
  checkoutConfig,
  ...props
}) {
  const saveCartConfig = useMemo(() => checkoutConfig.saveCart || {} , [checkoutConfig]);

  if (saveCartConfig?.disabled)
    return null;

  return <pre><code>{JSON.stringify(_.keys(props), null, 4)}</code></pre>

  return <div className="clear-me">
    {!currentAgent && <SaveCartInvoker
      shoppingCart={shoppingCart}
      saveCartStatus={saveCartStatus}
    />}

    <CatchErrors><SaveCartUi
      saveCartStatus={saveCartStatus}
      shoppingCart={shoppingCart}
    /></CatchErrors>
  </div>
}

// usage:
// <SaveCartDebug saveCartStatus={saveCartStatus} />

function SaveCartDebug({
  saveCartStatus = {}
}) {
  if (!isDev) return false;

  return <div className="grid grid-cols-2">
    <div className="debug overflow-clip">
      <pre>{JSON.stringify(saveCartStatus, null, 2)}</pre>
    </div>
    <div className="debug overflow-clip">
      FIXME status=failed SaveCartFailed
    </div>
  </div>
}







