import React from 'react';

import {isEmpty} from 'lodash';
import scrapyardSlice from "../ReduxStore/scrapyardSlice";
import Modal from "../Modals/Modal";

export default function ErrorModal({error, dispatch}) {
  if (!error) return null;

  const message = error.message || error.toString() || "Unknown Error";
  const headline = error.headline || "Something went wrong.";
  const instructions = error.instructions ||
    "Please try again. If this error persists, please contact us by phone or chat for assistance.";
  const icon = error.icon || 'fa fa-exclamation-triangle';

  const handleClose = () => {
    dispatch(scrapyardSlice.actions.setError({error: null}));
  }

  return <Modal handleClose={handleClose} id="error-modal">
    <div className="modal-content">
      <h2 className="text-red-600 text-xl">
        {icon && <span className={icon} />}
        {' ' + headline}</h2>

      <p className="font-bold py-8">{message}</p>

      <p>{instructions}</p>

      <div className="text-center">
        <button type="button" onClick={handleClose}
          className="mx-auto text-white bg-brand-secondary my-4 py-2 px-4">CLOSE</button>
      </div>
    </div>
  </Modal>
}


