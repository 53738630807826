import React from "react";
import _ from 'lodash';

// Used by CartSpecialOfferEntry to render the coupon code field,
// and TaxExemptWidget for the agents-only tax exempt widget.
export default function FieldWithApply(props) {
  const {name, callbacks, id, shoppingCart = {}} = props;

  const handleChange = (evt) => {
    const value = evt.target.value;
    callbacks.updateCart({[name]: value});
  }

  // the apply button does absolutely nothing.
  // it's just there to trick the user into leaving the text field,
  // creating an onBlur event
  const handleClick = (evt) => {
    console.log("apply button clicked");
  }

  // 2020-01 add maxLength because some people put their
  // entire life story in the tax exemption field
  let defaultValue = shoppingCart[name];

  return (<div className="field-with-apply">
    <input type="text" id={id} name={name}
      className="border border-gray-300 px-2 py-1"
      maxLength={props.maxLength || 40}
      placeholder={props.placeholder}
      defaultValue={defaultValue}
      onBlur={handleChange}/>

    {' '}
    <button type="button" onClick={handleClick} className="px-2 py-1 ml-2">apply</button>
  </div>);

}
