import React from 'react';
import _ from 'lodash';
import {getSiteComponent} from "../../../Utility/componentRegistry";

// Non-clustered list of Universal Items with add-to-cart widgets.
// Used on Universal group pages when pagetype does not include C.
// https://lisa.aagarage.net/Winches/Warn-Winch-Recovery-Kit

export default function UniversalItemList({pageConfig = {}, ...props}) {
  const {shoppingCart, dispatch, products = []} = props;

  const sorted = _(products).values().sortBy((p) => p.name).value();

  // DEPRECATED LOGIC: do not render when there is exactly one universal item, for
  // it shows in the upper right, next to the group image container, instead.

  // 2022-03 render it anyway so we get bullets/pdf links
  // if (prods.length == 1) return null;
  const sectionConfig = pageConfig?.productSection || {};

  const ItemWidget = getSiteComponent('UniversalItemWidget');

  return (
    <div>
      {sorted.map((p) => <ItemWidget key={p.id}
        sectionConfig={sectionConfig}
        shoppingCart={shoppingCart}
        product={p}
        products={products}
      />)}
    </div>
  );
}


