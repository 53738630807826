import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ShowCustomerVehicleUI from "./ShowCustomerVehicleUI";

import {deleteSessionVehicle, vehicleSlice} from "../ReduxStore/CustomerVehicle/vehicleSlice";
import ActiveVehicleDisplayNOL from "./ActiveVehicleDisplayNOL";
import {navigationActions} from "../ReduxStore/navigationSlice";
import {camelizeKeysDeep} from "../ReduxStore/Helpers/transformObject";

/**
 * 2022 - modernized to use vehicleSlice.
 *
 * Handle Redux interactions for ShowCustomerVehicleApp, which is
 * the top-of-screen widget that shows the currently selected
 * customer vehicle.
 *
 */
export default function ShowCustomerVehicleContainer({
  customerVehicle, ...props}) {

  const { dispatch } = props;
  const [panelOpen, setPanelOpen] = useState(false);

  if (customerVehicle) {
    customerVehicle = camelizeKeysDeep(customerVehicle);
  }

  const showOverlay = useCallback((active) => {
    dispatch(navigationActions.showOverlay({
      // show/hide overlay
      name: 'topVehicleDisplay',
      active: active, // true/false
      mode: 'content'
    }));
  }, [dispatch]);

  // show/hide overlay when panel is toggled.
  useEffect(() => {
    showOverlay(panelOpen);

    return () => showOverlay(false);
  }, [panelOpen]);

  useEffect( () => {
    console.log("VEHICLE CHANGED");
    setPanelOpen(false);
    //showOverlay(false);
  }, [customerVehicle?.id])
  const handleDelete = (evt) => {
    evt?.preventDefault();

    showOverlay(false);

    // inform back-end that it should be cleared from session;
    // this will also clear from Redux, after the operation completes.
    // then we will redirect to the url in 'uplink' if present.
    dispatch(deleteSessionVehicle({
      by: 'ShowCustomerVehicle#handleDelete', uplink: props.uplink
    }));
  };

  const showPanel = (visible) => {
    setPanelOpen(visible);
  }

  return <ShowCustomerVehicleUI
    handleDelete={handleDelete}
    showPanel={showPanel}
    panelOpen={panelOpen}
    customerVehicle={customerVehicle}
    {...props} />

}


ShowCustomerVehicleContainer.propTypes = {
  // include an FontAwesome icon when a vehicle is present?
  // AAG theme has none, NOL theme has 'fas fa-garage-open'
  icon: PropTypes.string,

  // from Redux
  dispatch: PropTypes.func.isRequired,

  // optional - only when set - often null
  customerVehicle: PropTypes.shape({
    id: PropTypes.number
  }),
};
