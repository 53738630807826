import React from 'react';
import _ from 'lodash';
import {Provider, connect} from 'react-redux';

import DynamicCartPopup from "./DynamicCartPopup";
import {getReduxStore} from "../../ReduxStore/store";

function mapStateToProps(state) {
  let {cart, vehicleSlice} = state;

  // omit: cartItemId, contextKeys, callInNumber...
  return Object.assign(
    _.pick(cart, 'shoppingCart', 'dynamicCart', 'newItemIds', 'recommendations'),
    _.pick(vehicleSlice, 'customerVehicle')
  );
}

let Wrapper = connect(mapStateToProps)(DynamicCartPopup);

export default function DynamicCartApp(props) {
  return <Provider store={getReduxStore()}>
    <Wrapper {...props} />
  </Provider>
}

