import {SITE_CONFIG} from "canyoneroTheme";
import {COMPONENT_DEFAULTS, COMPONENT_REGISTRY} from "./componentRegistryData";
const {components = {}} = SITE_CONFIG;


/**
 *  Get a registered component function from SITE_CONFIG.components
 * @param key {String}
 * @param fallback {String}
 * @returns {function}
 */
export const getSiteComponent = function (key, fallback = null) {
  let name = getSiteComponentName(key, fallback);

  let component = COMPONENT_REGISTRY[name];
  if (component)
    return component;

  if (typeof fallback === "function")
    return fallback;

  console.error(`cannot find ${name} in component registry`);
  return null;
}

function getSiteComponentName(key, fallback) {
  let name = (components.hasOwnProperty(key)) ? components[key] : fallback;
  name ||= COMPONENT_DEFAULTS[key];
  if (!name)
    console.error(`cannot find named component: ${key}`);
  return name;
}
