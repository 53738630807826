import UniversalItemDisplay from "../ProductGroupPage/components/StandardItems/UniversalItemDisplay";
import AddToolProduct from "../ProductGroupPage/components/StandardItems/AddToolProduct";
import ItemClusterUi from "../ProductGroupPage/components/ClusteredItems/main/ItemClusterUi";
import ToolItemClusterUi from "../ProductGroupPage/components/ClusteredItems/main/ToolItemClusterUi";
import ItemClusterLevel from "../ProductGroupPage/components/ClusteredItems/main/ItemClusterLevel";
import ToolItemClusterLevel from "../ProductGroupPage/components/ClusteredItems/main/ToolItemClusterLevel";
import GridItem from "../GroupGrid/components/grid/GridItem";
import ToolGridItem from "../GroupGrid/components/grid/ToolGridItem";

export const COMPONENT_REGISTRY = {
  GridItem: GridItem,
  ToolGridItem: ToolGridItem,
  UniversalItemDisplay: UniversalItemDisplay,
  AddToolProduct: AddToolProduct,
  ItemClusterUi: ItemClusterUi,
  ItemClusterLevel: ItemClusterLevel,
  ToolItemClusterUi: ToolItemClusterUi,
  ToolItemClusterLevel: ToolItemClusterLevel
}

export const COMPONENT_DEFAULTS = {
  GridItem: "GridItem",
  UniversalItemWidget: "UniversalItemDisplay",
  ItemClusterUi: "ItemClusterUi",
  ItemClusterLevel: "ItemClusterLevel"
};
