import React, {useEffect, useState} from 'react';

import AgentNoteTool from './AgentNoteTool';
import ShoppingCartTotals from './ShoppingCartTotals';
import CartSidebarButtonBox from './CartSidebarButtonBox';
import {affirmAvailableForTotal, refreshAffirm, safeRefreshAffirm} from "../../../lib/affirmHelpers";
import BraintreeClientProvider from "../../../Checkout/components/general/BraintreeClientProvider";
import PaymentPanel from "../../../Checkout/components/payment/PaymentPanel";

export default function CartSidebar(props) {
  const {currentAgent, shoppingCart = {}, onStartCheckout, checkoutConfig} = props;
  const {effectiveTotal = 0, customerId} = shoppingCart;

  const [enableAffirm, setEnableAffirm] = useState(false);

  // Determine whether AFFIRM is available.
  // TODO: the child components should really figure this out on their own
  useEffect(() => {
    if (!effectiveTotal)
      return;

    const enabled = affirmAvailableForTotal(effectiveTotal, checkoutConfig)

    if (enabled !== enableAffirm)
      setEnableAffirm(enabled);
  }, [effectiveTotal]);

  return (
    <div id="cart-summary"
      className={customerId ? "saved-cart" : "unsaved-cart"}>

      <div id="cart-totals-container">
        {currentAgent && <AgentSidebarTools {...props} />}
        <ShoppingCartTotals enableAffirm={enableAffirm} shoppingCart={shoppingCart} checkoutConfig={checkoutConfig}/>
      </div>

      <BraintreeClientProvider braintreeData={props.braintreeData}
        render={(btProps) => <CartSidebarButtonBox
          onStartCheckout={onStartCheckout}
          checkoutConfig={checkoutConfig}
          enableAffirm={enableAffirm} {...props} {...btProps} />
        }
      />
    </div>
  );
}

/**
 * At top of sidebar - agent tools, revealed only if agent.
 *
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
function AgentSidebarTools(props) {
  const {shoppingCart, currentAgent} = props;
  if (!currentAgent) return null;

  const {customerName, customerId} = shoppingCart;

  return <div>
    {customerName && <h4 className="agent-fg">{customerName}</h4>}

    <AgentNoteTool {...props} />
  </div>
}
