import React from 'react';
import {isEmpty} from 'lodash';
import CartDisplayTotal from "./CartDisplayTotal";
import CartDisplayShippingTotal from "./CartDisplayShippingTotal";

export default function CartSummaryBox({shoppingCart, ...props}) {
  const {shippingAddress={} } = shoppingCart;

  const showTaxLine  = shoppingCart.effectiveTax  || !isEmpty(shippingAddress);

  return <div id="cart-summary-box">
    <h2>Cart Summary</h2>

    <ul id="cart-summary-table">
      <CartDisplayTotal
        id="regular-subtotal"
        label="Subtotal"
        value={shoppingCart.regularSubtotal}
      />

      <CartDisplayTotal
        id="savings"
        label="Savings"
        optional="true"
        value={-shoppingCart.subtotalSavings}
      />

      <CartDisplayShippingTotal
        id="shipping"
        label="Shipping"
        value={shoppingCart.effectiveShipping}
        shippingAddress={shippingAddress}
      />

      {showTaxLine && <CartDisplayTotal
          id="tax"
          label="Tax"
          value={shoppingCart.effectiveTax}
        />}

      <CartDisplayTotal
        id="total"
        label="Total"
        value={shoppingCart.effectiveTotal}
      />

      <TaxExemptIndicator shoppingCart={shoppingCart}/>
    </ul>
  </div>

}

function TaxExemptIndicator(props) {
  let {shoppingCart} = props;
  let {taxExemptCode} = shoppingCart;

  if (!taxExemptCode)
    return null;

  return <div id="tax-exempt-indicator">Tax Exemption No. <b>{taxExemptCode}</b></div>
}
