import React, {useState} from 'react';
import {keyBy} from 'lodash';
import {getClassList} from "../../../Utility/siteConfig";
import DynamicAddButton from "../DynamicAddButton";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import {isOutOfStock} from "../../logic/products";
import OutOfStockExplainer from "./OutOfStockExplainer";

/** This is the Carlye add to cart widget */

export default function AddToCartWithQuantity({
  cartItem, product, products, ...props}) {
  const {shoppingCart, sectionConfig = {}} = props;

  const [quantity, setQuantity] = useState(1);

  const cartIndex = keyBy((shoppingCart && shoppingCart.cartItems),
    'productId');

  cartItem ||= (product && cartIndex) ? cartIndex[product.id] : null;

  const className = getClassList({
    key: 'AddToCartWithQuantity',
    base: []
  });

  function handleQuantity(evt) {
    let value = evt?.target?.value || 1;
    value = isNaN(value) ? 1 : +value;
    setQuantity(value);
  }

  const outOfStock = isOutOfStock(product);

  return (
    <div className={className}>
      <span className="inline-block font-bold mr-2">QTY:</span>
      <input className={'inline-block w-8 border-black border text-center px-2 py-1'}
        value={quantity} onChange={handleQuantity}/>

      <div className="inline-block mx-4">
        {cartItem ? <GoToCartButton shoppingCart={shoppingCart}
          caption={"Added to Cart"}
        /> : <DynamicAddButton className=""
          product={product} quantity={quantity}/>}
      </div>

      {outOfStock && <div className="my-4">
        <OutOfStockExplainer product={product} />
      </div>}

    </div>
  );
}

function GoToCartButton({shoppingCart, className, caption}) {
  let cartCode = shoppingCart?.cartCode;

  const url = LocalUrlMaker.prefixedPath(cartCode ? `/cart/${cartCode}` : '/cart');

  className = getClassList({
    key: 'GoToCartButton',
    base: [className]
  });

  return <a href={url} className={className}>{caption}</a>
}
