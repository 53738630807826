import React, {useState, useEffect} from 'react';

import {safeRefreshAffirm} from "../../../lib/affirmHelpers";

// Used by both shopping cart and checkout
export default function AffirmEstimate({amount, checkoutConfig}) {
  const [oldAmount, setOldAmount] = useState(0);
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    if (amount !== oldAmount) {
      setOldAmount(amount);

      safeRefreshAffirm();
    } else if (refreshCount < 2) {
      // wait for affirm to load
      window.setTimeout(safeRefreshAffirm, 250 * (1 + refreshCount));
      setRefreshCount(x => x + 1);
    }
  }, [amount, refreshCount]);

  // force the useEffect to trigger a second time

  return <div className="text-center mt-3 text-sm">
    <p className="affirm-as-low-as"
      data-page-type="cart"
      data-amount={amount}
    />
  </div>
}
