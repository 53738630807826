import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import LocalUrlMaker from "../../lib/LocalUrlMaker";
import {clearState} from "../Helpers/requestHelpers";

const camelizeKeys = hash => _.mapKeys(hash, (v, k) => _.camelCase(k))

export const loadGroupItems = createAsyncThunk(
  "groupItems/load",
  ({params}, thunkAPI) => {
    let {dispatch} = thunkAPI;

    if (!params.group_id) {
      console.warn("loadGroupItems - no group id param")
    }

    let qs = new URLSearchParams(params).toString();
    let url = LocalUrlMaker.jsonPath("/item_applications.json?" + qs);

    return fetch(url)
      .then(r => r.json())
      .then(payload => {
        dispatch(groupItemsSlice.actions.setGroupItems(payload))
      }).catch(err => {
        alert("error loading products" + err)
      });
  }
);

export const groupItemsSlice = createSlice({
  name: 'groupItemsSlice',
  initialState: {
    allClusterStates: {}
  },
  reducers: {
    setGroupItems: (state, action) => {
      // payload: cluster_index, item_applications, all_apps, products, product_detail
      const payload = camelizeKeys(action.payload);

      // These require special handling.
      let { clusterIndex } = payload;
      const {productDetail, products} = payload;

      // itemApplications: array of { id, product_id, footnote...}

      // productDetail (lloyd stuff): camelize top only
      if (productDetail)
        payload.productDetail = camelizeKeys(productDetail);

      // clusterIndex - camelize top level and 3rd level keys under .clusters
      if (clusterIndex) {
        clusterIndex = camelizeKeys(clusterIndex);

        // camelize second level down (but not deeper?)
        let {clusters} = clusterIndex;
        clusterIndex.clusters = _.mapValues(clusters, v => camelizeKeys(v));

        payload.clusterIndex = clusterIndex;
      }

      // products: array to hash
      if (Array.isArray(products)) {
        // products: hash of { id => product, id => product }
        // product: { id, product_group_id, vendorsku, etc.
        payload.products = _.keyBy(products, 'id');
      }

      console.log(`Loaded ${_.size(products)} product(s)`)
      Object.assign(state, payload);
    },

    updateClusterState: (state, action) => {
      const { payload } = action;
      const { clusterId, clusterState } = payload;
      console.log("################# update CS in Redux: ", payload);
      (state.allClusterStates ||= {})[clusterId] = clusterState;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      "vehicleSlice/setVehicle",
      (state, action) => {
        const {payload} = action;
        const {customerVehicle} = payload;
        if (!customerVehicle) {
          console.warn("[groupItemsSlice]: DEPRECATED vehicle is reset, clearing state. payload=" +
            JSON.stringify(payload));
          clearState(state);
        }
      });

    builder.addCase(
      "vehicleSlice/clearVehicle",
      (state, action) => {
        console.log(`[groupItemsSlice] clearVehicle called: ${JSON.stringify(action.payload)}`)
        clearState(state);
      }
    )
  }
})

export default groupItemsSlice;

export function createClusterState(cluster) {

  let data = _.merge({clusterId: cluster.id},
    _.pick(cluster, 'levels'));

  // at first, all products match.
  data.matchingProducts = cluster.productIndex;

  return {
    selections: {},
    matchingProducts: cluster.productIndex,
    selectedItem: null,
    levelOptions: {},
    highlighted: false
  }
}
