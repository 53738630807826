import React, {useMemo} from 'react';
import {getDispatch} from "../../../Utility/ReduxHelper";
import {showSaveCart} from "../../../ReduxStore/ShoppingCart/saveCartSlice";

export default function SaveCartButton({shoppingCart = {}, checkoutConfig={}}) {
  const saveCartConfig = useMemo(() => checkoutConfig.saveCart || {} , [checkoutConfig]);

  if (saveCartConfig?.disabled)
    return null;

  const handleClick = (e) => {
    const dispatch = getDispatch();
    dispatch(showSaveCart({flavour: 'requested'}));
  }

  return (
    <div>
      <button className="btn save-cart-button" type="button"
        onClick={handleClick}>
        Save Your Cart
      </button>
    </div>
  );
}
