import React from 'react';
import clsx from 'clsx';

export default function CloseModalButton({
  className, handleClose,
  ...props
}) {
  className ||= [
    "absolute top-2 right-2 p-2 inline-block",
    "text-2xl leading-none cursor-pointer text-gray-400 hover:opacity-75" ]

  return <a
    className={clsx(className)}
    onClick={handleClose}>
    <i className="fa fa-times"></i>
  </a>
}
