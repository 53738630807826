
import {camelCase} from "lodash";

/*
 * Various methods for extracting data from
 * the 'product' object.
 */


export function isOutOfStock(product) {
  if (!product) return false;

  // snake_case agnostic
  let status = camelCase(product.buyableStatus || product.buyable_status);

  return (status === "outOfStock") || (status === 'discontinued');
}

export function parseBulletText(text) {
  if (!text) return [];

  return text.toString().split('@@');
}
