/*
 * given a Product Index (hash of hashes) from the back end,
 * filter based on the selections made by the user, yielding
 * a smaller list of product index entries.
 */
export default class ProductIndexFilter {
  // index: ID => { attr: [values], attr: [values] }
  constructor(index) {
    if (index) {
      this.index = index;
    } else {
      console.log("WARNING: ProductIndexFilter - index is empty.");
      this.index = {};
    }
  }

  // Return a product index hash { ID => attribute-hash }
  // that matches the selections.

  // { [id]: { id: XX, fitment: [a,b], style: [a,b]...}, [id2]: { } }
  filterProducts(selections) {
    console.log("filter products: selections=", selections);

    // from the this.index hash, select those key-value pairs
    // where the right-hand-side matches user selections
    return _.pickBy(this.index,
      (entry, _) => this.entryMatches(entry, selections)
    );
  }

  // entry - a ProductIndex entry
  // selections - hash of { attr: selected-value } like {size: '47/64 in.'}
  entryMatches(entry, selections) {
    // console.log("ENTRY " + entry + "MATCHES? " + JSON.stringify(selections));
    for (let attr in selections) {
      let value = selections[attr];
      if (value && !this.entryMatchesAttribute(entry, attr, value))
        return false;
    }
    return true;
  }

  entryMatchesAttribute(entry, attr, value) {
    // always make stringy
    value = "" + value;

    // get the array of values this entry has
    let myValues = (entry[attr] || []).map((obj) => "" + obj.v);
    // console.log("look for " + value + " in list " + JSON.stringify(myValues));
    return _.includes(myValues, value);
  }

}
